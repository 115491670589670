import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import "./Modal.scss";

const Modal = ({ children, onCloseClick }) => {
  return (
    <div className="main-modal">
      <AiOutlineClose
        className="close-icon"
        onClick={onCloseClick}
      />
      {children}
    </div>
  );
};

export default Modal;
