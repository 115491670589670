import React from 'react';
import { useDispatch } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import { AiOutlineCloudDownload as DownloadIcon } from 'react-icons/ai';
import es from 'date-fns/locale/es';
import { downloadReport } from '../../../actions/booking';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';

registerLocale('es-ES', es);

const DownloadReport = (props) => {
  const startDate = props.dates.startDate;
  const endDate = props.dates.endDate;
  const dispatch = useDispatch();

  const handleStartDate = (date) => {
    const endDay = date > props.dates.endDate ? null : props.dates.endDate;
    props.onChange({startDate:date, endDate: endDay});
  };

  const handleEndDate = (date) => {
    props.onChange({startDate, endDate: date});
  };

  const download = () => {
    if (startDate !== null && endDate !== null) {
      dispatch(downloadReport(startDate, endDate));
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-5 col-xs-10 mb-2">
          <span className="mr-2 text-muted">Fecha Inicio</span>
          <DatePicker
            locale="es-ES"
            dateFormat="dd-MM-yyyy"
            selected={ startDate }
            onChange={ (date) => handleStartDate(date) }
            selectsStart
            startDate={ startDate }
            endDate={ endDate }
            placeholderText="Fecha Inicio"
          />
        </div>
        <div className="col-md-5 col-xs-10 mb-2">
          <span className="mr-2 text-muted">Fecha Fin</span>
          <DatePicker
            locale="es-ES"
            dateFormat="dd-MM-yyyy"
            selected={ endDate }
            onChange={ (date) => handleEndDate(date) }
            selectsEnd
            startDate={ startDate }
            endDate={ endDate }
            minDate={ startDate }
            placeholderText="Fecha Fin"
          />
        </div>

        <div className="col-md-1 col-xs-1 mb-2">
          <div
            className={ `btn-download-report d-inline-flex ${endDate !== null ? 'enable' : ''}` }
            onClick={ () => { download(); } }
          >
            <DownloadIcon size="1.8em" />
            <span className="ml-1">Descargar</span>
          </div>
        </div>

      </div>
    </div>

  );
};

DownloadReport.propTypes = {

};

DownloadReport.defaultProps = {

};

export default DownloadReport;
