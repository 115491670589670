import React, { useState, useEffect } from 'react';

import { colors, Text } from 'react-elemental';
import Moment from 'react-moment';
import { FiClock as Clock } from 'react-icons/fi';
import { FaMapMarkerAlt as Marker } from 'react-icons/fa';
import { daysWeek } from '../../../../constants';
import TimeBlock from './TimeBlock';

const ALLOWED_DAYS = 13;

const DatePicking = ({ config, exceptions, onChangeDate }) => {
  const [days, setDays] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedBlock, setSelectedBlock] = useState(null);

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const getBlocksInDay = (config) => {
    const SCHEDULE_START_TIME = config.station.opened_at.split(':')[0];
    const SCHEDULE_END_TIME = config.station.closed_at.split(':')[0];
    const date1 = new Date();
    date1.setHours(SCHEDULE_START_TIME, 0, 0, 0);
    const date2 = new Date();
    date2.setHours(SCHEDULE_END_TIME, 0, 0, 0);
    const diff = (date2.getTime() - date1.getTime()) / (1000 * 60);
    return Math.floor(diff / config.attention_time);
  };

  const calculateDays = (config) => {
    const days = [];
    const now = new Date();
    const pivot = addDays(now, 1);
    for (let i = 0; i <= ALLOWED_DAYS; i++) {
      const date = addDays(pivot, i);
      const item = {
        date,
        weekday: date.getDay(),
        blocks: [],
      };
      // if (date.getDay() !== 0)
      days.push(item);
    }

    setDays(days);

    if (days.length > 0) {
      setSelectedDate(days[0].date);
      calculateBlockInSelectedDay(days[0].date, config);
    }
  };

  useEffect(() => {
    calculateDays(config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const calculateBlockInSelectedDay = (selectedDate, config) => {
    const SCHEDULE_START_TIME = config.station.opened_at.split(':')[0];
    const attention_time = config.attention_time;
    const blocks = [];
    const fixedDate = new Date(selectedDate);
    const initialDate = new Date(selectedDate);
    for (let j = 0; j < getBlocksInDay(config); j++) {
      initialDate.setHours(SCHEDULE_START_TIME, 0, 0, 0);
      const initial = new Date(initialDate.setMinutes(
        initialDate.getMinutes() + (attention_time * j),
      ));
      const end = new Date(
        initialDate.setMinutes(initialDate.getMinutes() + attention_time),
      );
      const start = new Date(fixedDate.getFullYear(), fixedDate.getMonth(),
        fixedDate.getDate(), initial.getHours(), initial.getMinutes());
      const data = {
        id: j,
        start,
        end: new Date(fixedDate.getFullYear(), fixedDate.getMonth(),
          fixedDate.getDate(), end.getHours(), end.getMinutes()),
        weekday: initialDate.getDay() === 0 ? 6 : initialDate.getDay() - 1,
      };

      // Is Saturday ?
      let canAddInSat = true;
      const SCHEDULE_END_TIME = config.station.sat_closed_at.split(':')[0];
      if (initialDate.getDay() === 6 && initialDate.getHours()
        > SCHEDULE_END_TIME) canAddInSat = false;

      // Is Sunday ?
      const SCHEDULE_END_TIME_SUN = config.station.sun_closed_at.split(':')[0];
      if (initialDate.getDay() === 0 && initialDate.getHours()
        > SCHEDULE_END_TIME_SUN) canAddInSat = false;

      const pivotDate = new Date(start).setHours(start.getHours());
      if (addDays(new Date(), 1).getTime() < new Date(pivotDate).getTime()
        && canAddInSat) blocks.push(data);
    }
    setBlocks(blocks);
  };

  const isBlockDisabled = (block) => {
    if (!exceptions) return false;

    return exceptions.find((item) => new Date(item.start_date).getTime()
      === new Date(block.start).getTime()) !== undefined;
  };

  const onHandleChangeDate = (date) => {
    setSelectedDate(date);
    setSelectedBlock(null);
    calculateBlockInSelectedDay(date, config);
  };

  const onHandleChangeBlock = (block) => {
    if (!isBlockDisabled(block)) {
      setSelectedBlock(block);
      onChangeDate(block.start);
    }
  };

  return (
    <div>
      {
        config !== null
          ? (
            <div className="row">
              <div className="col-md-6 col-xs-12 col-sm-12 mb-5">

                <h3 className="custom-title black mt-5">Selecciona una fecha</h3>
                <div className="mb-5 text-muted margin-top--5">
                  Reserva el cambio
                  de aceite el día que elija el cliente
                </div>

                <h5
                  className="mb-4 text-bold custom-title  black text-capitalize text-month"
                >
                  <Moment
                    locale="es"
                    format="MMMM  YYYY"
                    element="span"
                  >
                    { addDays(new Date(), 1) }
                  </Moment>
                </h5>
                <div className="select_date_container">
                  <div className="d-inline-flex">
                    { days.map((day, index) => (
                      <div key={ index }>
                        {
                          index <= 6
                          && (
                          <div
                            className="mb-2 label-day font-weight-bold"
                          >
                            { daysWeek[day.weekday].substring(
                              0, 2,
                            ) }
                          </div>
                          )
                        }
                      </div>
                    )) }
                  </div>

                  <div>
                    <div
                      className="d-inline-flex"
                      id="select_date_container"
                    >
                      { days.map((day, index) => (
                        <div key={ `day-${index}` }>
                          {
                            index <= 6
                            && (
                              <div
                                key={ index }
                                className={ (selectedDate
                                  && selectedDate.getDate() === day.date.getDate())
                                  ? 'text-center day-container selected'
                                  : 'text-center day-container' }
                                onClick={ () => {
                                  onHandleChangeDate(day.date);
                                } }
                              >

                                <Moment
                                  format="DD"
                                  className={ (new Date().getDate()
                                    === day.date.getDate())
                                    ? 'current-date'
                                    : '' }
                                >
                                  { day.date }
                                </Moment>
                              </div>
                            )
                          }
                        </div>
                      )) }
                    </div>
                  </div>

                  <div>
                    <div
                      className="d-inline-flex"
                      id="select_date_container-2"
                    >
                      { days.map((day, index) => (
                        <div>
                          {
                            index > 6
                            && (
                              <div
                                key={ index }
                                className={ (selectedDate
                                  && selectedDate.getDate()
                                  === day.date.getDate())
                                  ? 'text-center day-container selected'
                                  : 'text-center day-container' }
                                onClick={ () => {
                                  onHandleChangeDate(day.date);
                                } }
                              >
                                <Moment
                                  format="DD"
                                  className={ (new Date().getDate()
                                    === day.date.getDate())
                                    ? 'current-date'
                                    : '' }
                                >
                                  { day.date }
                                </Moment>
                              </div>
                            )
                          }
                        </div>
                      )) }
                    </div>
                  </div>

                </div>
              </div>

              <div className="col-md-4 col-xs-12 col-sm-12">
                <h3 className="custom-title black mt-5">Selecciona una hora</h3>
                <div className="mb-5 text-muted margin-top--5">
                  Elige la hora que
                  más le acomode al cliente *
                </div>
                <div className="row">
                  {
                    blocks.length > 0
                      ? exceptions
                      && blocks.map((block, index) => (
                        <div className="col-3 mb-3 pr-1 pl-1">
                          <TimeBlock
                            key={ index }
                            selected={ selectedBlock && selectedBlock.id
                            === block.id }
                            disabled={ isBlockDisabled(block) }
                            start={ block.start }
                            end={ block.end }
                            selectBlock={ () => onHandleChangeBlock(block) }
                          />
                        </div>
                      ))

                      : (
                        <div className="d-inline-flex">
                          <Text
                            size="iota"
                            className="text-center mt-3"
                            color={ colors.gray45 }
                            bold
                          >
                            <Clock />
                            No hay horas disponibles.
                          </Text>
                        </div>
                      )
                  }
                </div>

              </div>

            </div>
          )
          : (
            <div className="d-inline-flex w-100">
              <Text
                size="epsilon"
                className="text-center mt-3 w-100"
                color={ colors.gray45 }
                bold
              >
                <Marker />
                No has seleccionado una sucursal.
              </Text>
            </div>
          )
      }
    </div>
  );
};

export default DatePicking;
