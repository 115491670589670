import React, { useState, useEffect } from "react";
import Modal from "../../Modal";
import GenericDelete from "../Delete";
import GenericCreate from "../Create";
import GenericEdit from "../Edit";
import {
  calculatePages,
  getPreviousPage,
  getNextPage,
  PAGESIZE,
} from "../../../../../utils/helpers";
import "./List.scss";

const GenericList = ({ data, reloadTable }) => {
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const objList = data.objList;
  const totalPages = calculatePages(objList.count, PAGESIZE);

  const previousPage = getPreviousPage(objList?.links?.previous);
  const nextPage = getNextPage(objList?.links?.next);

  useEffect(() => {
    reloadTable(currentPage);
  }, [reloadTable, currentPage]);

  return (
    <div className="mt-4">
      {showCreate && (
        <Modal onCloseClick={() => setShowCreate(false)}>
          <GenericCreate
            createItem={data.createItem}
            setFormData={data.setFormData}
            formData={data.formData}
            FormElement={data.FormElement}
            title={data.modalTitle}
            reloadTable={reloadTable}
            setShowCreate={setShowCreate}
            formTitles={data.formTitles}
          />
        </Modal>
      )}
      {showEdit && (
        <Modal onCloseClick={() => setShowEdit(false)}>
          <GenericEdit
            updateItem={data.updateItem}
            setFormData={data.setFormData}
            FormElement={data.FormElement}
            formData={data.formData}
            title={data.modalTitle}
            reloadTable={reloadTable}
            setShowEdit={setShowEdit}
            formTitles={data.formTitles}
          />
        </Modal>
      )}
      {showDelete && data.formData && (
        <Modal onCloseClick={() => setShowDelete(false)}>
          <GenericDelete
            deleteItem={() => data.deleteItem(data.formData.id)}
            item={`${data.formData.id} - ${
              data.formData[data.deleteItemColumnReference]
            }`}
            setShowDelete={setShowDelete}
            reloadTable={reloadTable}
            title={data.modalTitle}
          />
        </Modal>
      )}
      <div className="row">
        <div className="col-12">
          <h3 className="red-text">{data.title}</h3>
        </div>
        <div className="col-12">
          <button
            className="btn btn-red rounded-pill"
            onClick={() => {
              setShowCreate(true);
              data.setFormData(data.initialData);
            }}
          >
            Ingresar nuevo {data.modalTitle}
          </button>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  {/*<th scope="col">ID</th>*/}
                  {data.tableHeaders?.map((header, index) => (
                    <th scope="col" key={`column-${index}-${header}`}>
                      {header.title}
                    </th>
                  ))}
                  <th scope="col">Acción</th>
                </tr>
              </thead>
              <tbody>
                {data.objList?.results?.map((content, index) => (
                  <tr
                    key={`rowId-${index}`}
                    className={content.is_active ? "" : "text-muted"}
                  >
                    {/*<th scope="row">{content.id}</th>*/}
                    {data.tableHeaders?.map((item, index) => (
                      <td key={`tableHeader-${item.name}-${index}`}>
                        {content[item.name]}
                      </td>
                    ))}
                    <td>
                        <button
                          className="btn btn-outline-primary btn-sm rounded-pill"
                          onClick={() => {
                            data.setFormData(content);
                            setShowEdit(true);
                          }}
                        >
                          Editar
                        </button>
                        {content.is_active && (
                          <button
                            className="btn btn-outline-danger btn-sm rounded-pill mt-1 mt-xl-0 ml-xl-1"
                            onClick={() => {
                              data.setFormData(content);
                              setShowDelete(true);
                            }}
                          >
                            Deshabilitar
                          </button>
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        </div>
        <div className="col-12 text-right">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li
                className={`page-item ${
                  totalPages === 0 || (!previousPage && "disabled")
                }`}
              >
                <div
                  onClick={() => {
                    if (previousPage) setCurrentPage(previousPage);
                    return null;
                  }}
                >
                  Anterior
                </div>
              </li>
              {totalPages > 0 ? (
                new Array(totalPages).fill(1).map((_, index) => {
                  const pageSelection = ++index;
                  return (
                    <li
                      className={` ${
                        pageSelection === currentPage && "active"
                      }`}
                      key={`select-page-${pageSelection}`}
                    >
                      <div onClick={() => setCurrentPage(pageSelection)}>
                        {pageSelection}
                      </div>
                    </li>
                  );
                })
              ) : (
                <li className="page-item disabled">
                  <div className="page-link">1</div>
                </li>
              )}

              <li
                className={`page-item ${
                  totalPages === 0 || (!nextPage && "disabled")
                }`}
              >
                <div
                  onClick={() => {
                    if (nextPage) setCurrentPage(nextPage);
                    return null;
                  }}
                >
                  Siguiente
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default GenericList;
