import { blockService } from '../services/block';
import { blockConstants } from '../constants';
import { handleError } from '../services';

export function list(stationId) {
  function request() {
    return { type: blockConstants.GET_BLOCKS_REQUEST };
  }

  function success(blocks) {
    return { type: blockConstants.GET_BLOCKS_SUCCESS, blocks };
  }

  function failure(error) {
    error = handleError(error);
    return { type: blockConstants.GET_BLOCKS_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());

    blockService.list(stationId).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response)),
    );
  };
}

export function create(data) {
  function request() {
    return { type: blockConstants.CREATE_BLOCK_REQUEST };
  }

  function success(block) {
    return { type: blockConstants.CREATE_BLOCK_SUCCESS, block };
  }

  function failure(error) {
    error = handleError(error);
    return { type: blockConstants.CREATE_BLOCK_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    blockService.create(data).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response)),
    );
  };
}

export function remove(blockId) {
  function request() {
    return { type: blockConstants.REMOVE_BLOCK_REQUEST };
  }

  function success(blockId) {
    return { type: blockConstants.REMOVE_BLOCK_SUCCESS, blockId };
  }

  function failure(error) {
    error = handleError(error);
    return { type: blockConstants.REMOVE_BLOCK_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    blockService.remove(blockId).then(
      (response) => dispatch(success(blockId)),
      (error) => dispatch(failure(error.response)),
    );
  };
}
