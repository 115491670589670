import * as React from "react";
import {
  Button,
  Label,
  Modal,
  Spacing,
  Text,
  TextField,
  Checkbox,
} from "react-elemental";
import Select from "react-select";
import { customSelect, customThinSelect } from "../../commons/style/style";

class EditBookingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cylinderCapacity:
        props.booking.vehicle.cylinder_capacity === null
          ? ""
          : props.booking.vehicle.cylinder_capacity,
      mileage: props.booking.vehicle.mileage === null ? "" : props.booking.vehicle.mileage,
      status: props.booking.status,
      comment: props.booking.comment,
      products: [],
    };

    this.editBooking = this.editBooking.bind(this);
    this.isValidCylinderCapacity = this.isValidCylinderCapacity.bind(this);
    this.isValidMileage = this.isValidMileage.bind(this);
    this.onHandleChangeCapacity = this.onHandleChangeCapacity.bind(this);
    this.addProduct = this.addProduct.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.isAdded = this.isAdded.bind(this);
    this.changeQuantity = this.changeQuantity.bind(this);
    this.onChangeAddedProduct = this.onChangeAddedProduct.bind(this);
    this.isValidQuantity = this.isValidQuantity.bind(this);
    this.getQuantityById = this.getQuantityById.bind(this);
    this.onHandleChangeComment = this.onHandleChangeComment.bind(this);
  }

  editBooking() {
    const data = {
      cylinder_capacity:
        this.state.cylinderCapacity === "" ? null : this.state.cylinderCapacity,
      mileage: this.state.mileage === "" ? null : this.state.mileage,
      status: this.state.status,
      comment: this.state.comment,
      vehicle: {patent: this.props.booking.vehicle.patent}
    };
    data.vehicle.mileage = this.state.mileage === "" ? null : this.state.mileage
    data.vehicle.cylinder_capacity = this.state.cylinderCapacity === "" ? null : this.state.cylinderCapacity
    this.props.editBookig(this.props.booking.id, data);

    this.state.products.forEach((p) => {
      this.props.createBookingStationProduct(p);
    });

    this.props.setVisibility(false);
  }

  isValidCylinderCapacity(capacity) {
    if (capacity === "") return "";
    const re = new RegExp("([0-9]*[.])?[0-9]+$");
    return re.test(capacity) ? "" : "No es un valor válido";
  }

  onHandleChangeCapacity(value) {
    if (!isNaN(value) || value === ".") {
      this.setState({ cylinderCapacity: value });
    }
  }

  onHandleChangeComment(value) {
    this.setState({ comment: value });
  }

  isValidMileage() {
    const value = this.state.mileage;
    const re = /^[0-9\b]+$/;
    return value === "" || value === null || re.test(value)
      ? ""
      : "Solo debe entrar números";
  }

  isValidQuantity(quantity) {
    const re = /^[0-9\b]+$/;
    return quantity === "" || quantity === null || re.test(quantity)
      ? ""
      : "Solo debe entrar números";
  }

  addProduct(stationProductId) {
    const product = {
      booking: this.props.booking.id,
      station_product: stationProductId,
      quantity: "",
    };
    const tempProducts = this.state.products;
    tempProducts.push(product);
    this.setState({ products: tempProducts });
  }

  removeProduct(stationProductId) {
    const tempProducts = this.state.products;
    const index = tempProducts.findIndex(
      (p) => p.station_product === stationProductId
    );
    if (index > -1) {
      tempProducts.splice(index, 1);
      this.setState({ products: tempProducts });
    }
  }

  isAdded(stationProductId) {
    return (
      this.state.products.findIndex(
        (p) => p.station_product === stationProductId
      ) > -1
    );
  }

  changeQuantity(stationProductId, quantity) {
    if (this.isValidQuantity(quantity) === "") {
      const tempProducts = this.state.products;
      const index = tempProducts.findIndex(
        (p) => p.station_product === stationProductId
      );
      if (index > -1) {
        tempProducts[index] = { ...tempProducts[index], quantity };
        this.setState({ products: tempProducts });
      }
    }
  }

  getQuantityById(stationProductId) {
    const product = this.state.products.find(
      (p) => p.station_product === stationProductId
    );
    return product !== undefined ? product.quantity : "";
  }

  onChangeAddedProduct(id) {
    return this.isAdded(id) ? this.removeProduct(id) : this.addProduct(id);
  }

  disabledCheckbox(id) {
    const { products } = this.state;
    return products.length > 0 && products.findIndex((p) => p.station_product === id) === -1
  }

  componentDidMount() {
    this.props.getStationProducts();
  }

  render() {
    const { booking, stationProducts } = this.props;
    return (
      <Modal
        size="beta"
        onHide={() => {
          this.props.setVisibility(false);
        }}
      >
        <Spacing size="large" top right bottom left padding>
          <Spacing bottom>
            <Text size="epsilon" color="gray50">
              Editar datos de reserva
            </Text>

            {booking.status === "activated" && (
              <span className="tag activated in-modal">Activa</span>
            )}

            {booking.status === "cancelled" && (
              <span className="tag cancelled in-modal">Cancelada</span>
            )}

            {booking.status === "expired" && (
              <span className="tag expired in-modal">Caducada</span>
            )}

            {booking.status === "done" && (
              <span className="tag done in-modal">Realizada</span>
            )}
          </Spacing>

          <Spacing bottom>
            <div className="row">
              <div className="col-md-4 col-sm-12 col-xs-12">
                <Spacing bottom>
                  <Label sublabel="Estado" className="mr-2" />
                  <Select
                    className="rounded-pill"
                    styles={customSelect}
                    placeholder="Actualiza el estado"
                    onChange={(item) => {
                      this.setState({ status: item.value });
                    }}
                    noOptionsMessage={() => "No datos disponibles"}
                    options={[
                      { label: "Activa", value: "activated" },
                      { label: "Cancelada", value: "cancelled" },
                      { label: "Caducada", value: "expired" },
                      { label: "Realizada", value: "done" },
                    ]}
                  />
                </Spacing>
              </div>

              <div className="col-md-4 col-sm-12 col-xs-12">
                <Spacing bottom>
                  <Label sublabel="Kilometraje" />
                  <TextField
                    className="rounded-pill"
                    placeholder="Kilometraje"
                    error={this.isValidMileage()}
                    value={this.state.mileage}
                    onChange={(e) => {
                      this.setState({ mileage: e.target.value });
                    }}
                  />
                </Spacing>
              </div>

              <div className="col-md-4 col-sm-12 col-xs-12">
                <Spacing bottom>
                  <Label sublabel="Cilindrada" />
                  <TextField
                    className="rounded-pill"
                    placeholder="Cilindrada"
                    value={this.state.cylinderCapacity}
                    onChange={(e) => {
                      this.onHandleChangeCapacity(e.target.value);
                    }}
                    error={this.isValidCylinderCapacity(
                      this.state.cylinderCapacity
                    )}
                  />
                </Spacing>
              </div>
              {this.state.status === "cancelled" ? (
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <Spacing bottom>
                    <Label sublabel="Motivo de cancelación" className="mr-2" />
                    <Select
                      className="rounded-pill"
                      styles={customSelect}
                      placeholder="Seleccione"
                      onChange={(item) => {
                        this.setState({ comment: item.value });
                      }}
                      noOptionsMessage={() => "No datos disponibles"}
                      options={[
                        { label: "Sin stock de lubricantes", value: "Sin stock de lubricantes" },
                        { label: "Sin stock de accesorios", value: "Sin stock de accesorios" },
                        { label: "Sin lubricador", value: "Sin lubricador" },
                        { label: "Cancelación de cliente", value: "Cancelación de cliente" },
                        { label: "Otra", value: "Otra" },
                      ]}
                    />
                  </Spacing>
                </div>
              ) : null}
            </div>
          </Spacing>

          <Spacing bottom>
            <Text size="iota" color="gray70" bold>
              Selecciona los productos utilizados.
            </Text>

            <div className="w-100">
              {stationProducts.map((sp) => (
                <div className="mb-2 row" key={`sp_${sp.id}`}>
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <Checkbox
                      checked={ this.isAdded(sp.id) }
                      onChange={ () => {
                        this.onChangeAddedProduct(sp.id);
                      } }
                      disabled={ this.disabledCheckbox(sp.id) }
                    />
                    <span
                      className="ml-2 text-muted"
                      onClick={() => !this.disabledCheckbox(sp.id) && this.onChangeAddedProduct(sp.id) }
                    >
                      {sp.product.name} {sp.product.code}
                    </span>
                  </div>
                  {this.isAdded(sp.id) && (
                    <div className="col-md-6 col-sm-12 col-xs-12">
                      <Select
                        className="rounded-pill thin-input col-md-8 col-sm-12 col-xs-12"
                        styles={customThinSelect}
                        placeholder="Cantidad (Litros)"
                        onChange={(item) => {
                          this.changeQuantity(sp.id, item.value);
                        }}
                        noOptionsMessage={() => "No hay datos disponibles"}
                        options={Array.from(
                          { length: 10 },
                          (_, i) => i + 1
                        ).map((n) => ({ label: `${n} Litros`, value: n }))}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Spacing>

          <Text right>
            <Button
              text="Cancelar"
              secondary
              className="mr-3 rounded-pill"
              onClick={() => this.props.setVisibility(false)}
            />
            <Button
              text="Guardar"
              className="rounded-pill"
              onClick={() => this.editBooking()}
              disabled={
                this.isValidMileage() !== "" ||
                this.isValidCylinderCapacity(this.state.cylinderCapacity) !== "" ||
                !this.state.products.every((p) => p.quantity !== "" && p.quantity !== undefined) ||
                (this.state.status === "done" && this.state.products.length === 0) ||
                (this.state.status === "cancelled" && !this.state.comment)
              }
            />
          </Text>
        </Spacing>
      </Modal>
    );
  }
}

export default EditBookingModal;
