import React, { useState, useEffect } from "react";
import { Button, Accordion, Card } from 'react-bootstrap';
import { VehicleFuelTypeEnum } from "../../../../../services/api";
import { spanishFuelValues } from "./helpers";
import {
  allowOnlyLetters,
  allowOnlyNumbers,
} from "../../../../../utils/helpers";
import { serviceFactory } from "../../../../../utils/helpers";
import { VehiclesApi } from "../../../../../services/api";
import { get_history } from "../../../../../services/vehicle";
import { validatePatent } from "../../../../Commons/Validators";

const vehiclesApi = serviceFactory(VehiclesApi);

const SecondStep = ({ setNewOrderData, newOrderData, setFormStep }) => {
  const [vehicleExists, setVehicleExists] = useState(false);
  const [cylinderCapacity, setCylinderCapacity] = useState("");
  const [vehicleHistory, setVehicleHistory] = useState([]);
  const patentValue = newOrderData.booking.vehicle?.patent || '';
  const yearValue = newOrderData.booking.vehicle?.year || '';
  const brandValue = newOrderData.booking.vehicle?.brand || '';
  const modelValue = newOrderData.booking.vehicle?.model || '';
  const fuel_typeValue = newOrderData.booking.vehicle?.fuel_type || '';
  const cylinder_capacityValue = newOrderData.booking.vehicle?.cylinder_capacity || '';

  const disabled =
    patentValue === "" ||
    yearValue === "" ||
    brandValue === "" ||
    modelValue === "" ||
    fuel_typeValue === "";

  const updateExistingVehicle = (vehicle) => {
    setVehicleExists(true);
    setCylinderCapacity(vehicle.cylinder_capacity);
    setNewOrderData((prevState) => ({
      ...prevState,
      booking: {
        ...prevState.booking,
        vehicle: {
          ...prevState.booking.vehicle,
          patent: vehicle.patent,
          type: vehicle.type,
          fuel_type: vehicle.fuel_type,
          brand: vehicle.brand,
          model: vehicle.model,
          year: vehicle.year,
          cylinder_capacity: vehicle.cylinder_capacity,
          mileage: vehicle.mileage,
        },
      },
    }));
  };

  const restartVehicle = () => {
    setVehicleExists(false);
    setCylinderCapacity("");
    setNewOrderData((prevState) => ({
      ...prevState,
      booking: {
        ...prevState.booking,
        vehicle: {
          ...prevState.booking.vehicle,
          type: "",
          fuel_type: "",
          brand: "",
          model: "",
          year: "",
          cylinder_capacity: "",
          mileage: "",
        },
      },
    }));
  };

  const getPatent = (patent) => {
    vehiclesApi
        .vehiclesRead({patent: patent})
        .then((response) => {
          updateExistingVehicle(response.data);
          get_history(patent)
              .then((resp) => {
                setVehicleHistory(resp.data);
              }).catch((err) => {
                setVehicleHistory([]);
          })
        })
        .catch((error) => {
          restartVehicle();
          setVehicleHistory([]);
        });

  }

  const onBlurPantent = (e) => {
    e.persist();
    getPatent(patentValue);
  }

  useEffect(() => {
  	if (patentValue !== '') {
  	  getPatent(patentValue);
    }
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-sm-6 col-md-4 form-group">
          <label className="d-block" htmlFor="patent">
            Patente
          </label>
          <input
            name="patent"
            placeholder="Patente: AABB33"
            className={
              "form-control rounded-pill" +
              (validatePatent(patentValue) ? " is-invalid" : "")
            }
            value={patentValue}
            onChange={(e) => {
              e.persist();
              if (e.target.value.length <= 6)
                setNewOrderData((prevState) => ({
                  ...prevState,
                  booking: {
                    ...prevState.booking,
                    vehicle: {
                      ...prevState.booking.vehicle,
                      patent: e.target.value.toUpperCase(),
                    },
                  },
                }));
            }}
            onBlur={onBlurPantent}
          />
          <div className="invalid-feedback">No es una patente válida</div>
        </div>
        <div className="col-sm-6 col-md-4 form-group">
          <label className="d-block" htmlFor="brand">
            Marca
          </label>
          <input
            name="brand"
            placeholder="Marca"
            className="form-control rounded-pill"
            value={brandValue}
            onChange={(e) => {
              e.persist();
              const brand = allowOnlyLetters(e.target.value, brandValue);
              setNewOrderData((prevState) => ({
                ...prevState,
                booking: {
                  ...prevState.booking,
                  vehicle: {
                    ...prevState.booking.vehicle,
                    brand: brand,
                  },
                },
              }));
            }}
          />
        </div>
        <div className="col-sm-6 col-md-4 form-group">
          <label className="d-block" htmlFor="model">
            Modelo
          </label>
          <input
            name="model"
            value={modelValue}
            placeholder="Modelo"
            className="form-control rounded-pill"
            defaultValue={modelValue}
            onChange={(e) => {
              e.persist();
              setNewOrderData((prevState) => ({
                ...prevState,
                booking: {
                  ...prevState.booking,
                  vehicle: {
                    ...prevState.booking.vehicle,
                    model: e.target.value,
                  },
                },
              }));
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-4 form-group">
          <label className="d-block" htmlFor="year">
            Año
          </label>
          <input
            name="year"
            placeholder="Año"
            className="form-control rounded-pill"
            value={yearValue}
            onChange={(e) => {
              e.persist();
              const year = allowOnlyNumbers(e.target.value, yearValue);
              setNewOrderData((prevState) => ({
                ...prevState,
                booking: {
                  ...prevState.booking,
                  vehicle: {
                    ...prevState.booking.vehicle,
                    year: year,
                  },
                },
              }));
            }}
          />
        </div>
        <div className="col-sm-6 col-md-4 form-group">
          <label className="d-block" htmlFor="cylinder_capacity">
            Cilindrada
          </label>
          <input
            name="cylinder_capacity"
            className="form-control rounded-pill"
            value={cylinder_capacityValue}
            placeholder="Cilindrada"
            onChange={(e) => {
              e.persist();
              const cylinder_capacity = allowOnlyNumbers(
                e.target.value.replace(".", ""),
                cylinder_capacityValue
              );

              setNewOrderData((prevState) => ({
                ...prevState,
                booking: {
                  ...prevState.booking,
                  vehicle: {
                    ...prevState.booking.vehicle,
                    cylinder_capacity:
                      cylinder_capacity.length <= 2
                        ? cylinder_capacity.replace(/^(\d)(\d)$/, "$1.$2")
                        : e.target.value,
                  },
                },
              }));
            }}
          />
        </div>
        <div className="col-sm-6 col-md-4 form-group">
          <label className="d-block" htmlFor="mileage">
            Kilometraje
          </label>
          <input
            name="mileage"
            placeholder="Kilometraje"
            className="form-control rounded-pill"
            value={newOrderData.booking.vehicle?.mileage}
            onChange={(e) => {
              e.persist();
              const mileage = allowOnlyNumbers(
                e.target.value,
                newOrderData.booking.vehicle?.mileage
              );

              setNewOrderData((prevState) => ({
                ...prevState,
                booking: {
                  ...prevState.booking,
                  vehicle: {
                    ...prevState.booking.vehicle,
                    mileage: mileage,
                  },
                },
              }));
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-4 form-group">
          <label className="d-block" htmlFor="fuel_type">
            Tipo de combustible
          </label>

          <select
            name="fuel_type"
            placeholder="Tipo de combustible"
            className="form-control rounded-pill"
            value={fuel_typeValue}
            onChange={(e) => {
              e.persist();
              setNewOrderData((prevState) => ({
                ...prevState,
                booking: {
                  ...prevState.booking,
                  vehicle: {
                    ...prevState.booking.vehicle,
                    fuel_type: e.target.value,
                  },
                },
              }));
            }}
          >
            <option value="" disable="true" hidden>
              Seleccionar Combustible
            </option>
            {Object.keys(VehicleFuelTypeEnum).map((fuel_type) => (
              <option key={fuel_type} value={VehicleFuelTypeEnum[fuel_type]}>
                {spanishFuelValues[fuel_type]}
              </option>
            ))}
          </select>
        </div>
      </div>

      {vehicleHistory.length > 0 && (
      <div className="row">

        <div className="col-12 mt-3">
          <h5>Historial de atención</h5>
        </div>

        <div className="col-12">
          <Accordion>
            {vehicleHistory.map((item, index) => (
              <Card key={index}>
                <Accordion.Toggle as={Card.Header} eventKey={String(index)}>
                  {item.station} - {item.date}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={String(index)}>
                  <Card.Body>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong>Sucursal:</strong> {item.station} <br/>
                        <strong>Lubricador:</strong> {item.lubricator} <br/>
                        <strong>Número de OT:</strong> {item.ot_number} <br/>
                        <strong>Kilometraje:</strong> {item.mileage} <br/>
                        <strong>Número de Ticket:</strong> {item.ticket_number} <br/>
                        <strong>Cliente:</strong> {item.customer} <br/>
                        <strong>Rut:</strong> {item.rut} <br/>
                        {/* {item.products.length > 0 && <strong>Productos: </strong>}
                          {item.products.map(
                            (prod, idx) =>
                                <span key={idx}>{idx === 0 ? '': ','} {prod.name} - {prod.quantity} Lts</span>
                        )} */}

                      </div>
                      <div className="col-12 col-md-6">
                        <strong>Servicios</strong><br/>
                        <ul className="pl-4">
                          {item.services.map(
                              (serv, idx) =>
                                  (<li key={idx}>{serv.name}
                                    {serv.changed ? (<span className="badge badge-primary ml-2">Cambio</span>) : (
                                        <span className="badge badge-secondary ml-2">Revisión</span>)}</li>)
                          )}
                        </ul>
                      </div>
                      {item.products.length > 0 && (
                        <div className="col-12 col-md-6">
                          <strong>Productos</strong><br/>
                          <ul className="pl-4">
                            {item.products.map(
                                (product, idx) =>
                                    (<li key={idx}>
                                      {product.name} <span className="badge badge-primary ml-2">{product.quantity} Litros</span>
                                    </li>)
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>

        </div>

      </div>
      )}
      <div className="row next-button">
        <div className="col-6">
          <div>
            <button
              type="button"
              className="btn new-order-button font-weight-bold"
              onClick={() => setFormStep((prevState) => --prevState)}
            >
              Atrás
            </button>
          </div>
        </div>
        <div className="col-6">
          <div className="text-right">
            <button
              type="button"
              disabled={disabled}
              className="btn new-order-button font-weight-bold"
              onClick={() => setFormStep((prevState) => ++prevState)}
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondStep;
