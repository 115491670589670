import { productConstants, constants } from '../constants';

const initialState = {
  isSearching: false,
  isSuccess: false,
  error: '',
  hasError: false,
  products: [],
  stationProducts: [],
  bookingStationProducts: [],
};

export function products(state = initialState, action) {
  switch (action.type) {
    case productConstants.GET_PRODUCTS_REQUEST:
      return {
        ...state,
        isSearching: true,
        error: '',
        hasError: false,
        isSuccess: false,
      };
    case productConstants.GET_PRODUCTS_SUCCESS: {
      return {
        ...state,
        products: action.products.sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        }),
        isSearching: false,
        error: '',
        hasError: false,
        isSuccess: true,
      };
    }
    case productConstants.GET_PRODUCTS_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isSearching: false,
      };

    case productConstants.GET_STATION_PRODUCTS_REQUEST:
      return {
        ...state,
        isSearching: true,
        error: '',
        hasError: false,
        isSuccess: false,
      };
    case productConstants.GET_STATION_PRODUCTS_SUCCESS: {
      return {
        ...state,
        stationProducts: action.stationProducts.sort((a, b) => {
          if (a.product.name < b.product.name) { return -1; }
          if (a.product.name > b.product.name) { return 1; }
          return 0;
        }),
        isSearching: false,
        error: '',
        hasError: false,
        isSuccess: true,
      };
    }
    case productConstants.GET_STATION_PRODUCTS_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isSearching: false,
      };

    case productConstants.GET_STATION_PRODUCTS_BY_STATION_REQUEST:
      return {
        ...state,
        isSearching: true,
        error: '',
        hasError: false,
        isSuccess: false,
      };
    case productConstants.GET_STATION_PRODUCTS_BY_STATION_SUCCESS: {
      return {
        ...state,
        stationProducts: action.stationProducts,
        isSearching: false,
        error: '',
        hasError: false,
        isSuccess: true,
      };
    }
    case productConstants.GET_STATION_PRODUCTS_BY_STATION_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isSearching: false,
      };

    case productConstants.CREATE_STATION_PRODUCT_REQUEST:
      return {
        ...state,
        isSearching: true,
        error: '',
        hasError: false,
        isSuccess: false,
      };
    case productConstants.CREATE_STATION_PRODUCT_SUCCESS: {
      return {
        ...state,
        stationProducts: [...state.stationProducts, action.data],
        isSearching: false,
        error: '',
        hasError: false,
        isSuccess: true,
      };
    }
    case productConstants.CREATE_STATION_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isSearching: false,
      };
    case productConstants.REMOVE_STATION_PRODUCT_REQUEST:
      return {
        ...state,
        isSearching: true,
        error: '',
        hasError: false,
        isSuccess: false,
      };
    case productConstants.REMOVE_STATION_PRODUCT_SUCCESS: {
      const spList = state.stationProducts;
      const index = spList.findIndex((sp) => (sp.id === action.id));
      if (index > -1) {
        spList.splice(index, 1);
      }
      return {
        ...state,
        stationProducts: spList,
        isSearching: false,
        error: '',
        hasError: false,
        isSuccess: true,
      };
    }
    case productConstants.REMOVE_STATION_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isSearching: false,
      };

    case productConstants.EDIT_STATION_PRODUCT_REQUEST:
      return {
        ...state,
        isSearching: true,
        error: '',
        hasError: false,
        isSuccess: false,
      };
    case productConstants.EDIT_STATION_PRODUCT_SUCCESS: {
      const spList = state.stationProducts;
      const index = spList.findIndex((sp) => (sp.id === action.data.id));
      if (index > -1) {
        spList[index] = action.data;
      }
      return {
        ...state,
        stationProducts: spList,
        isSearching: false,
        error: '',
        hasError: false,
        isSuccess: true,
      };
    }
    case productConstants.EDIT_STATION_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isSearching: false,
      };
    case productConstants.CREATE_BOOKING_STATION_PRODUCT_REQUEST:
      return {
        ...state,
        isSearching: true,
        error: '',
        hasError: false,
        isSuccess: false,
      };
    case productConstants.CREATE_BOOKING_STATION_PRODUCT_SUCCESS: {
      return {
        ...state,
        bookingStationProducts: [...state.bookingStationProducts, action.data],
        isSearching: false,
        error: '',
        hasError: false,
        isSuccess: true,
      };
    }
    case productConstants.CREATE_BOOKING_STATION_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isSearching: false,
      };
    case constants.RESET_ERROR_STATE:
      return {
        ...state,
        error: '',
        hasError: false,
        success: false,
        isSuccess: false,
      };
    default:
      return state;
  }
}
