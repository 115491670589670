import { handleError } from '../services';
import { vehicleService } from '../services/vehicle';
import { vehiclesConstants } from '../constants';

export function retrieve(patent) {
  function request() {
    return { type: vehiclesConstants.GET_VEHICLE_REQUEST };
  }

  function success(vehicle) {
    return { type: vehiclesConstants.GET_VEHICLE_SUCCESS, vehicle };
  }

  function failure(error) {
    error = handleError(error, vehiclesConstants.GET_VEHICLE_FAILURE);
    return { type: vehiclesConstants.GET_VEHICLE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    vehicleService.retrieve(patent).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response)),
    );
  };
}
