import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { resetNotification } from "../../actions";
import Ticket from "./index";
import {
  create,
} from "../../actions/tickets";

const mapStateToProps = (state) => ({
  ticket: state.ticket,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  createTicket: (data) => {
    dispatch(create(data));
  },
  resetNotification: () => {
    dispatch(resetNotification());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Ticket));
