import React, { useState } from 'react';
import { navigate } from 'hookrouter';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { colors, Text } from 'react-elemental';
import Image from 'react-bootstrap/Image';
import { Link, useLocation } from 'react-router-dom';
import { FaBars as Bars } from 'react-icons/fa';
import { IoMdCloseCircle as Close } from 'react-icons/io';
import { logout } from '../../../actions';
import logo from '../../../commons/assets/helix.svg';
import './styles.scss';

const Menu = ({
  handleLogout,
  location,
  isMobile,
}) => {
  const getClassLink = (path) => {
    if (location === path) return `${isMobile ? 'mr-3' : ''}  active`;
    return `${isMobile ? 'mr-3' : ''}`;
  };

  return (
    <>
      <Link
        to="/app/config"
        className={ getClassLink('/app/config') }
      >
        <Text color={ colors.primary }>Configuración</Text>
      </Link>

      <Link
        to="/app/products"
        className={ getClassLink('/app/products') }
      >
        <Text color={ colors.primary }>Productos</Text>
      </Link>

      <Link
        to="/app/planning"
        className={ getClassLink('/app/planning') }
      >
        <Text color={ colors.primary }>Planificación</Text>
      </Link>

      <Link
        to="/app/bookings"
        className={ getClassLink('/app/bookings') }
      >
        <Text color={ colors.primary }>Gestionar reservas</Text>
      </Link>

      <Link
        to="/"
        className={ getClassLink('/') }
      >
        <Text color={ colors.primary }>Inicio</Text>
      </Link>

      <Link
        to="/app"
        className={ getClassLink('/app') }
      >
        <Text color={ colors.primary }>Reservar</Text>
      </Link>

      <Link
        to="/dashboard/news"
        className={ getClassLink('/app') }
      >
        <Text color={ colors.primary }>Dashboard</Text>
      </Link>

      <Link
        to=""
        onClick={ () => handleLogout() }
      >
        <Text color={ colors.primary }>Cerrar sesión</Text>
      </Link>
    </>
  );
};

const CustomNavBar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const dispatch = useDispatch();
  const lc = useLocation();
  const location = lc.pathname;

  const state = useSelector((s) => ({ auth: s.auth }));

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="container mb-3 mt-3"
    >
      <Navbar.Brand>
        <Link to="/">
          <Image
            className="d-none d-lg-block"
            alt="Shell"
            src={ logo }
            color={ colors.primary }
            width="auto"
            height="50px"
          />

          <Image
            className="d-lg-none d-xl-none margin-top--10"
            alt="Shell"
            src={ logo }
            color={ colors.primary }
            width="auto"
            height="38px"
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto" />

        <Nav className="d-none d-lg-block">
          <div className="d-inline-flex">
            {
              state.auth.isLogged
                ? (
                  <span>
                    <Text
                      bold
                      className="text-capitalize font-weight-bolder text-danger mr-3 mt-3 mb-0 pb-3"
                    >
                      { state.auth.currentUser.username }
                    </Text>
                  </span>
                )

                : (
                  <span className="text-muted margin-top-13 menu-title shell-bold mr-3">
                    ¿Eres administrador?
                  </span>
                )
            }
            <span
              className="btn-menu-header rounded-circle d-flex justify-content-center align-items-center"
              onClick={ () => setShowMenu(!showMenu) }
            >
              <Bars />
            </span>
          </div>

          {
            showMenu
            && (
              <div
                className="panel-menu-header"
                onClick={ () => setShowMenu(false) }
              >
                <div
                  className="close-icon"
                  onClick={ () => setShowMenu(!showMenu) }
                >
                  <Close />
                </div>
                {
                  state.auth.isLogged
                    ? (
                      <span>
                        <span
                          className="text-capitalize font-weight-bold text-white pl-1"
                        >
                          Hola
                          &nbsp;
                          { state.auth.currentUser.username }
                        </span>
                        <Menu
                          handleLogout={ handleLogout }
                          location={ location }
                        />
                      </span>
                    )
                    : (
                      <Link to="/app/login">
                        <Text className="shell-normal" color={ colors.primary }>
                          Acceso a
                          administración
                        </Text>
                      </Link>
                    )
                }
              </div>
            )
          }
        </Nav>

        <Nav className="d-block d-lg-none mt-3">
          {
            state.auth.isLogged
              ? (
                <span
                  id="link-container"
                  className="d-inline-flex"
                >
                  <Menu
                    handleLogout={ handleLogout }
                    location={ location }
                    isMobile
                  />

                  <Text
                    bold
                    size="iota"
                    className="text-capitalize mt-1"
                  >
                    { state.auth.currentUser.username }
                  </Text>
                </span>
              )
              : (
                <Link
                  to="/app/login"
                  className="text-center mt-3"
                >
                  <Text
                    size="iota"
                    color={ colors.primary }
                  >
                    Acceso a
                    administración
                  </Text>
                </Link>
              )
          }
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavBar;
