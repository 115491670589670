import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { resetNotification } from '../../actions';
import Planning from './Planning';
import { getConfig } from '../../actions/stations';
import { list, create, remove } from '../../actions/block';

const mapStateToProps = (state) => ({
  auth: state.auth,
  station: state.station,
  block: state.block,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getConfig: (stationId) => {
    dispatch(getConfig(stationId));
  },
  getBlocks: (stationId) => {
    dispatch(list(stationId));
  },
  createBlock: (data) => {
    dispatch(create(data));
  },
  removeBlock: (blockId) => {
    dispatch(remove(blockId));
  },
  resetNotification: () => {
    dispatch(resetNotification());
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Planning),
);
