import { bookingConstants, constants } from '../constants';

const initialState = {
  list: [],
  isLoading: false,
  error: '',
  hasError: false,
  success: false,
  total: 0,
};

export function booking(state = initialState, action) {
  switch (action.type) {
    case bookingConstants.GET_BOOKINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
        hasError: false,
      };
    case bookingConstants.GET_BOOKINGS_SUCCESS: {
      // const sortedBookings = action.list.results;
      return {
        ...state,
        list: action.list.results,
        isLoading: false,
        error: '',
        hasError: false,
        total: action.list.count,
      };
    }
    case bookingConstants.GET_BOOKINGS_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isLoading: false,
        list: [],
      };

    case bookingConstants.EDIT_BOOKING_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
        hasError: false,
      };
    case bookingConstants.EDIT_BOOKING_SUCCESS: {
      const index = state.list.findIndex((item) => item.id === Number(action.booking.id));
      if (index > -1) {
        return {
          ...state,
          isLoading: false,
          error: '',
          hasError: false,
          list: [
            ...state.list.slice(0, index),
            action.booking,
            ...state.list.slice(index + 1),
          ],
        };
      }
      return state;
    }
    case bookingConstants.EDIT_BOOKING_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isLoading: false,
      };

    case bookingConstants.CREATE_BOOKING_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
        hasError: false,
      };
    case bookingConstants.CREATE_BOOKING_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.booking],
        success: true,
        isLoading: false,
        error: '',
        hasError: false,
      };
    case bookingConstants.CREATE_BOOKING_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isLoading: false,
      };
    case constants.RESET_ERROR_STATE:
      return {
        ...state,
        error: '',
        hasError: false,
        success: false,
      };
    default:
      return state;
  }
}
