import React, { useState, useLayoutEffect, useMemo } from "react";
import { sidebarLinks } from "./SidebarData";
import shellLogo from "../../commons/assets/shell_logo.png";
import SidebarButtons from "./components/SidebarButtons";
import Header from "./components/Header";
import { Route, Switch } from "react-router-dom";
import News from "./components/News";
import Management from "./components/Management";
import Trainings from "./components/Trainings";
import NewOrder from "./components/NewOrder";
import Configuration from "../Configuration/ConfigurationContainer";
import Products from "../Products";
import Booking from "../Home/Home";
import ListOrder from "./components/WorkOrder/List";
import Lubricators from "./components/Lubricators";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { logout } from "../../actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { NEWS_TYPE, getCurrentUser } from "../../utils/helpers";
import "./Dashboard.scss";

const Dashboard = () => {
  const currentUser = getCurrentUser();
  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const state = useSelector((s) => ({ auth: s.auth }));
  const [itemActive, setItemActive] = useState(null);
  const [childActive, setChildActive] = useState(null);

  function calculateSize() {
    const height = document.getElementById("dashboard-logo-box").offsetHeight;
    const sidebar = document.getElementById("sidebar").offsetWidth;
    return { height, sidebar };
  }

  function useWindowSize() {
    const [heightLogo, setHeightLogo] = useState(110);
    const [sidebarWidth, setSidebarWidth] = useState(200);
    useLayoutEffect(() => {
      function updateHeight() {
        const new_size = calculateSize();
        setHeightLogo(new_size.height);
        setSidebarWidth(new_size.sidebar);
      }
      window.addEventListener("resize", updateHeight);
      setTimeout(() => {
        updateHeight();
      }, 100);
      return () => window.removeEventListener("resize", updateHeight);
    }, []);
    return { heightLogo, sidebarWidth };
  }

  const calculatedSizes = useWindowSize();

  const sidebarLinksMemo = useMemo(() => sidebarLinks(), []);

  const handleLogout = () => {
    history.push("/");
    dispatch(logout());
  };

  return (
    <div className="dashboard-wrapper">
      <div className="sidebar" id="sidebar">
        <div className="dashboard-logo-box" id="dashboard-logo-box">
          <img src={shellLogo} className="dashboard-logo" alt={"main-logo"} />
        </div>
        <div
          className="menu-overflow"
          style={{ height: `calc(100vh - ${calculatedSizes.heightLogo}px)` }}
        >
          {sidebarLinksMemo.map(
            (element) =>
              element.permissions.includes(currentUser?.type) && (
                <div key={element.key}>
                  <SidebarButtons
                    element={element}
                    path={path}
                    setChildActive={setChildActive}
                    childActive={childActive}
                    itemActive={itemActive}
                    setItemActive={setItemActive}
                  />
                </div>
              )
          )}
          <div onClick={() => handleLogout()} className="sidebar-button">
            Cerrar sesión
          </div>
        </div>
      </div>
      <div
        className="main-content"
        style={{
          marginLeft: `${calculatedSizes.sidebarWidth}px`,
          width: `calc(100% - ${calculatedSizes.sidebarWidth}px)`,
        }}
      >
        <div className="row">
          <div className="col-12">
            <Header user={state.auth.currentUser} />
            <Switch>
              <Route exact path={`${path}`}>
                <div>Dashboard</div>
              </Route>
              <Route path={`${path}/news`}>
                <News typeNot={NEWS_TYPE.INCENTIVES} />
              </Route>
              <Route path={`${path}/cambia-con-helix/management`}>
                <Management />
              </Route>
              <Route path={`${path}/cambia-con-helix/products`}>
                <Products />
              </Route>
              <Route path={`${path}/cambia-con-helix/booking`}>
                <Booking />
              </Route>
              <Route path={`${path}/settings`}>
                <Configuration />
              </Route>
              <Route path={`${path}/incentives`}>
                <News type={NEWS_TYPE.INCENTIVES} />
              </Route>
              <Route path={`${path}/work-orders/list`}>
                <ListOrder />
              </Route>
              <Route path={`${path}/work-orders/new-order`}>
                <NewOrder />
              </Route>
              <Route path={`${path}/lubricators`}>
                <Lubricators />
              </Route>
              <Route path={`${path}/trainings`}>
                <Trainings />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
