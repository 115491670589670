import React, { useState } from "react";
// import ReCAPTCHA from 'react-google-recaptcha';
import "./Confirm.scss";
import { colors, Spacing, Text } from "react-elemental";
import Moment from "react-moment";
import Stats from "../../Stats";
import ProductCarousel from "../../../Commons/ProductCarousel";
import schedule from "../../../../commons/assets/schedule.png";
import car from "../../../../commons/assets/car.png";
import person from "../../../../commons/assets/personal.png";
import TermsModal from "./TermsModal";

function Confirm(props) {
  const { station, date, vehicle, customer, promotional_code } = props.data;

  const { name, email, phone, rut } = customer;
  const { patent, brand, model, year, fuel_type } = vehicle;
  // const { validateCaptcha, captcha } = props;
  // const [captchaIsValid, setCaptchaIsValid] = useState(false);
  const [terms, setTerms] = useState(false);
  // const [captchaToken, setCaptchaToken] = useState(undefined);

  const onUpdateTerms = () => {
    setTerms(!terms);
  };

  /*
  const onExpiredCaptcha = () => {
    setCaptchaIsValid(false);
  };
*/

  /*
  const onChangeCaptcha = (token) => {
    if (token) {
      setCaptchaToken(token);
    }
  };
*/

  /*
  useEffect(() => {
    if (captchaToken) {
      validateCaptcha(captchaToken);
    }
  },
  [validateCaptcha, captchaToken]);
*/

  /*
  useEffect(() => {
    if (captchaIsValid && captcha.captcha.validated) {
      setCaptchaIsValid(false);
      setCaptchaToken(undefined);
    }

    if (captcha.captcha.validated) {
      setCaptchaIsValid(true);
      setCaptchaToken(undefined);
    }
  }, [captchaIsValid, captcha.captcha.validated]);
*/

  return (
    <div className="mt-3">
      <h3 className="custom-title black"> Confirma tus datos</h3>
      <div className="mb-3 text-muted margin-top--5">
        Te enviaremos el comprobante de tu reserva de cambio de aceite a tu
        correo
      </div>

      {station ? (
        <div className="row mb-3">
          <div className="col-md-4 col-sm-12 col-xs-12 mb-3">
            <div className="section-title d-inline-flex mb-2 mb-md-4">
              <img alt="Sucursal" src={schedule} width="30px" height="30px" />
              <span className="title-text custom-title ml-2">
                <h5 className="custom-title black">Estación de Servicio</h5>
              </span>
            </div>

            <Spacing>
              <h6 className="mr-2 text-bold text-muted">
                Estación de Servicio:&nbsp;
                <span className="text-black-50">{station.name}</span>
              </h6>

              <h6 className="mr-2 text-bold text-muted">
                Fecha:&nbsp;
                <span className="text-black-50 text-capitalize">
                  <Moment
                    locale="es"
                    format="dddd, DD MMMM YYYY"
                    element="span"
                  >
                    {date}
                  </Moment>
                </span>
              </h6>

              <h6 className="mr-2 text-bold text-black-50 text-muted">
                Hora:&nbsp;
                <Moment locale="es" format="HH:mm" element="span">
                  {date}
                </Moment>
                &nbsp;
                <span>Hrs</span>
              </h6>
            </Spacing>
          </div>

          <div className="col-md-4 col-sm-12 col-xs-12 mb-3">
            <div className="section-title d-inline-flex mb-2 mb-md-4">
              <img
                alt="Vehículo"
                src={car}
                className="mt-1"
                width="55px"
                height="25px"
              />
              <span className="title-text custom-title ml-2">
                <h5 className="custom-title black">Vehículo</h5>
              </span>
            </div>

            <h6 className="mr-2 text-muted">
              Patente:&nbsp;
              <span className="text-black-50">{patent}</span>
            </h6>

            <h6 className="mr-2 text-muted">
              Marca:&nbsp;
              <span className="text-black-50">{brand}</span>
            </h6>

            <h6 className="mr-2 text-muted">
              Modelo:&nbsp;
              <span className="text-black-50">{model}</span>
            </h6>

            <h6 className="mr-2 text-muted">
              Año:&nbsp;
              <span className="text-black-50">{year}</span>
            </h6>

            <h6 className="mr-2 text-muted">
              Tipo de combustible:&nbsp;
              <span className="text-black-50 text-capitalize">
                {fuel_type === "gasoline" ? "Bencina" : fuel_type}
              </span>
            </h6>
          </div>

          <div className="col-md-4 col-sm-12 col-xs-12 mb-3">
            <div className="section-title d-inline-flex mb-2 mb-md-4">
              <img
                alt="Cliente"
                src={person}
                className="mt-1"
                width="auto"
                height="25px"
              />
              <span className="title-text custom-title ml-2">
                <h5 className="custom-title black">Cliente</h5>
              </span>
            </div>

            <h6 className="mr-2 text-muted">
              Nombre:&nbsp;
              <span className="text-black-50">{name}</span>
            </h6>

            <h6 className="mr-2 text-muted">
              Correo:&nbsp;
              <span className="text-black-50">{email}</span>
            </h6>

            <h6 className="mr-2 text-muted">
              Teléfono:&nbsp;
              <span className="text-black-50">{phone}</span>
            </h6>
            {rut ? (
              <h6 className="mr-2 text-muted">
                Rut:&nbsp;
                <span className="text-black-50">{rut}</span>
              </h6>
            ) : null}
            {promotional_code ? (
              <h6 className="mr-2 text-muted">
                C&#243;digo:&nbsp;
                <span className="text-black-50">{promotional_code}</span>
              </h6>
            ) : null}
          </div>
          <div className="col-md-12">
            <TermsModal terms={terms} onUpdate={onUpdateTerms} />
          </div>
        </div>
      ) : (
        <div className="d-inline-flex">
          <Text
            size="iota"
            className="text-center mt-3"
            color={colors.gray45}
            bold
          >
            No hay datos disponibles.
          </Text>
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {/*
        <ReCAPTCHA
          sitekey={ process.env.REACT_APP_CAPTCHA_TOKEN }
          onChange={ onChangeCaptcha }
          onExpired={ onExpiredCaptcha }
          hl="es"
        />
*/}
      </div>
      <Stats
        disabled={!terms}
        step={5}
        // captchaIsValid={ captchaIsValid }
        {...props}
      />

      <ProductCarousel products={props.products} />
    </div>
  );
}

export default Confirm;
