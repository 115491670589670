import React, { useState, useEffect } from 'react';
import CustomNavBar from '../../../Commons/CustomNavBar';
import './styles.scss';

const Header = () => {
  const [onScrolling, setOnScrolling] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 20) {
      setOnScrolling(true);
    } else {
      setOnScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={ `navbar-wrapper ${onScrolling ? 'scrolling' : ''}` }>
      <CustomNavBar />
    </div>
  );
};

export default Header;
