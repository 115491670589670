import { ticketConstants, constants } from '../constants';

const initialState = {
  list: [],
  isLoading: false,
  error: '',
  hasError: false,
  success: false,
};

export function ticket(state = initialState, action) {
  switch (action.type) {
    case ticketConstants.CREATE_TICKET_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
        hasError: false,
      };
    case ticketConstants.CREATE_TICKET_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.ticket],
        success: true,
        isLoading: false,
        error: '',
        hasError: false,
      };
    case ticketConstants.CREATE_TICKET_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isLoading: false,
      };
    case constants.RESET_ERROR_STATE:
      return {
        ...state,
        error: '',
        hasError: false,
        success: false,
      };
    default:
      return state;
  }
}
