import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import oilBottle from './assets/oil-bottle.png';
import './styles.scss';

const ScheduleButton = () => {
  const [toBooking, setToBooking] = useState(false);

  const [onScrolling, setOnScrolling] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 400) {
      setOnScrolling(true);
    } else {
      setOnScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (toBooking) return (<Redirect to="/app" />);
  return (
    <div className={`text-center text-md-right schedule-button-box wrapper fade-in ${onScrolling ? 'scrolling' : ''}`}>
      <button className="btn-yellow rounded-shell text-uppercase red-text schedule-button-size desktop-display shell-bold" onClick={ () => { setToBooking(true); } }>
          <img src={oilBottle} className="schedule-button-icon" alt='Bidón de aceite'/>
          Agenda tu cambio de aceite aquí
      </button>
      <button className="btn-yellow rounded-shell text-uppercase red-text schedule-button-size mobile-display shell-bold" onClick={ () => { setToBooking(true); } }>
          <img src={oilBottle} className="schedule-button-icon" alt='Bidón de aceite'/> Agenda aquí
      </button>
    </div>
  );
};

export default ScheduleButton;
