import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { resetNotification } from "../../actions";
import Wizard from "./Wizard";
import {
  getCommunes,
  getConfig,
  getRegions,
  getStations,
} from "../../actions/stations";
import { updateStepAction } from "../../actions/wizard";
import { list } from "../../actions/block";
import {
  create,
  // validateCaptcha,
  retrievePromotionalCode,
} from "../../actions/booking";
import { retrieve } from "../../actions/vehicle";
import { getStationProductsByStation } from "../../actions/products";

const mapStateToProps = (state) => ({
  auth: state.auth,
  station: state.station,
  block: state.block,
  booking: state.booking,
  vehicle: state.vehicle,
  products: state.products.stationProducts,
  // captcha: state.captcha,
  promotionalCode: state.promotionalCode,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetNotification: () => {
    dispatch(resetNotification());
  },
  getRegions: () => {
    dispatch(getRegions());
  },
  getCommunes: (regionId) => {
    dispatch(getCommunes(regionId));
  },
  getStations: (communeId) => {
    dispatch(getStations(communeId));
  },
  getBlocks: (stationId) => {
    dispatch(list(stationId));
  },
  getConfig: (stationId) => {
    dispatch(getConfig(stationId));
  },
  createBooking: (data) => {
    dispatch(create(data));
  },
  retrieveVehicle: (patent) => {
    dispatch(retrieve(patent));
  },
  getStationProductsByStation: (stationId) => {
    dispatch(getStationProductsByStation(stationId));
  },
  updateStepAction: (step) => {
    dispatch(updateStepAction(step));
  },
/*
  validateCaptchaAction: (token) => {
    dispatch(validateCaptcha(token));
  },
*/
  validatePromotionalCode: (promotionalCode) => {
    dispatch(retrievePromotionalCode(promotionalCode));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Wizard));
