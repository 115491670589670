import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ children, path, exact }) => {
  const rootData = JSON.parse(localStorage.getItem("persist:root"));
  const auth = JSON.parse(rootData.auth);

  return (
    <>
      {auth.isLogged ? (
        <Route path={path} exact={exact}>
          {children}
        </Route>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default PrivateRoute;
