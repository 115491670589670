import { stationService } from '../services/station';
import { stationConstants } from '../constants';
import { handleError } from '../services';

export function getConfig(stationId) {
  function request() {
    return { type: stationConstants.GET_CONFIG_REQUEST };
  }

  function success(configurations) {
    return { type: stationConstants.GET_CONFIG_SUCCESS, configurations };
  }

  function failure(error) {
    error = handleError(error);
    return { type: stationConstants.GET_CONFIG_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    stationService.getConfig(stationId).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response)),
    );
  };
}

export function editConfig(configId, data) {
  function request() {
    return { type: stationConstants.EDIT_CONFIG_REQUEST };
  }

  function success(configuration) {
    return { type: stationConstants.EDIT_CONFIG_SUCCESS, configuration };
  }

  function failure(error) {
    error = handleError(error);
    return { type: stationConstants.EDIT_CONFIG_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    stationService.editConfig(configId, data).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response)),
    );
  };
}

export function getRegions() {
  function request() {
    return { type: stationConstants.GET_REGIONS_REQUEST };
  }

  function success(regions) {
    return { type: stationConstants.GET_REGIONS_SUCCESS, regions };
  }

  function failure(error) {
    error = handleError(error);
    return { type: stationConstants.GET_REGIONS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    stationService.getRegions().then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response)),
    );
  };
}

export function getCommunes(regionId) {
  function request() {
    return { type: stationConstants.GET_COMMUNES_REQUEST };
  }

  function success(communes) {
    return { type: stationConstants.GET_COMMUNES_SUCCESS, communes };
  }

  function failure(error) {
    error = handleError(error);
    return { type: stationConstants.GET_COMMUNES_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    stationService.getCommunes(regionId).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response)),
    );
  };
}

export function getStations(communeId) {
  function request() {
    return { type: stationConstants.GET_STATIONS_REQUEST };
  }

  function success(stations) {
    return { type: stationConstants.GET_STATIONS_SUCCESS, stations };
  }

  function failure(error) {
    error = handleError(error);
    return { type: stationConstants.GET_STATIONS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    stationService.getStations(communeId).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response)),
    );
  };
}

export function updateFullConfig(data) {
  function request() {
    return { type: stationConstants.EDIT_FULL_CONFIG_REQUEST };
  }

  function success(data) {
    return { type: stationConstants.EDIT_FULL_CONFIG_SUCCESS, data };
  }

  function failure(error) {
    error = handleError(error);
    return { type: stationConstants.EDIT_FULL_CONFIG_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    stationService.updateFullConfig(data).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response)),
    );
  };
}
