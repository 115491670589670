import React from 'react';
import { Button, Spinner } from 'react-elemental';
import { IoMdArrowDropleft as LeftArrow } from 'react-icons/io';
import { toSchedulePixel } from '../../utils/pixels';

class Stats extends React.Component {
  next() {
    if (this.props.saveData) { this.props.saveData(); }

    this.props.nextStep();
  }

  render() {
    const {
      previousStep,
      totalSteps,
      step,
      disabled,
      isLoading,
      // captchaIsValid,
      createBooking,
    } = this.props;

    return (
      <div className="my-3 my-lg-5">
        { step > 1
        && (
        <Button
          onClick={ previousStep }
          className="rounded-pill"
          style={ {
            color: 'white',
            fontSize: '1.8em',
            display: 'inline-flex',
            padding: '7px 8px',
          } }
        >
          <LeftArrow />
        </Button>
        )}
        { step < totalSteps
          ? (
            <Button
              className="rounded-pill"
              disabled={ disabled }
              text="Siguiente"
              onClick={ () => this.next() }
              style={ { float: 'right' } }
            />
          )
          : (
            <Button
              className="rounded-pill"
              text="Agendar"
              onClick={ () => {
                // if (captchaIsValid) {
                  createBooking();
                  toSchedulePixel();
                // }
              } }
              style={ { float: 'right' } }
              disabled={ disabled || isLoading } // || !captchaIsValid
            >
              { isLoading && (
              <Spinner
                size="gamma"
                className="ml-2"
              />
              ) }
            </Button>
          )}
      </div>
    );
  }
}

export default Stats;
