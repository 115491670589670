import React from "react";

const Button = ({
  children,
  element,
  openChildrenMenu,
  setItemActive,
  itemActive,
}) => {
  const pathname = window.location.pathname.replace("/dashboard", "");
  return (
    <>
      <div
        onClick={() =>
          element.children ? openChildrenMenu() : setItemActive(element.key)
        }
        className={`sidebar-button ${
          element.key === "sidebar-button-1" ? "first-element" : ""
        } 
                    ${
                      pathname === element.url || itemActive === element.key
                        ? "sidebar-active"
                        : ""
                    }`}
      >
        {children}
      </div>
    </>
  );
};

export default Button;
