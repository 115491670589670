import { wizardConstants } from '../constants';

const initialState = {
  currentStep: 0,
};

export function wizard(state = initialState, action) {
  switch (action.type) {
    case wizardConstants.WIZARD_UPDATE_STEP:
      return {
        ...state,
        currentStep: action.step,
      };
    default:
      return state;
  }
}
