import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import './styles.scss';

export default function OptionsDropdown(props) {
  const { children, size } = props;
  const [open, setOpen] = useState(false);

  const toggleVisibility = () => {
    if (open === false) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <div
      className="options-wrapper"
      onMouseEnter={ () => toggleVisibility() }
      onMouseLeave={ () => toggleVisibility() }
    >
      <BiDotsHorizontalRounded size={ size } />
      { open
        ? (
          <div className="menu">
            { children }
          </div>
        ) : null }
    </div>
  );
}

OptionsDropdown.propTypes = {
  children: PropTypes.element.isRequired,
  size: PropTypes.string,
};

OptionsDropdown.defaultProps = {
  size: '1.5rem',
};
