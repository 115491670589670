import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import localStorage from "redux-persist/lib/storage";
import rootReducer from "../reducers";

const persistConfig = {
  key: "root",
  storage: localStorage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    "booking",
    "station",
    "block",
    "vehicle",
    "products",
    "captcha",
    "promotionalCode",
    "ticket",
    "wizard",
  ],
};

const loggerMiddleware = createLogger();
const pReducer = persistReducer(persistConfig, rootReducer);

let middleware = [thunkMiddleware];
if (process.env.REACT_APP_NODE_ENV === 'development'){
  middleware = [...middleware, loggerMiddleware]
}

export const store = createStore(
  pReducer,
  applyMiddleware(...middleware)
  // applyMiddleware(thunkMiddleware, loggerMiddleware)
);

export const persistor = persistStore(store);
