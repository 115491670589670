/* tslint:disable */
/* eslint-disable */
/**
 * Enex API
 * API to manage bookings for oil change with Enex
 *
 * The version of the OpenAPI document: v1
 * Contact: jliznaga@linets.cl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BookingStationProductSave } from '../models';
/**
 * BookingStationProductsApi - axios parameter creator
 * @export
 */
export const BookingStationProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint that allows manage BookingStationProducts.
         * @param {BookingStationProductSave} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingStationProductsCreate: async (data: BookingStationProductSave, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('bookingStationProductsCreate', 'data', data)
            const localVarPath = `/booking_station_products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows manage BookingStationProducts.
         * @param {number} id A unique integer value identifying this producto aplicado en reserva.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingStationProductsDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingStationProductsDelete', 'id', id)
            const localVarPath = `/booking_station_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows manage BookingStationProducts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingStationProductsList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/booking_station_products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows manage BookingStationProducts.
         * @param {number} id A unique integer value identifying this producto aplicado en reserva.
         * @param {BookingStationProductSave} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingStationProductsPartialUpdate: async (id: number, data: BookingStationProductSave, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingStationProductsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('bookingStationProductsPartialUpdate', 'data', data)
            const localVarPath = `/booking_station_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows manage BookingStationProducts.
         * @param {number} id A unique integer value identifying this producto aplicado en reserva.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingStationProductsRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingStationProductsRead', 'id', id)
            const localVarPath = `/booking_station_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows manage BookingStationProducts.
         * @param {number} id A unique integer value identifying this producto aplicado en reserva.
         * @param {BookingStationProductSave} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingStationProductsUpdate: async (id: number, data: BookingStationProductSave, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingStationProductsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('bookingStationProductsUpdate', 'data', data)
            const localVarPath = `/booking_station_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingStationProductsApi - functional programming interface
 * @export
 */
export const BookingStationProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingStationProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * API endpoint that allows manage BookingStationProducts.
         * @param {BookingStationProductSave} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingStationProductsCreate(data: BookingStationProductSave, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingStationProductSave>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingStationProductsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API endpoint that allows manage BookingStationProducts.
         * @param {number} id A unique integer value identifying this producto aplicado en reserva.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingStationProductsDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingStationProductsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API endpoint that allows manage BookingStationProducts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingStationProductsList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BookingStationProductSave>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingStationProductsList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API endpoint that allows manage BookingStationProducts.
         * @param {number} id A unique integer value identifying this producto aplicado en reserva.
         * @param {BookingStationProductSave} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingStationProductsPartialUpdate(id: number, data: BookingStationProductSave, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingStationProductSave>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingStationProductsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API endpoint that allows manage BookingStationProducts.
         * @param {number} id A unique integer value identifying this producto aplicado en reserva.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingStationProductsRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingStationProductSave>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingStationProductsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API endpoint that allows manage BookingStationProducts.
         * @param {number} id A unique integer value identifying this producto aplicado en reserva.
         * @param {BookingStationProductSave} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingStationProductsUpdate(id: number, data: BookingStationProductSave, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingStationProductSave>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingStationProductsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookingStationProductsApi - factory interface
 * @export
 */
export const BookingStationProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingStationProductsApiFp(configuration)
    return {
        /**
         * API endpoint that allows manage BookingStationProducts.
         * @param {BookingStationProductSave} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingStationProductsCreate(data: BookingStationProductSave, options?: any): AxiosPromise<BookingStationProductSave> {
            return localVarFp.bookingStationProductsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows manage BookingStationProducts.
         * @param {number} id A unique integer value identifying this producto aplicado en reserva.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingStationProductsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.bookingStationProductsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows manage BookingStationProducts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingStationProductsList(options?: any): AxiosPromise<Array<BookingStationProductSave>> {
            return localVarFp.bookingStationProductsList(options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows manage BookingStationProducts.
         * @param {number} id A unique integer value identifying this producto aplicado en reserva.
         * @param {BookingStationProductSave} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingStationProductsPartialUpdate(id: number, data: BookingStationProductSave, options?: any): AxiosPromise<BookingStationProductSave> {
            return localVarFp.bookingStationProductsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows manage BookingStationProducts.
         * @param {number} id A unique integer value identifying this producto aplicado en reserva.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingStationProductsRead(id: number, options?: any): AxiosPromise<BookingStationProductSave> {
            return localVarFp.bookingStationProductsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows manage BookingStationProducts.
         * @param {number} id A unique integer value identifying this producto aplicado en reserva.
         * @param {BookingStationProductSave} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingStationProductsUpdate(id: number, data: BookingStationProductSave, options?: any): AxiosPromise<BookingStationProductSave> {
            return localVarFp.bookingStationProductsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for bookingStationProductsCreate operation in BookingStationProductsApi.
 * @export
 * @interface BookingStationProductsApiBookingStationProductsCreateRequest
 */
export interface BookingStationProductsApiBookingStationProductsCreateRequest {
    /**
     * 
     * @type {BookingStationProductSave}
     * @memberof BookingStationProductsApiBookingStationProductsCreate
     */
    readonly data: BookingStationProductSave
}

/**
 * Request parameters for bookingStationProductsDelete operation in BookingStationProductsApi.
 * @export
 * @interface BookingStationProductsApiBookingStationProductsDeleteRequest
 */
export interface BookingStationProductsApiBookingStationProductsDeleteRequest {
    /**
     * A unique integer value identifying this producto aplicado en reserva.
     * @type {number}
     * @memberof BookingStationProductsApiBookingStationProductsDelete
     */
    readonly id: number
}

/**
 * Request parameters for bookingStationProductsPartialUpdate operation in BookingStationProductsApi.
 * @export
 * @interface BookingStationProductsApiBookingStationProductsPartialUpdateRequest
 */
export interface BookingStationProductsApiBookingStationProductsPartialUpdateRequest {
    /**
     * A unique integer value identifying this producto aplicado en reserva.
     * @type {number}
     * @memberof BookingStationProductsApiBookingStationProductsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {BookingStationProductSave}
     * @memberof BookingStationProductsApiBookingStationProductsPartialUpdate
     */
    readonly data: BookingStationProductSave
}

/**
 * Request parameters for bookingStationProductsRead operation in BookingStationProductsApi.
 * @export
 * @interface BookingStationProductsApiBookingStationProductsReadRequest
 */
export interface BookingStationProductsApiBookingStationProductsReadRequest {
    /**
     * A unique integer value identifying this producto aplicado en reserva.
     * @type {number}
     * @memberof BookingStationProductsApiBookingStationProductsRead
     */
    readonly id: number
}

/**
 * Request parameters for bookingStationProductsUpdate operation in BookingStationProductsApi.
 * @export
 * @interface BookingStationProductsApiBookingStationProductsUpdateRequest
 */
export interface BookingStationProductsApiBookingStationProductsUpdateRequest {
    /**
     * A unique integer value identifying this producto aplicado en reserva.
     * @type {number}
     * @memberof BookingStationProductsApiBookingStationProductsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {BookingStationProductSave}
     * @memberof BookingStationProductsApiBookingStationProductsUpdate
     */
    readonly data: BookingStationProductSave
}

/**
 * BookingStationProductsApi - object-oriented interface
 * @export
 * @class BookingStationProductsApi
 * @extends {BaseAPI}
 */
export class BookingStationProductsApi extends BaseAPI {
    /**
     * API endpoint that allows manage BookingStationProducts.
     * @param {BookingStationProductsApiBookingStationProductsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingStationProductsApi
     */
    public bookingStationProductsCreate(requestParameters: BookingStationProductsApiBookingStationProductsCreateRequest, options?: AxiosRequestConfig) {
        return BookingStationProductsApiFp(this.configuration).bookingStationProductsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows manage BookingStationProducts.
     * @param {BookingStationProductsApiBookingStationProductsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingStationProductsApi
     */
    public bookingStationProductsDelete(requestParameters: BookingStationProductsApiBookingStationProductsDeleteRequest, options?: AxiosRequestConfig) {
        return BookingStationProductsApiFp(this.configuration).bookingStationProductsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows manage BookingStationProducts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingStationProductsApi
     */
    public bookingStationProductsList(options?: AxiosRequestConfig) {
        return BookingStationProductsApiFp(this.configuration).bookingStationProductsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows manage BookingStationProducts.
     * @param {BookingStationProductsApiBookingStationProductsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingStationProductsApi
     */
    public bookingStationProductsPartialUpdate(requestParameters: BookingStationProductsApiBookingStationProductsPartialUpdateRequest, options?: AxiosRequestConfig) {
        return BookingStationProductsApiFp(this.configuration).bookingStationProductsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows manage BookingStationProducts.
     * @param {BookingStationProductsApiBookingStationProductsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingStationProductsApi
     */
    public bookingStationProductsRead(requestParameters: BookingStationProductsApiBookingStationProductsReadRequest, options?: AxiosRequestConfig) {
        return BookingStationProductsApiFp(this.configuration).bookingStationProductsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows manage BookingStationProducts.
     * @param {BookingStationProductsApiBookingStationProductsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingStationProductsApi
     */
    public bookingStationProductsUpdate(requestParameters: BookingStationProductsApiBookingStationProductsUpdateRequest, options?: AxiosRequestConfig) {
        return BookingStationProductsApiFp(this.configuration).bookingStationProductsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
