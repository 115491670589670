/* tslint:disable */
/* eslint-disable */
/**
 * Enex API
 * API to manage bookings for oil change with Enex
 *
 * The version of the OpenAPI document: v1
 * Contact: jliznaga@linets.cl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { WorkOrder } from '../models';
// @ts-ignore
import { WorkOrdersList200Response } from '../models';
/**
 * WorkOrdersApi - axios parameter creator
 * @export
 */
export const WorkOrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Viewset model crud of customer.
         * @param {WorkOrder} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workOrdersCreate: async (data: WorkOrder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('workOrdersCreate', 'data', data)
            const localVarPath = `/work-orders/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Orden de trabajo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workOrdersDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('workOrdersDelete', 'id', id)
            const localVarPath = `/work-orders/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset model crud of customer.
         * @param {string} [otNumber] 
         * @param {string} [ticketNumber] 
         * @param {number} [station] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [rut] 
         * @param {string} [patent] 
         * @param {string} [search] A search term.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] Inform the page. Starting with 1. Default: 1
         * @param {number} [pageSize] Limit per page, Default: 8.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workOrdersList: async (otNumber?: string, ticketNumber?: string, station?: number, startDate?: string, endDate?: string, rut?: string, patent?: string, search?: string, ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/work-orders/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (otNumber !== undefined) {
                localVarQueryParameter['ot_number'] = otNumber;
            }

            if (ticketNumber !== undefined) {
                localVarQueryParameter['ticket_number'] = ticketNumber;
            }

            if (station !== undefined) {
                localVarQueryParameter['station'] = station;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (rut !== undefined) {
                localVarQueryParameter['rut'] = rut;
            }

            if (patent !== undefined) {
                localVarQueryParameter['patent'] = patent;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Orden de trabajo.
         * @param {WorkOrder} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workOrdersPartialUpdate: async (id: number, data: WorkOrder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('workOrdersPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('workOrdersPartialUpdate', 'data', data)
            const localVarPath = `/work-orders/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Orden de trabajo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workOrdersRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('workOrdersRead', 'id', id)
            const localVarPath = `/work-orders/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Orden de trabajo.
         * @param {WorkOrder} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workOrdersUpdate: async (id: number, data: WorkOrder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('workOrdersUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('workOrdersUpdate', 'data', data)
            const localVarPath = `/work-orders/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkOrdersApi - functional programming interface
 * @export
 */
export const WorkOrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkOrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * Viewset model crud of customer.
         * @param {WorkOrder} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workOrdersCreate(data: WorkOrder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workOrdersCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Orden de trabajo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workOrdersDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workOrdersDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Viewset model crud of customer.
         * @param {string} [otNumber] 
         * @param {string} [ticketNumber] 
         * @param {number} [station] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [rut] 
         * @param {string} [patent] 
         * @param {string} [search] A search term.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] Inform the page. Starting with 1. Default: 1
         * @param {number} [pageSize] Limit per page, Default: 8.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workOrdersList(otNumber?: string, ticketNumber?: string, station?: number, startDate?: string, endDate?: string, rut?: string, patent?: string, search?: string, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrdersList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workOrdersList(otNumber, ticketNumber, station, startDate, endDate, rut, patent, search, ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Orden de trabajo.
         * @param {WorkOrder} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workOrdersPartialUpdate(id: number, data: WorkOrder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workOrdersPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Orden de trabajo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workOrdersRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workOrdersRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Orden de trabajo.
         * @param {WorkOrder} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workOrdersUpdate(id: number, data: WorkOrder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workOrdersUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkOrdersApi - factory interface
 * @export
 */
export const WorkOrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkOrdersApiFp(configuration)
    return {
        /**
         * Viewset model crud of customer.
         * @param {WorkOrder} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workOrdersCreate(data: WorkOrder, options?: any): AxiosPromise<WorkOrder> {
            return localVarFp.workOrdersCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Orden de trabajo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workOrdersDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.workOrdersDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset model crud of customer.
         * @param {string} [otNumber] 
         * @param {string} [ticketNumber] 
         * @param {number} [station] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [rut] 
         * @param {string} [patent] 
         * @param {string} [search] A search term.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] Inform the page. Starting with 1. Default: 1
         * @param {number} [pageSize] Limit per page, Default: 8.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workOrdersList(otNumber?: string, ticketNumber?: string, station?: number, startDate?: string, endDate?: string, rut?: string, patent?: string, search?: string, ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<WorkOrdersList200Response> {
            return localVarFp.workOrdersList(otNumber, ticketNumber, station, startDate, endDate, rut, patent, search, ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Orden de trabajo.
         * @param {WorkOrder} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workOrdersPartialUpdate(id: number, data: WorkOrder, options?: any): AxiosPromise<WorkOrder> {
            return localVarFp.workOrdersPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Orden de trabajo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workOrdersRead(id: number, options?: any): AxiosPromise<WorkOrder> {
            return localVarFp.workOrdersRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Orden de trabajo.
         * @param {WorkOrder} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workOrdersUpdate(id: number, data: WorkOrder, options?: any): AxiosPromise<WorkOrder> {
            return localVarFp.workOrdersUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for workOrdersCreate operation in WorkOrdersApi.
 * @export
 * @interface WorkOrdersApiWorkOrdersCreateRequest
 */
export interface WorkOrdersApiWorkOrdersCreateRequest {
    /**
     * 
     * @type {WorkOrder}
     * @memberof WorkOrdersApiWorkOrdersCreate
     */
    readonly data: WorkOrder
}

/**
 * Request parameters for workOrdersDelete operation in WorkOrdersApi.
 * @export
 * @interface WorkOrdersApiWorkOrdersDeleteRequest
 */
export interface WorkOrdersApiWorkOrdersDeleteRequest {
    /**
     * A unique integer value identifying this Orden de trabajo.
     * @type {number}
     * @memberof WorkOrdersApiWorkOrdersDelete
     */
    readonly id: number
}

/**
 * Request parameters for workOrdersList operation in WorkOrdersApi.
 * @export
 * @interface WorkOrdersApiWorkOrdersListRequest
 */
export interface WorkOrdersApiWorkOrdersListRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkOrdersApiWorkOrdersList
     */
    readonly otNumber?: string

    /**
     * 
     * @type {string}
     * @memberof WorkOrdersApiWorkOrdersList
     */
    readonly ticketNumber?: string

    /**
     * 
     * @type {number}
     * @memberof WorkOrdersApiWorkOrdersList
     */
    readonly station?: number

    /**
     * 
     * @type {string}
     * @memberof WorkOrdersApiWorkOrdersList
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof WorkOrdersApiWorkOrdersList
     */
    readonly endDate?: string

    /**
     * 
     * @type {string}
     * @memberof WorkOrdersApiWorkOrdersList
     */
    readonly rut?: string

    /**
     * 
     * @type {string}
     * @memberof WorkOrdersApiWorkOrdersList
     */
    readonly patent?: string

    /**
     * A search term.
     * @type {string}
     * @memberof WorkOrdersApiWorkOrdersList
     */
    readonly search?: string

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof WorkOrdersApiWorkOrdersList
     */
    readonly ordering?: string

    /**
     * Inform the page. Starting with 1. Default: 1
     * @type {number}
     * @memberof WorkOrdersApiWorkOrdersList
     */
    readonly page?: number

    /**
     * Limit per page, Default: 8.
     * @type {number}
     * @memberof WorkOrdersApiWorkOrdersList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for workOrdersPartialUpdate operation in WorkOrdersApi.
 * @export
 * @interface WorkOrdersApiWorkOrdersPartialUpdateRequest
 */
export interface WorkOrdersApiWorkOrdersPartialUpdateRequest {
    /**
     * A unique integer value identifying this Orden de trabajo.
     * @type {number}
     * @memberof WorkOrdersApiWorkOrdersPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {WorkOrder}
     * @memberof WorkOrdersApiWorkOrdersPartialUpdate
     */
    readonly data: WorkOrder
}

/**
 * Request parameters for workOrdersRead operation in WorkOrdersApi.
 * @export
 * @interface WorkOrdersApiWorkOrdersReadRequest
 */
export interface WorkOrdersApiWorkOrdersReadRequest {
    /**
     * A unique integer value identifying this Orden de trabajo.
     * @type {number}
     * @memberof WorkOrdersApiWorkOrdersRead
     */
    readonly id: number
}

/**
 * Request parameters for workOrdersUpdate operation in WorkOrdersApi.
 * @export
 * @interface WorkOrdersApiWorkOrdersUpdateRequest
 */
export interface WorkOrdersApiWorkOrdersUpdateRequest {
    /**
     * A unique integer value identifying this Orden de trabajo.
     * @type {number}
     * @memberof WorkOrdersApiWorkOrdersUpdate
     */
    readonly id: number

    /**
     * 
     * @type {WorkOrder}
     * @memberof WorkOrdersApiWorkOrdersUpdate
     */
    readonly data: WorkOrder
}

/**
 * WorkOrdersApi - object-oriented interface
 * @export
 * @class WorkOrdersApi
 * @extends {BaseAPI}
 */
export class WorkOrdersApi extends BaseAPI {
    /**
     * Viewset model crud of customer.
     * @param {WorkOrdersApiWorkOrdersCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkOrdersApi
     */
    public workOrdersCreate(requestParameters: WorkOrdersApiWorkOrdersCreateRequest, options?: AxiosRequestConfig) {
        return WorkOrdersApiFp(this.configuration).workOrdersCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset model crud of customer.
     * @param {WorkOrdersApiWorkOrdersDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkOrdersApi
     */
    public workOrdersDelete(requestParameters: WorkOrdersApiWorkOrdersDeleteRequest, options?: AxiosRequestConfig) {
        return WorkOrdersApiFp(this.configuration).workOrdersDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset model crud of customer.
     * @param {WorkOrdersApiWorkOrdersListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkOrdersApi
     */
    public workOrdersList(requestParameters: WorkOrdersApiWorkOrdersListRequest = {}, options?: AxiosRequestConfig) {
        return WorkOrdersApiFp(this.configuration).workOrdersList(requestParameters.otNumber, requestParameters.ticketNumber, requestParameters.station, requestParameters.startDate, requestParameters.endDate, requestParameters.rut, requestParameters.patent, requestParameters.search, requestParameters.ordering, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset model crud of customer.
     * @param {WorkOrdersApiWorkOrdersPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkOrdersApi
     */
    public workOrdersPartialUpdate(requestParameters: WorkOrdersApiWorkOrdersPartialUpdateRequest, options?: AxiosRequestConfig) {
        return WorkOrdersApiFp(this.configuration).workOrdersPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset model crud of customer.
     * @param {WorkOrdersApiWorkOrdersReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkOrdersApi
     */
    public workOrdersRead(requestParameters: WorkOrdersApiWorkOrdersReadRequest, options?: AxiosRequestConfig) {
        return WorkOrdersApiFp(this.configuration).workOrdersRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset model crud of customer.
     * @param {WorkOrdersApiWorkOrdersUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkOrdersApi
     */
    public workOrdersUpdate(requestParameters: WorkOrdersApiWorkOrdersUpdateRequest, options?: AxiosRequestConfig) {
        return WorkOrdersApiFp(this.configuration).workOrdersUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
