/* tslint:disable */
/* eslint-disable */
/**
 * Enex API
 * API to manage bookings for oil change with Enex
 *
 * The version of the OpenAPI document: v1
 * Contact: jliznaga@linets.cl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Vehicle } from '../models';
/**
 * VehiclesApi - axios parameter creator
 * @export
 */
export const VehiclesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint that allows retrieve vehicles by patent.
         * @param {Vehicle} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesCreate: async (data: Vehicle, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('vehiclesCreate', 'data', data)
            const localVarPath = `/vehicles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows retrieve vehicles by patent.
         * @param {string} patent A unique value identifying this vehículo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesDelete: async (patent: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patent' is not null or undefined
            assertParamExists('vehiclesDelete', 'patent', patent)
            const localVarPath = `/vehicles/{patent}/`
                .replace(`{${"patent"}}`, encodeURIComponent(String(patent)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows retrieve vehicles by patent.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vehicles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows retrieve vehicles by patent.
         * @param {string} patent A unique value identifying this vehículo.
         * @param {Vehicle} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesPartialUpdate: async (patent: string, data: Vehicle, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patent' is not null or undefined
            assertParamExists('vehiclesPartialUpdate', 'patent', patent)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('vehiclesPartialUpdate', 'data', data)
            const localVarPath = `/vehicles/{patent}/`
                .replace(`{${"patent"}}`, encodeURIComponent(String(patent)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows retrieve vehicles by patent.
         * @param {string} patent A unique value identifying this vehículo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesRead: async (patent: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patent' is not null or undefined
            assertParamExists('vehiclesRead', 'patent', patent)
            const localVarPath = `/vehicles/{patent}/`
                .replace(`{${"patent"}}`, encodeURIComponent(String(patent)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows retrieve vehicles by patent.
         * @param {string} patent A unique value identifying this vehículo.
         * @param {Vehicle} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesUpdate: async (patent: string, data: Vehicle, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patent' is not null or undefined
            assertParamExists('vehiclesUpdate', 'patent', patent)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('vehiclesUpdate', 'data', data)
            const localVarPath = `/vehicles/{patent}/`
                .replace(`{${"patent"}}`, encodeURIComponent(String(patent)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehiclesApi - functional programming interface
 * @export
 */
export const VehiclesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VehiclesApiAxiosParamCreator(configuration)
    return {
        /**
         * API endpoint that allows retrieve vehicles by patent.
         * @param {Vehicle} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehiclesCreate(data: Vehicle, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Vehicle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehiclesCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API endpoint that allows retrieve vehicles by patent.
         * @param {string} patent A unique value identifying this vehículo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehiclesDelete(patent: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehiclesDelete(patent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API endpoint that allows retrieve vehicles by patent.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehiclesList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vehicle>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehiclesList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API endpoint that allows retrieve vehicles by patent.
         * @param {string} patent A unique value identifying this vehículo.
         * @param {Vehicle} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehiclesPartialUpdate(patent: string, data: Vehicle, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Vehicle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehiclesPartialUpdate(patent, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API endpoint that allows retrieve vehicles by patent.
         * @param {string} patent A unique value identifying this vehículo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehiclesRead(patent: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Vehicle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehiclesRead(patent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API endpoint that allows retrieve vehicles by patent.
         * @param {string} patent A unique value identifying this vehículo.
         * @param {Vehicle} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vehiclesUpdate(patent: string, data: Vehicle, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Vehicle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vehiclesUpdate(patent, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VehiclesApi - factory interface
 * @export
 */
export const VehiclesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehiclesApiFp(configuration)
    return {
        /**
         * API endpoint that allows retrieve vehicles by patent.
         * @param {Vehicle} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesCreate(data: Vehicle, options?: any): AxiosPromise<Vehicle> {
            return localVarFp.vehiclesCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows retrieve vehicles by patent.
         * @param {string} patent A unique value identifying this vehículo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesDelete(patent: string, options?: any): AxiosPromise<void> {
            return localVarFp.vehiclesDelete(patent, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows retrieve vehicles by patent.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesList(options?: any): AxiosPromise<Array<Vehicle>> {
            return localVarFp.vehiclesList(options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows retrieve vehicles by patent.
         * @param {string} patent A unique value identifying this vehículo.
         * @param {Vehicle} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesPartialUpdate(patent: string, data: Vehicle, options?: any): AxiosPromise<Vehicle> {
            return localVarFp.vehiclesPartialUpdate(patent, data, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows retrieve vehicles by patent.
         * @param {string} patent A unique value identifying this vehículo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesRead(patent: string, options?: any): AxiosPromise<Vehicle> {
            return localVarFp.vehiclesRead(patent, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows retrieve vehicles by patent.
         * @param {string} patent A unique value identifying this vehículo.
         * @param {Vehicle} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehiclesUpdate(patent: string, data: Vehicle, options?: any): AxiosPromise<Vehicle> {
            return localVarFp.vehiclesUpdate(patent, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for vehiclesCreate operation in VehiclesApi.
 * @export
 * @interface VehiclesApiVehiclesCreateRequest
 */
export interface VehiclesApiVehiclesCreateRequest {
    /**
     * 
     * @type {Vehicle}
     * @memberof VehiclesApiVehiclesCreate
     */
    readonly data: Vehicle
}

/**
 * Request parameters for vehiclesDelete operation in VehiclesApi.
 * @export
 * @interface VehiclesApiVehiclesDeleteRequest
 */
export interface VehiclesApiVehiclesDeleteRequest {
    /**
     * A unique value identifying this vehículo.
     * @type {string}
     * @memberof VehiclesApiVehiclesDelete
     */
    readonly patent: string
}

/**
 * Request parameters for vehiclesPartialUpdate operation in VehiclesApi.
 * @export
 * @interface VehiclesApiVehiclesPartialUpdateRequest
 */
export interface VehiclesApiVehiclesPartialUpdateRequest {
    /**
     * A unique value identifying this vehículo.
     * @type {string}
     * @memberof VehiclesApiVehiclesPartialUpdate
     */
    readonly patent: string

    /**
     * 
     * @type {Vehicle}
     * @memberof VehiclesApiVehiclesPartialUpdate
     */
    readonly data: Vehicle
}

/**
 * Request parameters for vehiclesRead operation in VehiclesApi.
 * @export
 * @interface VehiclesApiVehiclesReadRequest
 */
export interface VehiclesApiVehiclesReadRequest {
    /**
     * A unique value identifying this vehículo.
     * @type {string}
     * @memberof VehiclesApiVehiclesRead
     */
    readonly patent: string
}

/**
 * Request parameters for vehiclesUpdate operation in VehiclesApi.
 * @export
 * @interface VehiclesApiVehiclesUpdateRequest
 */
export interface VehiclesApiVehiclesUpdateRequest {
    /**
     * A unique value identifying this vehículo.
     * @type {string}
     * @memberof VehiclesApiVehiclesUpdate
     */
    readonly patent: string

    /**
     * 
     * @type {Vehicle}
     * @memberof VehiclesApiVehiclesUpdate
     */
    readonly data: Vehicle
}

/**
 * VehiclesApi - object-oriented interface
 * @export
 * @class VehiclesApi
 * @extends {BaseAPI}
 */
export class VehiclesApi extends BaseAPI {
    /**
     * API endpoint that allows retrieve vehicles by patent.
     * @param {VehiclesApiVehiclesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public vehiclesCreate(requestParameters: VehiclesApiVehiclesCreateRequest, options?: AxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).vehiclesCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows retrieve vehicles by patent.
     * @param {VehiclesApiVehiclesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public vehiclesDelete(requestParameters: VehiclesApiVehiclesDeleteRequest, options?: AxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).vehiclesDelete(requestParameters.patent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows retrieve vehicles by patent.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public vehiclesList(options?: AxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).vehiclesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows retrieve vehicles by patent.
     * @param {VehiclesApiVehiclesPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public vehiclesPartialUpdate(requestParameters: VehiclesApiVehiclesPartialUpdateRequest, options?: AxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).vehiclesPartialUpdate(requestParameters.patent, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows retrieve vehicles by patent.
     * @param {VehiclesApiVehiclesReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public vehiclesRead(requestParameters: VehiclesApiVehiclesReadRequest, options?: AxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).vehiclesRead(requestParameters.patent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows retrieve vehicles by patent.
     * @param {VehiclesApiVehiclesUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public vehiclesUpdate(requestParameters: VehiclesApiVehiclesUpdateRequest, options?: AxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).vehiclesUpdate(requestParameters.patent, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
