import { bookingConstants, constants } from "../constants";

const initialState = {
  promotionalCode: { validated: false, message: "" },
  isLoading: false,
  error: "",
  hasError: false,
  success: false,
};

export function promotionalCode(state = initialState, action) {
  switch (action.type) {
    case bookingConstants.VALIDATE_PROMOTIONAL_CODE_REQUEST:
      return initialState;
    case bookingConstants.VALIDATE_PROMOTIONAL_CODE_SUCCESS: {
      return {
        ...state,
        promotionalCode: action.promotionalCode,
        isLoading: false,
        error: "",
        hasError: false,
      };
    }
    case bookingConstants.VALIDATE_PROMOTIONAL_CODE_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isLoading: false,
      };
    case constants.RESET_ERROR_STATE:
      return {
        ...state,
        error: "",
        hasError: false,
        success: false,
      };
    default:
      return state;
  }
}
