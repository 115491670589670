import ReactPixel from 'react-facebook-pixel';

export function initPixel() {
  const options = {
    autoConfig: false,
    debug: true,
  };

  ReactPixel.init('1177032203751814', options);
}

export function pageViewPixel() {
  ReactPixel.pageView();
}

export function toSchedulePixel() {
  // FacebookPixels
  ReactPixel.trackCustom('HelixAgenda');
  ReactPixel.trackCustom('HelixFinalizar');
  ReactPixel.trackCustom('Schedule');
  // GooglePixels
  window.gtag('event', 'conversion',
    { send_to: 'AW-439393550/3m1oCMjL9PIBEI66wtEB' });
}
