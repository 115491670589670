import * as React from 'react';
import Moment from 'react-moment';

class TimeBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _class: '',
    };
  }

  componentDidMount() {
    this.props.selected ? this.setState({ _class: 'selected' }) : this.setState(
      { _class: '' },
    );
    if (this.props.disabled) this.setState({ _class: 'disabled' });
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    nextProps.selected ? this.setState({ _class: 'selected' }) : this.setState(
      { _class: '' },
    );
    if (nextProps.disabled) this.setState({ _class: 'disabled' });
  }

  render() {
    return (
      <div
        className={ `block-container text-center ${this.state._class}` }
        onClick={ () => { this.props.selectBlock(); } }
      >
        <Moment format="HH:mm">
          { this.props.start }
        </Moment>
      </div>
    );
  }
}

export default TimeBlock;
