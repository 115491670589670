import helixUltra from './assets/helix-ultra.png';
import helixHX7 from './assets/helix-hx7.png';
import helixHX8 from './assets/helix-hx8.png';

export const lubIconList = [
    {imgPath: helixUltra, title: 'Shell Helix Ultra', description: 'Ayuda al máximo desempeño', key: 1},
    {imgPath: helixHX8, title: 'Shell Helix HX8', description: 'Excelente limpieza y protección', key: 2},
    {imgPath: helixHX7, title: 'Shell Helix HX7', description: 'Ayuda a extender la vida del motor', key: 3},
    {imgPath: helixUltra, title: 'Shell Helix Ultra', description: 'Ayuda al máximo desempeño', key: 4},
    {imgPath: helixHX8, title: 'Shell Helix HX8', description: 'Excelente limpieza y protección', key: 5},
    {imgPath: helixHX7, title: 'Shell Helix HX7', description: 'Ayuda a extender la vida del motor', key: 6},
    {imgPath: helixUltra, title: 'Shell Helix Ultra', description: 'Ayuda al máximo desempeño', key: 7},
    {imgPath: helixHX8, title: 'Shell Helix HX8', description: 'Excelente limpieza y protección', key: 8},
    {imgPath: helixHX7, title: 'Shell Helix HX7', description: 'Ayuda a extender la vida del motor', key: 9}
]

export const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

