import * as React from 'react';
import './Car.scss';
import { Spacing, TextField, LoadingBar } from 'react-elemental';
import Select from 'react-select';
import Stats from '../../Stats';
import { customSelect } from '../../../../commons/style/style';
import { showNotification } from '../../../../actions';
import { errorType } from '../../../../constants';

class Car extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patent: '',
      brand: '',
      model: '',
      year: '',
      fuel_type: '',
      cValues: null,
      loading: false,
    };

    this.combustibles = [
      { label: 'Gasolina', value: 'gasoline' },
      { label: 'Diésel', value: 'diesel' },
    ]

    this.selectType = this.selectType.bind(this);
    this.validate = this.validate.bind(this);
    this.handleData = this.handleData.bind(this);
    this.validateYear = this.validateYear.bind(this);
    this.validatePatent = this.validatePatent.bind(this);
    this.populateValues = this.populateValues.bind(this);
    this.validateCapacity = this.validateCapacity.bind(this);
    this.onHandleChangeYear = this.onHandleChangeYear.bind(this);
    this.onHandleChangePatent = this.onHandleChangePatent.bind(this);
    this.onHandleChangeCapacity = this.onHandleChangeCapacity.bind(this);
  }

  selectType() {
    return this.combustibles.find(o=>o.value == this.state.fuel_type) || '';
  }

  validatePatent(patent) {
    if (patent === '') { return ''; }
    // const re = new RegExp('^([a-z-A-Z]{4}[0-9]{2})|([a-z-A-Z]{2}[0-9]{4})$');
    const re = new RegExp('^[A-Z]{2}[A-Z\\d]{2}[\\d]{2}$');
    return re.test(patent) ? '' : 'No es una patente válida';
  }

  validateYear(year) {
    if (year === '') return '';

    const re = new RegExp('^[0-9]{4}$');
    if (!re.test(year)) return 'No es un año válido';

    if (parseInt(year) < 1900 || parseInt(year) > new Date().getFullYear()) return 'Debe escribir un año superior a 1970 y menor o igual al actual';

    return '';
  }

  onHandleChangeYear(value) {
    if (!Number.isNaN(value)) this.setState({ year: value });
  }

  validateCapacity(capacity) {
    if (capacity === '') return '';
    const re = new RegExp('([0-9]*[.])?[0-9]+$');
    return re.test(capacity) ? '' : 'No es un valor válido';
  }

  onHandleChangeCapacity(value) {
    if (!Number.isNaN(value) || value === '.') this.setState({ fuel_type: value });
  }

  onHandleChangePatent(value) {
    this.setState({ patent: value.toUpperCase() });
    if (value.length === 6) {
      this.setState({ loading: true }, () => this.props.retrieveVehicle(value));
    }
  }

  populateValues() {
    const temp = [];
    for (let i = 800; i < 5500; i += 100) {
      temp.push(i / 1000);
    }
    this.setState({ cValues: temp });
  }

  handleData() {
    if (this.validate()) {
      const data = {
        patent: this.state.patent,
        brand: this.state.brand,
        model: this.state.model,
        year: this.state.year,
        fuel_type: this.state.fuel_type,
      };
      this.props.onChangeCarData(data);
    }
  }

  validate() {
    const {
      patent, brand, model, year, fuel_type,
    } = this.state;
    if (patent === '' || brand === '' || model === '' || year === ''
      || fuel_type === '') return false;

    return !(this.validatePatent(patent) !== '' || this.validateYear(year)
      !== '');
  }

  componentDidMount() {
    this.populateValues();
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.loading &&nextProps.vehicle && nextProps.vehicle.hasError) {
      showNotification(errorType.ERROR, nextProps.vehicle.error);
      this.props.resetNotification();
      this.setState({ brand: '' , model: '', year: '', fuel_type: '', loading: false});
    }

    if (this.state.loading && nextProps.vehicle && nextProps.vehicle.isSuccess) {
      this.setState({
        brand: nextProps.vehicle.data.brand,
        model: nextProps.vehicle.data.model,
        year: nextProps.vehicle.data.year,
        fuel_type: nextProps.vehicle.data.fuel_type,
        loading: false
      });
    }
  }

  render() {
    return (
      <div className="mt-3">
        <h3 className="custom-title black">Datos del vehículo</h3>
        <div className="text-muted margin-top--5 mb-3">
          Ingresa tu patente y
          completa los datos de tu vehículo
        </div>
        <div className="row mb-3 mb-md-5">

          <div className="col-md-4 col-lg-2">
            <Spacing>
              <label className="text-muted custom-label">
                Patente
                *
              </label>
              <TextField
                placeholder="Patente: AABB33"
                className="rounded-pill"
                value={ this.state.patent }
                onChange={ (e) => this.onHandleChangePatent(
                  e.target.value.toUpperCase(),
                ) }
                error={ this.validatePatent(this.state.patent) }
                onBlur={ () => this.handleData() }
                disabled={ this.props.vehicle.isSearching }
                required
              />
            </Spacing>
            { this.props.vehicle.isSearching
            && (
            <Spacing>
              <LoadingBar
                color="red"
                thickness={ 4 }
                duration={ 500 }
                delay={ 50 }
              />
            </Spacing>
            )}
          </div>

          <div className="col-md-4 col-lg-2 mt-2 mt-md-0">
            <Spacing>
              <label className="text-muted custom-label">
                Marca
                *
              </label>
              <TextField
                placeholder="Marca"
                className="rounded-pill"
                value={ this.state.brand }
                onChange={ (e) => this.setState(
                  { brand: e.target.value },
                ) }
                onBlur={ () => this.handleData() }
                required
              />
            </Spacing>
          </div>

          <div className="col-md-4 col-lg-2 mt-2 mt-md-0">
            <Spacing>
              <label className="text-muted custom-label">
                Modelo
                *
              </label>
              <TextField
                placeholder="Modelo"
                className="rounded-pill"
                value={ this.state.model }
                onChange={ (e) => this.setState(
                  { model: e.target.value },
                ) }
                onBlur={ () => this.handleData() }
                required
              />
            </Spacing>
          </div>

          <div className="col-md-4 col-lg-2 mt-2 mt-lg-0">
            <Spacing>
              <label className="text-muted custom-label">
                Año de
                fabricación *
              </label>
              <TextField
                placeholder="Año"
                className="rounded-pill"
                value={ this.state.year }
                onChange={ (e) => this.onHandleChangeYear(
                  e.target.value,
                ) }
                error={ this.validateYear(this.state.year) }
                onBlur={ () => this.handleData() }
                required
              />
            </Spacing>
          </div>

          {
            this.state.cValues
            && (
            <div className="col-md-4 col-lg-3 mt-2 mt-lg-0">
              <Spacing>
                <label className="text-muted custom-label">
                  Tipo
                  de combustible *
                </label>
                <Select
                  styles={ customSelect }
                  value={ this.selectType()}
                  placeholder="Tipo de combustible"
                  onChange={ (item) => this.setState(
                    { fuel_type: item.value },
                  ) }
                  onBlur={ () => this.handleData() }
                  noOptionsMessage={ () => 'No hay datos cargados' }
                  options={ this.combustibles }
                />
              </Spacing>
            </div>
            )
          }
        </div>

        <Stats
          step={ 3 }
          { ...this.props }
          disabled={ !this.validate() }
          saveData={ this.handleData }
        />
      </div>
    );
  }
}

export default Car;
