import { axios_instance } from './index';

export async function list(stationId) {
  return axios_instance.get(`blocks/station/${stationId}/`);
}

export async function create(data) {
  return axios_instance.post('blocks/', data);
}

export async function remove(blockId) {
  return axios_instance.delete(`blocks/${blockId}/`);
}

export const blockService = {
  list,
  create,
  remove,
};
