export const authConstants = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  RESET_REQUEST: 'RESET_REQUEST',
  RESET_SUCCESS: 'RESET_SUCCESS',
  RESET_FAILURE: 'RESET_FAILURE',

  CHANGE_REQUEST: 'CHANGE_REQUEST',
  CHANGE_SUCCESS: 'CHANGE_SUCCESS',
  CHANGE_FAILURE: 'CHANGE_FAILURE',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
};
