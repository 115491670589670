import React from 'react';
import PropTypes from 'prop-types';
import { Button, Text } from 'react-elemental';
import { Modal } from 'react-bootstrap';

const ConfirmModal = ({
  show, onHandleSubmit, onHandleClose, message,
}) => (
  <Modal
    show={ show }
    id="confirm_modal"
    onHide={ onHandleClose }
    size="xs"
    centered
    dialogClassName="ap-modal"
    backdrop="static"
  >
    <h5 className="mb-4">{ message }</h5>
    <Text right>
      <Button
        text="No"
        secondary
        className="mr-3 rounded-pill"
        onClick={ () => onHandleClose() }
      />
      <Button
        text="Si"
        className="rounded-pill"
        onClick={ () => {
          onHandleSubmit();
          onHandleClose();
        } }
      />
    </Text>
  </Modal>
);

ConfirmModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHandleSubmit: PropTypes.func.isRequired,
  onHandleClose: PropTypes.func.isRequired,
  message: PropTypes.string,
};

ConfirmModal.defaultProps = {
  message: '¿Realmente deseas continuar?',
};

export default ConfirmModal;
