import React, { useState } from "react";
import { allowOnlyNumbers } from "../../../../../utils/helpers";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { HiInformationCircle } from "react-icons/hi";
import transactionNumber from "../../../../../commons/assets/boleta_factura.png";

const FourthStep = ({
  newOrderData,
  setNewOrderData,
  submitOrder,
  setFormStep,
}) => {
  const [disableOnSubmit, setDisableOnSubmit] = useState(false);

  const popoverHoverFocus = (
    <Popover id="popover-trigger-hover-focus">
      <Popover.Title as="h4">
        ¿Dónde encontrar el número de boleta o factura?
      </Popover.Title>
      <Popover.Content>
        <img
          src={transactionNumber}
          className="popover-image"
          alt="Transaction number example"
        />
      </Popover.Content>
    </Popover>
  );

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <h5 className="red-text">Datos del cliente</h5>
          <div className="row">
            <div className="col-6">
              <div>Nombre:</div>
              <div>Rut:</div>
              <div>Teléfono:</div>
              <div>Correo:</div>
            </div>
            <div className="col-6">
              <div>{newOrderData.booking.customer.name}</div>
              <div>{newOrderData.booking.customer.phone}</div>
              <div>{newOrderData.booking.customer.rut}</div>
              <div>{newOrderData.booking.customer.email}</div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h5 className="red-text">Datos del vehículo</h5>
          <div className="row">
            <div className="col-6">
              <div>Patente:</div>
              <div>Marca:</div>
              <div>Modelo:</div>
              <div>Año:</div>
              <div>Cilindrada:</div>
              <div>Kilometraje:</div>
              <div>Combustible:</div>
            </div>
            <div className="col-6">
              <div>{newOrderData.booking.vehicle.patent}</div>
              <div>{newOrderData.booking.vehicle.brand}</div>
              <div>{newOrderData.booking.vehicle.model}</div>
              <div>{newOrderData.booking.vehicle.year}</div>
              <div>{newOrderData.booking.vehicle.cylinder_capacity}</div>
              <div>{newOrderData.booking.vehicle.mileage}</div>
              <div>{newOrderData.booking.vehicle.fuel_type}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="d-block" htmlFor="mileage">
            Número de boleta o factura
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="right"
              overlay={popoverHoverFocus}
            >
              <HiInformationCircle />
            </OverlayTrigger>
          </label>
          <input
            name="ticket_number"
            className="d-block new-order-input"
            value={newOrderData.ticket_number}
            onChange={(e) => {
              e.persist();
              const ticketNumber = allowOnlyNumbers(
                e.target.value,
                newOrderData.ticket_number
              );
              setNewOrderData((prevState) => ({
                ...prevState,
                ticket_number: ticketNumber,
              }));
            }}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <div>
            <button
              type="button"
              className="btn new-order-button font-weight-bold"
              onClick={() => setFormStep((prevState) => --prevState)}
            >
              Atrás
            </button>
          </div>
        </div>
        <div className="col-6 text-right">
          <div>
            <button
              className="btn new-order-button font-weight-bold"
              disabled={disableOnSubmit}
              onClick={() => {
                setDisableOnSubmit(true);
                submitOrder();
              }}
            >
              Crear orden
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FourthStep;
