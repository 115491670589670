export const productConstants = {
  GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
  GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILURE: 'GET_PRODUCTS_FAILURE',

  GET_STATION_PRODUCTS_REQUEST: 'GET_STATION_PRODUCTS_REQUEST',
  GET_STATION_PRODUCTS_SUCCESS: 'GET_STATION_PRODUCTS_SUCCESS',
  GET_STATION_PRODUCTS_FAILURE: 'GET_STATION_PRODUCTS_FAILURE',

  GET_STATION_PRODUCTS_BY_STATION_REQUEST: 'GET_STATION_PRODUCTS_BY_STATION_REQUEST',
  GET_STATION_PRODUCTS_BY_STATION_SUCCESS: 'GET_STATION_PRODUCTS_BY_STATION_SUCCESS',
  GET_STATION_PRODUCTS_BY_STATION_FAILURE: 'GET_STATION_PRODUCTS_BY_STATION_FAILURE',

  EDIT_STATION_PRODUCT_REQUEST: 'EDIT_STATION_PRODUCT_REQUEST',
  EDIT_STATION_PRODUCT_SUCCESS: 'EDIT_STATION_PRODUCT_SUCCESS',
  EDIT_STATION_PRODUCT_FAILURE: 'EDIT_STATION_PRODUCT_FAILURE',

  CREATE_STATION_PRODUCT_REQUEST: 'CREATE_STATION_PRODUCT_REQUEST',
  CREATE_STATION_PRODUCT_SUCCESS: 'CREATE_STATION_PRODUCT_SUCCESS',
  CREATE_STATION_PRODUCT_FAILURE: 'CREATE_STATION_PRODUCT_FAILURE',

  REMOVE_STATION_PRODUCT_REQUEST: 'REMOVE_STATION_PRODUCT_REQUEST',
  REMOVE_STATION_PRODUCT_SUCCESS: 'REMOVE_STATION_PRODUCT_SUCCESS',
  REMOVE_STATION_PRODUCT_FAILURE: 'REMOVE_STATION_PRODUCT_FAILURE',

  CREATE_BOOKING_STATION_PRODUCT_REQUEST: 'CREATE_BOOKING_STATION_PRODUCT_REQUEST',
  CREATE_BOOKING_STATION_PRODUCT_SUCCESS: 'CREATE_BOOKING_STATION_PRODUCT_SUCCESS',
  CREATE_BOOKING_STATION_PRODUCT_FAILURE: 'CREATE_BOOKING_STATION_PRODUCT_FAILURE',
};
