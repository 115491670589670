import * as React from 'react';
import './Schedule.scss';
import Moment from 'react-moment';
import { config } from '../../config';
import Block from './Block';
import { daysWeek } from '../../constants';

class Schedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      days: [],
    };

    this.addDays = this.addDays.bind(this);
    this.calculateDays = this.calculateDays.bind(this);
    this.getBlocksInDay = this.getBlocksInDay.bind(this);
  }

  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  getBlocksInDay() {
    const SCHEDULE_START_TIME = this.props.configuration.station.opened_at.split(
      ':',
    )[0];
    const SCHEDULE_END_TIME = this.props.configuration.station.closed_at.split(
      ':',
    )[0];
    const date1 = new Date();
    date1.setHours(SCHEDULE_START_TIME, 0, 0, 0);
    const date2 = new Date();
    date2.setHours(SCHEDULE_END_TIME, 0, 0, 0);
    const diff = (date2.getTime() - date1.getTime()) / (1000 * 60);
    return Math.floor(diff / this.props.configuration.attention_time);
  }

  calculateDays() {
    const days = [];
    const now = new Date();
    const attention_time = this.props.configuration.attention_time;
    const SCHEDULE_START_TIME = this.props.configuration.station.opened_at.split(
      ':',
    )[0];
    for (let i = 0; i <= config.TOTAL_DAYS; i++) {
      const date = this.addDays(now, i);
      const item = {
        date,
        weekday: date.getDay(),
        times: [],
      };
      const initialDate = new Date(date);
      for (let j = 0; j < this.getBlocksInDay(); j++) {
        initialDate.setHours(SCHEDULE_START_TIME, 0, 0, 0);
        const initial = new Date(initialDate.setMinutes(
          initialDate.getMinutes() + (attention_time * j),
        ));
        const end = initialDate.setMinutes(
          initialDate.getMinutes() + attention_time,
        );
        const data = {
          start: initial,
          end,
          weekday: initialDate.getDay() === 0 ? 6 : initialDate.getDay() - 1,
        };
        item.times.push(data);
      }
      days.push(item);
    }

    this.setState({ days });
  }

  componentDidMount() {
    this.calculateDays();
    this.getBlocksInDay();
  }

  render() {
    const { days } = this.state;
    const timeLenght = this.getBlocksInDay();
    const { blocks } = this.props;
    return (
      <div className="mt-3 table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="border-none" />
              { days.map((day, index) => (
                <th
                  className="text-center"
                  key={ index }
                >
                  <div
                    className="text-muted"
                  >
                    { daysWeek[day.weekday].substring(
                      0, 3,
                    ) }
                  </div>
                  <Moment
                    format="DD"
                    className={ (new Date().getDate()
                            === day.date.getDate()) ? 'current-date' : '' }
                  >
                    { day.date }
                  </Moment>
                </th>
              )) }
            </tr>
          </thead>
          <tbody>
            { Array.from(Array(timeLenght), (e, i) => (
              <tr key={ i }>
                <td className="border-none">
                  { days.length > 0 && days[0].times && days[0].times.length
                > 0
                && (
                <span className="time-label">
                  <Moment format="HH:mm">
                    { days[0].times[i].start }
                  </Moment>
                </span>
                )}

                </td>
                { days.map((day, index) => (
                  { blocks }
                  && (
                  <Block
                    key={ index }
                    startDate={ day.times[i].start }
                    endDate={ day.times[i].end }
                    weekDay={ day.times[i].weekday }
                    exceptions={ blocks }
                    configuration={ this.props.configuration }
                    createBlock={ this.props.createBlock }
                    removeBlock={ this.props.removeBlock }
                  />
                  )
                )) }
              </tr>
            )) }
          </tbody>
        </table>
      </div>
    );
  }
}

export default Schedule;
