export * from './station';
export * from './auth';
export * from './booking';
export * from './blocks';
export * from './vehicles';
export * from './products';
export * from './tickets';
export * from './wizard';

export const constants = {
  RESET_ERROR_STATE: 'RESET_ERROR_STATE',
};

export const errorType = {
  SUCCESS: 'success',
  ERROR: 'danger',
  INFO: 'info',
  DEFAULT: 'default',
  WARNING: 'warning',
  CUSTOM: 'custom',
};

export const daysWeek = [
  'Domingo',
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
];
