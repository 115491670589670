import React from 'react'; 
import redArrow from './assets/red-arrow.png';
import yellowArrow from './assets/yellow-arrow.png';
import './styles.scss';

const ScheduleButton = ({ arrowColor, sectionId }) => {
    const arrows = {
        'redArrow': redArrow,
        'yellowArrow': yellowArrow
    }
  return (
    <div className="container overlap-box mb-5">
      <div className="row">
        <div className="col-12">
          <div className="text-center">
            <a href={sectionId}>
                <img className="arrow-img" src={arrows[arrowColor]} alt="Next section" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleButton;
