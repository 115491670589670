export const customSelect = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#de1d22' : 'white',
    fontSize: '0.8em',
    cursor: state.isSelected ? 'default' : 'pointer',

  }),
  control: () => ({
    border: '1px solid #ddd',
    display: 'flex',
    fontSize: '0.8em',
    borderRadius: '50rem !important',
  }),

  menu: () => ({
    position: 'absolute',
    zIndex: 5000,
    width: '100%',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6',
  }),

  menuList: () => ({
    maxHeight: '150px',
    overflowX: 'auto',
    borderRadius: '5px',
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

export const customThinSelect = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#de1d22' : 'white',
    fontSize: '0.6em',
    cursor: state.isSelected ? 'default' : 'pointer',

  }),
  control: () => ({
    border: '1px solid #ddd',
    display: 'flex',
    fontSize: '0.6em',
    borderRadius: '50rem !important',
    height: '26px',
  }),

  menu: () => ({
    position: 'absolute',
    zIndex: 5000,
    width: '80%',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6',
  }),

  menuList: () => ({
    maxHeight: '150px',
    overflowX: 'auto',
    borderRadius: '5px',
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};
