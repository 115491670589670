import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from './Header';
import { logout } from '../../actions';

const mapStateToProps = (state) => ({
  auth: state.auth,
  wizard: state.wizard,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  logout: () => {
    dispatch(logout());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
