import oilCar from './assets/oil-car.png';
import breakLiquid from './assets/break-liquid.png'; 
import directionLiquid from './assets/direction-liquid.png'; 
import coolant from './assets/coolant.png'; 
import wiperWasher from './assets/wiper-washer.png'; 
import lights from './assets/lights.png'; 
import wiperBlade from './assets/wiper-blade.png';


export const featuresList = [[
    { image: oilCar, title: 'Nivel de aceite', key: 1},
    { image: breakLiquid, title: 'Nivel del líquido de frenos', key: 2},
    { image: directionLiquid, title: 'Nivel del líquido de dirección', key: 3},
    { image: coolant, title: 'Nivel del líquido refrigerante', key: 4},

  ],
  [
    { image: wiperWasher, title: 'Nivel de agua limpia parabrisas', key: 5},
    { image: lights, title: 'Estado de luces', key: 6},
    { image: wiperBlade, title: 'Estado de plumillas', key: 7},
  ]
]

export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
