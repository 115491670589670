import React from "react";
import "./Card.scss";
import moment from "moment";

const Card = ({ title, message, files, newsDate }) => {
  const formattedDate = moment(newsDate, "YYYY-MM-DD").format("dddd, LL");
  return (
    <>
      <div className="card-box">
        <div className="row">
          <div className="col-12">
            <h4 className="red-text">{title}</h4>
          </div>
        </div>
        <div className="row">
          <div className={`card-text text-justify col-12`} dangerouslySetInnerHTML={{__html: message}}></div>
          {files?.map((file) =>
            file.is_image ? (
              <div className="col-12 text-center mt-2" key={file.id}>
                <img src={file.file} className="news-img" alt="news-img" />
              </div>
            ) : (
              <div className="mr-2" key={file.id}>
                <a
                  className="btn new-order-button font-weight-bold"
                  href={file.file}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Descargar archivo adjunto
                </a>
              </div>
            )
          )}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="text-right font-weight-bold date-box">
              {formattedDate}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
