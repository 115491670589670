import React from "react";
import { Modal, Spacing, Text, Button, Label } from "react-elemental";

export default function DetailsClientModal(props) {
  const { name, email, phone, setVisibility } = props;

  return (
    <Modal
      size="gamma"
      onHide={() => {
        setVisibility(false);
      }}
    >
      <Spacing size="large" top right bottom left padding>
        <Spacing bottom>
          <Text size="epsilon" color="gray50">
            Datos de cliente
          </Text>
        </Spacing>
        <Spacing bottom>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <Spacing bottom>
                <Label sublabel="Nombre" className="mr-2" />
                <Text>{name}</Text>
              </Spacing>
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <Spacing bottom>
                <Label sublabel="E-mail" className="mr-2" />
                <Text>{email}</Text>
              </Spacing>
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <Spacing bottom>
                <Label sublabel="Teléfono" className="mr-2" />
                <Text>+{phone}</Text>
              </Spacing>
            </div>
          </div>
        </Spacing>
        <Text right>
          <Button
            text="Cerrar"
            className="rounded-pill"
            onClick={() => setVisibility(false)}
          />
        </Text>
      </Spacing>
    </Modal>
  );
}
