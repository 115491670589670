import React, { useEffect } from 'react';
import './Home.scss';
import Wizard from '../Wizard/WizardContainer';

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="mb-3 mb-lg-5">
      <Wizard/>
    </div>
  );
}

export default Home;
