import { authService } from '../services/auth';
import { authConstants } from '../constants';
import { handleError } from '../services';

export function login(username, password) {
  function request() {
    return { type: authConstants.LOGIN_REQUEST };
  }

  function success(user) {
    return { type: authConstants.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    error = handleError(error);
    return { type: authConstants.LOGIN_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    authService.login(username, password).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response)),
    );
  };
}

export function logout() {
  return (dispatch) => {
    dispatch({ type: authConstants.LOGOUT_REQUEST });
  };
}


export function reset_password(username){
  function request() {
    return { type: authConstants.RESET_REQUEST };
  }

  function success(resp) {
    return { type: authConstants.RESET_SUCCESS, resp };
  }

  function failure(error) {
    // error = handleError(error);
    const msg = error.status === 422 ? 'No podemos restablecer la contraseña para el usuario' : handleError(error);
    return { type: authConstants.RESET_FAILURE, error: msg };
  }

  return (dispatch) => {
    dispatch(request());

    authService.reset_password(username).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error.response)),
    );
  };
}

export function change_password(token, pass, pass2){
  function request() {
    return { type: authConstants.CHANGE_REQUEST };
  }

  function success(resp) {
    return { type: authConstants.CHANGE_SUCCESS, resp };
  }

  function failure(error) {
    let msg;
    let redirect = false;
      if (error.status === 400)
        msg = error.data.new_password.join(', ');
      if (error.status === 404){
        redirect = true
        msg = 'Este enlace ya no es válido para establecer la contraseña';
      }
      if (![400,404].includes(error.status))
        msg = handleError(error);
    return { type: authConstants.CHANGE_FAILURE, error: msg, redirect: redirect };
  }

  return (dispatch) => {
    dispatch(request());

    authService.change_password(token, pass, pass2).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error.response)),
    );
  };
}
