import * as React from "react";
import "./Client.scss";
import { Spacing, TextField } from "react-elemental";
import Stats from "../../Stats";
import {validatePhone, validateEmail, validateRut} from '../../../Commons/Validators'

class Client extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      rut: "",
      promotional_code: "",
    };
    this.validate = this.validate.bind(this);
    this.validateEmail = validateEmail.bind(this);
    this.validatePhone = validatePhone.bind(this);
    this.validateRut = validateRut.bind(this);
    this.onHandlePromotionalCode = this.onHandlePromotionalCode.bind(this);
    this.handleData = this.handleData.bind(this);
  }

  validatePromotionalCode(promotionalCode) {
    if (
      !this.props.promotionalCode.promotionalCode.validate &&
      this.props.promotionalCode.hasError &&
      promotionalCode
    ) {
      return "No es un código promocional válido.";
    }

    return "";
  }

  onHandlePromotionalCode(value) {
    this.setState({ promotional_code: value });
    this.props.validatePromotionalCode(value);

    // console.log("props client", this.props.promotionalCode);
  }

  validate() {
    const { name, email, phone, rut, promotional_code } = this.state;
    if (name === "" || email === "" || phone === "") return false;

    return (
      validateEmail(email) === "" &&
      validatePhone(phone) === "" &&
      validateRut(rut) === "" &&
      this.validatePromotionalCode(promotional_code) === ""
    );
  }

  handleData() {
    if (this.validate()) {
      const data = {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        rut: this.state.rut,
        promotional_code: this.state.promotional_code,
      };
      this.props.onChangeClientData(data);
    }
  }

  render() {
    return (
      <div className="mt-3">
        <h3 className="custom-title black">Datos de contacto</h3>
        <div className="text-muted margin-top--5 mb-3 ">
          Ingresa tus datos personales para terminar la reserva de tu cambio de
          aceite
        </div>

        <div className="row mb-3 mb-md-5">
          <div className="col-md-4">
            <Spacing>
              <label className="text-muted custom-label">
                Nombre *
              </label>
              <TextField
                placeholder="Nombre"
                className="rounded-pill"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
                onBlur={() => this.handleData()}
                required
              />
            </Spacing>
          </div>
          <div className="col-md-2 mt-2 mt-md-0">
            <Spacing>
              <label className="text-muted custom-label">
                Rut
              </label>
              <TextField
                placeholder="Ej: 12345678-1"
                className="rounded-pill"
                onBlur={() => this.handleData()}
                value={this.state.rut}
                onChange={(e) => this.setState({ rut: e.target.value })}
                error={this.validateRut(this.state.rut)}
              />
            </Spacing>
          </div>

          <div className="col-md-3 mt-2 mt-md-0">
            <Spacing>
              <label className="text-muted custom-label">
                Correo electrónico *
              </label>
              <TextField
                type="email"
                placeholder="Correo electrónico"
                className="rounded-pill"
                onBlur={() => this.handleData()}
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                error={this.validateEmail(this.state.email)}
                required
              />
            </Spacing>
          </div>

          <div className="col-md-2 mt-2 mt-md-0">
            <Spacing>
              <label className="text-muted custom-label">
                Teléfono*
              </label>
              <TextField
                placeholder="Ej: 9xxxxxxxx"
                className="rounded-pill"
                onBlur={() => this.handleData()}
                value={this.state.phone}
                onChange={(e) => this.setState({ phone: e.target.value })}
                error={this.validatePhone(this.state.phone)}
                required
              />
            </Spacing>
          </div>

          <div className="col-md-3 mt-2">
            <Spacing>
              <label className="text-muted custom-label">
                C&#243;digo promocional
              </label>
              <TextField
                placeholder="C&#243;digo promocional"
                className="rounded-pill"
                value={this.state.promotional_code}
                onChange={(e) => this.onHandlePromotionalCode(e.target.value)}
                error={this.validatePromotionalCode(
                  this.state.promotional_code
                )}
                onBlur={() => this.handleData()}
              />
            </Spacing>
          </div>
        </div>

        <Stats step={4} {...this.props} disabled={!this.validate()} />
      </div>
    );
  }
}

export default Client;
