/* tslint:disable */
/* eslint-disable */
/**
 * Enex API
 * API to manage bookings for oil change with Enex
 *
 * The version of the OpenAPI document: v1
 * Contact: jliznaga@linets.cl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { News } from '../models';
// @ts-ignore
import { NewsList200Response } from '../models';
/**
 * NewsApi - axios parameter creator
 * @export
 */
export const NewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Viewset model crud of customer.
         * @param {string} [type] 
         * @param {string} [typeNot] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] Inform the page. Starting with 1. Default: 1
         * @param {number} [pageSize] Limit per page, Default: 8.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsList: async (type?: string, typeNot?: string, ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/news/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (typeNot !== undefined) {
                localVarQueryParameter['type__not'] = typeNot;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Noticia.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsRead', 'id', id)
            const localVarPath = `/news/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsApi - functional programming interface
 * @export
 */
export const NewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsApiAxiosParamCreator(configuration)
    return {
        /**
         * Viewset model crud of customer.
         * @param {string} [type] 
         * @param {string} [typeNot] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] Inform the page. Starting with 1. Default: 1
         * @param {number} [pageSize] Limit per page, Default: 8.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsList(type?: string, typeNot?: string, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsList(type, typeNot, ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Noticia.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<News>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsApi - factory interface
 * @export
 */
export const NewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsApiFp(configuration)
    return {
        /**
         * Viewset model crud of customer.
         * @param {string} [type] 
         * @param {string} [typeNot] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] Inform the page. Starting with 1. Default: 1
         * @param {number} [pageSize] Limit per page, Default: 8.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsList(type?: string, typeNot?: string, ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<NewsList200Response> {
            return localVarFp.newsList(type, typeNot, ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Noticia.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsRead(id: number, options?: any): AxiosPromise<News> {
            return localVarFp.newsRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for newsList operation in NewsApi.
 * @export
 * @interface NewsApiNewsListRequest
 */
export interface NewsApiNewsListRequest {
    /**
     * 
     * @type {string}
     * @memberof NewsApiNewsList
     */
    readonly type?: string

    /**
     * 
     * @type {string}
     * @memberof NewsApiNewsList
     */
    readonly typeNot?: string

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof NewsApiNewsList
     */
    readonly ordering?: string

    /**
     * Inform the page. Starting with 1. Default: 1
     * @type {number}
     * @memberof NewsApiNewsList
     */
    readonly page?: number

    /**
     * Limit per page, Default: 8.
     * @type {number}
     * @memberof NewsApiNewsList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for newsRead operation in NewsApi.
 * @export
 * @interface NewsApiNewsReadRequest
 */
export interface NewsApiNewsReadRequest {
    /**
     * A unique integer value identifying this Noticia.
     * @type {number}
     * @memberof NewsApiNewsRead
     */
    readonly id: number
}

/**
 * NewsApi - object-oriented interface
 * @export
 * @class NewsApi
 * @extends {BaseAPI}
 */
export class NewsApi extends BaseAPI {
    /**
     * Viewset model crud of customer.
     * @param {NewsApiNewsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsList(requestParameters: NewsApiNewsListRequest = {}, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsList(requestParameters.type, requestParameters.typeNot, requestParameters.ordering, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset model crud of customer.
     * @param {NewsApiNewsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsRead(requestParameters: NewsApiNewsReadRequest, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}
