import React from "react";
import {
  Button,
  Label,
  Spacing,
  TextField,
} from 'react-elemental';
import {validateEmail, validatePhone, validateRut} from "../Commons/Validators";
import {showNotification} from "../../actions";
import {errorType} from "../../constants";

class Ticket extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      rut: "",
      phone: "",
      email: "",
      code: "",
    };
    this.validate = this.validate.bind(this);
    this.validateRut = validateRut.bind(this);
    this.validatePhone = validatePhone.bind(this);
    this.validateEmail = validateEmail.bind(this);
    this.saveForm = this.saveForm.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.ticket.hasError) {
      showNotification(errorType.ERROR, nextProps.ticket.error);
      this.props.resetNotification();
    }

    if (nextProps.ticket.success) {
      this.props.history.push('/app/info-ticket');
      window.location.reload(true);
    }
  }


  cleanForm() {
    this.setState({
      name: "",
      rut: "",
      phone: "",
      email: "",
      code: "",
    })
  }

  validate() {
    const {name, email, phone, rut, code} = this.state;
    if (name === "" || email === "" || phone === "" || code === "") return false;

    return (
      validateEmail(email) === "" &&
      validatePhone(phone) === "" &&
      validateRut(rut) === ""
    );
  }

  saveForm() {
    if (this.validate()) {
      const data = {
        name: this.state.name,
        rut: this.state.rut,
        phone: this.state.phone,
        email: this.state.email,
        code: this.state.code,
      };
      // console.log('Enviar data a API para agregar el registro');
      this.props.createTicket(data);
    }
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-12 py-3">
            <h3 className="custom-title black">Registro de boleta</h3>
            <div className="text-muted margin-top--5 mb-3 ">
              Ingresa los datos de tu boleta para participar en promociones
            </div>
          </div>

          <div className="col-md-4 col-lg-3">
            <Spacing bottom>
              <Label sublabel="Nombre"/>
              <TextField placeholder={'Nombre'} className='rounded-pill'
                         value={this.state.name}
                         onChange={(e) => this.setState({name: e.target.value})}
                         required/>
            </Spacing>
          </div>
          <div className="col-md-4 col-lg-3">
            <Spacing bottom>
              <Label sublabel="Rut"/>
              <TextField placeholder={'Rut'} className='rounded-pill'
                         value={this.state.rut}
                         onChange={(e) => this.setState({rut: e.target.value})}
                         error={this.validateRut(this.state.rut)}
                         required/>
            </Spacing>
          </div>
          <div className="col-md-4 col-lg-3">
            <Spacing bottom>
              <Label sublabel="Teléfono"/>
              <TextField placeholder="Ej: 9xxxxxxxx" className='rounded-pill'
                         value={this.state.phone}
                         onChange={(e) => this.setState({phone: e.target.value})}
                         error={this.validatePhone(this.state.phone)}
                         required/>
            </Spacing>
          </div>
          <div className="col-md-4 col-lg-3">
            <Spacing bottom>
              <Label sublabel="Email"/>
              <TextField placeholder="Correo electrónico" className='rounded-pill' type="email"
                         value={this.state.email}
                         onChange={(e) => this.setState({email: e.target.value})}
                         error={this.validateEmail(this.state.email)}
                         required/>
            </Spacing>
          </div>
          <div className="col-md-4 col-lg-3">
            <Spacing bottom>
              <Label sublabel="Boleta"/>
              <TextField placeholder="Boleta" className='rounded-pill'
                         value={this.state.code}
                         onChange={(e) => this.setState({code: e.target.value})}
                         required/>
            </Spacing>
          </div>

          <div className="col-12 mt-3 text-center">
            <Button
              className='rounded-pill'
              text="Solicitar boleta"
              disabled={!this.validate()}
              onClick={this.saveForm}
            />
          </div>
        </div>
      </div>
    )
  }

}

export default Ticket;