import { store } from 'react-notifications-component';
import { constants } from '../constants';

export * from './auth';

export function showNotification(type, message) {
  store.addNotification({
    title: '',
    message,
    type,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'bounceIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 10000,
      onScreen: true,
    },
  });
}

export function resetNotification() {
  return (dispatch) => {
    dispatch({ type: constants.RESET_ERROR_STATE });
  };
}
