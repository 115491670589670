import { blockConstants } from '../constants';

const initialState = {
  list: undefined,
  isLoading: false,
  error: '',
  hasError: false,
};

export function block(state = initialState, action) {
  switch (action.type) {
    case blockConstants.GET_BLOCKS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
        hasError: false,
      };
    case blockConstants.GET_BLOCKS_SUCCESS: {
      return {
        ...state,
        list: action.blocks,
        isLoading: false,
        error: '',
        hasError: false,
      };
    }
    case blockConstants.GET_BLOCKS_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isLoading: false,
        list: undefined,
      };

    case blockConstants.CREATE_BLOCK_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
        hasError: false,
      };
    case blockConstants.CREATE_BLOCK_SUCCESS: {
      return {
        ...state,
        list: [...state.list, action.block],
        isLoading: false,
        error: '',
        hasError: false,
      };
    }
    case blockConstants.CREATE_BLOCK_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isLoading: false,
      };

    case blockConstants.REMOVE_BLOCK_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
        hasError: false,
      };
    case blockConstants.REMOVE_BLOCK_SUCCESS: {
      return {
        ...state,
        list: state.list.filter((item) => item.id !== action.blockId),
        isLoading: false,
        error: '',
        hasError: false,
      };
    }
    case blockConstants.REMOVE_BLOCK_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isLoading: false,
      };
    default:
      return state;
  }
}
