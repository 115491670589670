import { ticketService } from "../services/ticket";
import { ticketConstants } from "../constants";

export function create(data) {
  function request() {
    return { type: ticketConstants.CREATE_TICKET_REQUEST };
  }

  function success(ticket) {
    return { type: ticketConstants.CREATE_TICKET_SUCCESS, ticket };
  }

  function failure(error) {
    // error = handleError(error); // Uncomment if is needed
    switch (error.status) {
      case 422:
        error = "Código promocional inválido";
        break;
      case 408:
        error = "Error al validar captcha, inténtalo más tarde";
        break;
      case 406:
        error = "Tu captcha fue invalidado, inténtalo nuevamente";
        break;
      case 417:
        error = "No hay descuento para este código promocional";
        break;
      default:
        error =
          "Opps, Parece que otra persona ya registro la boleta, por favor inténtelo nuevamente";
    }
    return { type: ticketConstants.CREATE_TICKET_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    ticketService.create(data).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response))
    );
  };
}
