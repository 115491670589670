import { productsService } from '../services/products';
import { productConstants } from '../constants';
import { handleError } from '../services';

export function getProducts() {
  function request() {
    return { type: productConstants.GET_PRODUCTS_REQUEST };
  }

  function success(products) {
    return { type: productConstants.GET_PRODUCTS_SUCCESS, products };
  }

  function failure(error) {
    error = handleError(error);
    return { type: productConstants.GET_PRODUCTS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    productsService.list().then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response)),
    );
  };
}

export function createStationProduct(data) {
  function request() {
    return { type: productConstants.CREATE_STATION_PRODUCT_REQUEST };
  }

  function success(data) {
    return { type: productConstants.CREATE_STATION_PRODUCT_SUCCESS, data };
  }

  function failure(error) {
    error = handleError(error);
    return { type: productConstants.CREATE_STATION_PRODUCT_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    productsService.createStationProduct(data).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response)),
    );
  };
}

export function getStationProducts() {
  function request() {
    return { type: productConstants.GET_STATION_PRODUCTS_REQUEST };
  }

  function success(stationProducts) {
    return {
      type: productConstants.GET_STATION_PRODUCTS_SUCCESS,
      stationProducts,
    };
  }

  function failure(error) {
    error = handleError(error);
    return { type: productConstants.GET_STATION_PRODUCTS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    productsService.listStationProducts().then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response)),
    );
  };
}

export function getStationProductsByStation(stationId) {
  function request() {
    return { type: productConstants.GET_STATION_PRODUCTS_BY_STATION_REQUEST };
  }

  function success(stationProducts) {
    return {
      type: productConstants.GET_STATION_PRODUCTS_BY_STATION_SUCCESS,
      stationProducts,
    };
  }

  function failure(error) {
    error = handleError(error);
    return {
      type: productConstants.GET_STATION_PRODUCTS_BY_STATION_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request());

    productsService.listStationProductsByStation(stationId).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response)),
    );
  };
}

export function editStationProducts(id, data) {
  function request() {
    return { type: productConstants.EDIT_STATION_PRODUCT_REQUEST };
  }

  function success(data) {
    return { type: productConstants.EDIT_STATION_PRODUCT_SUCCESS, data };
  }

  function failure(error) {
    error = handleError(error);
    return { type: productConstants.EDIT_STATION_PRODUCT_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    productsService.changeStationProductPrice(id, data).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response)),
    );
  };
}

export function removeStationProducts(id) {
  function request() {
    return { type: productConstants.REMOVE_STATION_PRODUCT_REQUEST };
  }

  function success(id) {
    return { type: productConstants.REMOVE_STATION_PRODUCT_SUCCESS, id };
  }

  function failure(error) {
    error = handleError(error);
    return { type: productConstants.REMOVE_STATION_PRODUCT_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    productsService.deleteStationProduct(id).then(
      (response) => dispatch(success(id)),
      (error) => dispatch(failure(error.response)),
    );
  };
}

export function createBookingStationProduct(data) {
  function request() {
    return { type: productConstants.CREATE_BOOKING_STATION_PRODUCT_REQUEST };
  }

  function success(data) {
    return {
      type: productConstants.CREATE_BOOKING_STATION_PRODUCT_SUCCESS,
      data,
    };
  }

  function failure(error) {
    error = handleError(error);
    return {
      type: productConstants.CREATE_BOOKING_STATION_PRODUCT_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request());

    productsService.createBookingStationProductPrice(data).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response)),
    );
  };
}
