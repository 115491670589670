import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Configuration from './Configuration';
import {
  editConfig,
  getConfig,
  updateFullConfig,
} from '../../actions/stations';
import { resetNotification } from '../../actions';

const mapStateToProps = (state) => ({
  station: state.station,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getConfig: (stationId) => {
    dispatch(getConfig(stationId));
  },
  editConfig: (configId, data) => {
    dispatch(editConfig(configId, data));
  },
  updateFullConfig: (data) => {
    dispatch(updateFullConfig(data));
  },
  resetNotification: () => {
    dispatch(resetNotification());
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Configuration),
);
