import * as React from 'react';
import './Login.scss';
import {
  Alert,
  Button,
  colors,
  TextField,
} from 'react-elemental';
import Modal from 'react-bootstrap/Modal';
import { showNotification } from '../../actions';
import { errorType } from '../../constants';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.recover_msg = 'Se enviará un correo con un enlace para que pueda restablecer su contraseña.'
    this.state = {
      user_recover: '',
      username: '',
      password: '',
      showModal: false,
      showRecoverMsg: false,
      isLoading: false,
    };

    this.handleLogin = this.handleLogin.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.recoverPassword = this.recoverPassword.bind(this);
  }

  handleClose = (e) => {
    this.setState({
      showModal: false,
      user_recover: '',
      showRecoverMsg: false,
    });
  };

  handleShow = (e) => {
    this.setState({showModal: true});
  }

  recoverPassword = () => {
    this.setState({isLoading:true})
    this.props.reset_password(this.state.user_recover)
  }

  handleLogin = (e) => {
    e.preventDefault();
    if(this.state.username !== '' && this.state.password !== '')
      this.props.login(this.state.username, this.state.password);
  };

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    // debugger
    if(nextProps.auth.hasError) {
      showNotification(errorType.ERROR, nextProps.auth.error);
      this.props.resetNotification();
    }
    if(nextProps.auth.isLogged) {
      if(!nextProps.auth.currentUser.station) {
        showNotification(errorType.WARNING,
          'No tienes ninguna sucursal asignada, contacta a tu administrador general');
        this.props.resetNotification();
      }
      this.props.history.push('/app/config');
    }
    if(this.state.isLoading && nextProps.auth.resetPassword) {
      this.setState({
        showRecoverMsg: true,
        user_recover: '',
        isLoading: false,
      })
        // showNotification(errorType.SUCCESS, this.recover_msg)
        this.props.resetNotification();
    }

    if (this.state.isLoading != nextProps.auth.isLoading) {
      this.setState({isLoading: nextProps.auth.isLoading})
    }
  }

  componentDidMount() {
    if(this.props.auth.isLogged) {
      if(!this.props.auth.currentUser.station) {
        showNotification(errorType.WARNING,
          'No tienes ninguna sucursal asignada, contacta a tu administrador general');
        this.props.resetNotification();
      }
      this.props.history.push('/app/config');
    }

  }

  render() {
    return (
      <div className="row pt-5">
        <div className="col-md-4 offset-md-4">
          <div className="row">
            <div className="col-12 mb-3 text-center">
              <h3>Iniciar sesión</h3>
            </div>
            <form className="w-100" onSubmit={this.handleLogin}>
              <div className="col-12">
                <label className="text-muted custom-label">
                  Usuario
                </label>
                <TextField
                    placeholder="Usuario"
                    className="rounded-pill"
                    onChange={(e) => this.setState({username: e.target.value})} required/>
              </div>
              <div className="col-12 mt-3">
                <label className="text-muted custom-label">
                  Contraseña
                </label>
                <TextField
                    type="password"
                    placeholder="Contraseña"
                    className="rounded-pill"
                    onChange={(e) => this.setState({password: e.target.value})} required/>
              </div>
              <div className="col-12 mt-3 text-center">
                <Button bold={ 700 }
                    text="Aceptar" className='rounded-pill w-100'
                    disabled={ this.state.username === '' || this.state.password === '' }
                    type="submit"/>
              </div>
            </form>
            <div className="w-100">
              <div className="col-md-12 mt-5 text-center">
                <Button
                    type="button"
                    className="rounded-pill w-100"
                    color={colors.gray55}
                    onClick={this.handleShow}
                    text="Recuperar contraseña"/>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Recuperar contraseña</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-12">
              <label className="text-muted custom-label">
                Para ayudarlo a recuperar su contraseña, por favor ingrese su usuario
              </label>
              <TextField
                  placeholder="Usuario"
                  className="rounded-pill"
                  value={this.state.user_recover}
                  onChange={(e) => this.setState({user_recover: e.target.value})} required/>
            </div>
            {this.state.showRecoverMsg && (
            <div className="col-12 my-3">
              <Alert
                  type="success"
                  title="Recuperando contraseña."
                  message={this.recover_msg}
              />
            </div>
            )}

          </Modal.Body>
          <Modal.Footer>
            <Button
                text="Cerrar"
                color={colors.gray55}
                onClick={this.handleClose}>
              Cancelar
            </Button>
            <Button
                type="button"
                text="Recuperar constraseña"
                disabled={this.state.user_recover == '' || this.state.user_recover.trim().length <= 2 || this.state.isLoading}
                onClick={this.recoverPassword}/>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Login;