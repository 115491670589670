/* tslint:disable */
/* eslint-disable */
/**
 * Enex API
 * API to manage bookings for oil change with Enex
 *
 * The version of the OpenAPI document: v1
 * Contact: jliznaga@linets.cl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { TokenObtainPair } from '../models';
// @ts-ignore
import { TokenRefresh } from '../models';
/**
 * TokenApi - axios parameter creator
 * @export
 */
export const TokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
         * @param {TokenObtainPair} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenCreate: async (data: TokenObtainPair, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('tokenCreate', 'data', data)
            const localVarPath = `/token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {TokenRefresh} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenRefreshCreate: async (data: TokenRefresh, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('tokenRefreshCreate', 'data', data)
            const localVarPath = `/token/refresh/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokenApi - functional programming interface
 * @export
 */
export const TokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TokenApiAxiosParamCreator(configuration)
    return {
        /**
         * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
         * @param {TokenObtainPair} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenCreate(data: TokenObtainPair, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenObtainPair>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {TokenRefresh} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenRefreshCreate(data: TokenRefresh, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenRefresh>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenRefreshCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TokenApi - factory interface
 * @export
 */
export const TokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TokenApiFp(configuration)
    return {
        /**
         * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
         * @param {TokenObtainPair} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenCreate(data: TokenObtainPair, options?: any): AxiosPromise<TokenObtainPair> {
            return localVarFp.tokenCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {TokenRefresh} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenRefreshCreate(data: TokenRefresh, options?: any): AxiosPromise<TokenRefresh> {
            return localVarFp.tokenRefreshCreate(data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for tokenCreate operation in TokenApi.
 * @export
 * @interface TokenApiTokenCreateRequest
 */
export interface TokenApiTokenCreateRequest {
    /**
     * 
     * @type {TokenObtainPair}
     * @memberof TokenApiTokenCreate
     */
    readonly data: TokenObtainPair
}

/**
 * Request parameters for tokenRefreshCreate operation in TokenApi.
 * @export
 * @interface TokenApiTokenRefreshCreateRequest
 */
export interface TokenApiTokenRefreshCreateRequest {
    /**
     * 
     * @type {TokenRefresh}
     * @memberof TokenApiTokenRefreshCreate
     */
    readonly data: TokenRefresh
}

/**
 * TokenApi - object-oriented interface
 * @export
 * @class TokenApi
 * @extends {BaseAPI}
 */
export class TokenApi extends BaseAPI {
    /**
     * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
     * @param {TokenApiTokenCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public tokenCreate(requestParameters: TokenApiTokenCreateRequest, options?: AxiosRequestConfig) {
        return TokenApiFp(this.configuration).tokenCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     * @param {TokenApiTokenRefreshCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public tokenRefreshCreate(requestParameters: TokenApiTokenRefreshCreateRequest, options?: AxiosRequestConfig) {
        return TokenApiFp(this.configuration).tokenRefreshCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
