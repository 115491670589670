export const blockConstants = {
  GET_BLOCKS_REQUEST: 'GET_BLOCKS_REQUEST',
  GET_BLOCKS_SUCCESS: 'GET_BLOCKS_SUCCESS',
  GET_BLOCKS_FAILURE: 'GET_BLOCKS_FAILURE',

  CREATE_BLOCK_REQUEST: 'CREATE_BLOCK_REQUEST',
  CREATE_BLOCK_SUCCESS: 'CREATE_BLOCK_SUCCESS',
  CREATE_BLOCK_FAILURE: 'CREATE_BLOCK_FAILURE',

  REMOVE_BLOCK_REQUEST: 'REMOVE_BLOCK_REQUEST',
  REMOVE_BLOCK_SUCCESS: 'REMOVE_BLOCK_SUCCESS',
  REMOVE_BLOCK_FAILURE: 'REMOVE_BLOCK_FAILURE',
};
