import React, { useState, useEffect } from "react";

const GenericDelete = ({
  deleteItem,
  item,
  setShowDelete,
  reloadTable,
  title,
}) => {
  const [responseCode, setResponseCode] = useState(null);

  useEffect(() => {
    if (responseCode === 204 || responseCode === 200) {
      reloadTable();
      setShowDelete(false);
    }
  }, [reloadTable, setShowDelete, responseCode]);
  return (
    <>
      <div className="row">
        <div className="col-12">
          <h5 className="red-text">Deshabilitar {title}</h5>
        </div>
      </div>
      <div className="row">
        <div className="col-12"></div>
        <p>¿Estás seguro de que quieres deshabilitar el {title}: <span className="font-weight-bold">{item}</span>?</p>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="text-right">
            <button
              className="btn btn-danger rounded-pill"
              onClick={() =>
                deleteItem().then((response) =>
                  setResponseCode(response.status)
                )
              }
            >
              Deshabilitar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenericDelete;
