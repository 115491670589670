import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { resetNotification } from '../../actions';
import Booking from './Booking';
import { edit, list } from '../../actions/booking';
import { getStationProducts, createBookingStationProduct } from '../../actions/products';

const mapStateToProps = (state) => ({
  auth: state.auth,
  booking: state.booking,
  stationProducts: state.products.stationProducts,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetNotification: () => {
    dispatch(resetNotification());
  },

  list: (data={}) => {
    dispatch(list(data));
  },
  edit: (bookingId, data) => {
    dispatch(edit(bookingId, data));
  },
  getStationProducts: () => {
    dispatch(getStationProducts());
  },
  createBookingStationProduct: (data) => {
    dispatch(createBookingStationProduct(data));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Booking));
