import * as React from 'react';
import {
  Button,
  colors,
  TextField,
} from 'react-elemental';
import { showNotification } from '../../actions';
import { errorType } from '../../constants';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      token: '',
      password: '',
      password_confirm: '',
    };

    this.handleChangePassword = this.handleChangePassword.bind(this);
  }


  handleChangePassword = (e) => {
    e.preventDefault();
    if(this.state.password !== '' && this.state.password_confirm !== '')
      this.setState({loading: true}, ()=>
          this.props.change_password(this.state.token, this.state.password, this.state.password_confirm));
  };

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.auth.hasError) {
      showNotification(errorType.ERROR, nextProps.auth.error);
      this.props.resetNotification();
      if (nextProps.auth.redirect)
        setTimeout(()=>{ this.props.history.push('/')},3000)
    }

    if(this.state.isLoading && nextProps.auth.resetPassword) {
      this.setState({isLoading: false})
        // showNotification(errorType.SUCCESS, this.recover_msg)
      showNotification(errorType.SUCCESS,
          'Su contraseña fue actualizada con éxito');
      setTimeout(()=>{ this.props.history.push('/app/login')}, 3000)
        this.props.resetNotification();
    }

    if (this.state.isLoading != nextProps.auth.isLoading) {
      this.setState({isLoading: nextProps.auth.isLoading});
    }

    if(nextProps.auth.isLogged) {
      if(!nextProps.auth.currentUser.station) {
        showNotification(errorType.WARNING,
          'No tienes ninguna sucursal asignada, contacta a tu administrador general');
        this.props.resetNotification();
      }
    }

  }

  componentDidMount() {
    // debugger
    this.setState({token: this.props.match.params.token});
    console.log(this.props.match.params.token);
    if(this.props.auth.isLogged) {
      if(!this.props.auth.currentUser.station) {
        showNotification(errorType.WARNING,
          'No tienes ninguna sucursal asignada, contacta a tu administrador general');
        this.props.resetNotification();
      }
      this.props.history.push('/app/config');
    }

  }

  render() {
    return (
      <div className="row pt-5">
        <div className="col-md-4 offset-md-4">
          <div className="row">
            <div className="col-12 mb-3 text-center">
              <h3>Actualizar contraseña</h3>
            </div>
            <form className="w-100" onSubmit={this.handleChangePassword}>
              <div className="col-12 mt-3">
                <label className="text-muted custom-label">
                  Nueva Contraseña
                </label>
                <TextField
                    type="password"
                    placeholder="Contraseña"
                    className="rounded-pill"
                    onChange={(e) => this.setState({password: e.target.value})} required/>
              </div>
              <div className="col-12 mt-3">
                <label className="text-muted custom-label">
                  Confirmar Contraseña
                </label>
                <TextField
                    type="password"
                    placeholder="Confirmar Contraseña"
                    className="rounded-pill"
                    onChange={(e) => this.setState({password_confirm: e.target.value})} required/>
              </div>
              <div className="col-12 mt-3 text-center">
                <Button bold={ 700 }
                    text="Actualizar contraseña" className='rounded-pill w-100'
                    disabled={ this.state.password === '' || this.state.password_confirm === '' ||
                    this.state.password != this.state.password_confirm }
                    type="submit"/>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;