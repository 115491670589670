import * as React from 'react';
import './Info.scss';
import { colors, Text } from 'react-elemental';
import success from '../../commons/assets/reserva_exitosa_yellow.svg';

function Info(props) {
  return (
    <div className="mb-5">
      <div className="text-center mt-5">
        <img
          alt="Success"
          src={ success }
          className="mb-2"
          width="100px"
          height="auto"
        />
        <div
          className="col-md-5 col-sm-12 col-xs-12 text-danger ml-auto mr-auto mb-4"
        >
          <h4 className="custom-title black mt-3">
            ¡Tu reserva se ha realizado
            con éxito!
          </h4>
        </div>
        <Text
          size="iota"
          className="text-center col-md-6 col-sm-12 col-xs-12 mb-0 ml-auto mr-auto"
          color={ colors.gray55 }
          bold
        >
          Se ha enviado un correo con la información de la reserva.
          <br />
          Si aún no recibes el mensaje, por favor revisa tus correos no
          deseados.
          <br />
          Recuerda, te esperamos hasta 15 minutos después de la hora agendada.
          <br />
        </Text>
        <Text
          size="iota"
          className="text-center w-100 mb-5 mt-3"
          color={ colors.gray50 }
          bold
        >
          <strong>¡Nos vemos!</strong>
          <br/><br/>
          Cualquier duda nos pueden escribir al mail cambiaconhelix@enex.cl
        </Text>

        <span
          className="mt-4 btn-back"
          onClick={ () => {
            props.history.push('/app');
          } }
        >
          Agendar otro vehículo
        </span>
      </div>

    </div>
  );
}

export default Info;
