import { axios_instance } from './index';

export async function retrieve(patent) {
  return axios_instance.get(`vehicles/${patent}/`);
}

export async function get_history(patent) {
  return axios_instance.get(`vehicle-history/${patent}/`);
}

export const vehicleService = {
  retrieve,
  get_history,
};
