const validateEmail = (email) => {
  if (email === "") return "";
  const re = new RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
  );
  return re.test(email) ? "" : "No es un correo válido";
}

const validatePhone = (phone) => {
  if (phone === "") return "";
  const re = new RegExp("^(\\+?56)?(\\s?)(0?[29])(\\s?)[0-9]\\d{7}$");
  return re.test(phone) ? "" : "No es un teléfono válido";
}

const validateRut = (rut) => {
  if (rut === "") return "";
  const re = /^\d{7,8}-[\dkK]$/;
  return re.test(rut) ? "" : "No es un RUT válido";
}

const validateRut2 = (rut) => {
  if (rut === "") return "";
  const re = /^\d{7,8}-[\dkK]$|^\d{1,2}\.\d{3}\.\d{3}-[\dkK]$/;
  return re.test(rut) ? "" : "No es un RUT válido";
}

const validatePatent = (patent) => {
    if (patent === '') { return ''; }
    // const re = new RegExp('^([a-z-A-Z]{4}[0-9]{2})|([a-z-A-Z]{2}[0-9]{4})$');
    const re = new RegExp('^[A-Z]{2}[A-Z\\d]{2}[\\d]{2}$');
    return re.test(patent) ? '' : 'No es una patente válida';
  }

export {validateRut, validateRut2, validateEmail, validatePhone, validatePatent}