import React from "react";

const Trainings = () => {
  const url = "https://enex.gea.smartraining.cl/";
  return (
    <>
      <div className="training-content">
        <div>
          <div>
            Estimado, A continuación encontrarás un link para acceder al portal
            de capacitación web que cuenta con los siguientes módulos:
          </div>
          <div className="mt-3">
            <ul>
              <li>Cambio de Aceite</li>
              <li>Revisión de nivel de aceite hidráulico</li>
              <li>Cambio de líquido refrigerante</li>
              <li>Revisión líquido de frenos</li>
              <li>Revisión y cambio de plumillas</li>
              <li>Revisión y cambio de Batería</li>
              <li>Cambio de Ampolleta</li>
            </ul>

            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="btn new-order-button font-weight-bold mt-3"
            >
              Ir a capacitaciones
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Trainings;
