import axios from 'axios';
import { config } from '../config';
import { vehiclesConstants } from '../constants';

const WHITELIST = [
  'token/',
  'regions/',
  'communes/',
  'stations/',
  'settings/',
  'vehicles/',
  'captcha/',
  'system_setting/',
];

const BLACKLIST = {
  'bookings/': 'post',
  'blocks/': 'get',
};

export const axios_instance = axios.create({
  baseURL: config.BASE_URL,
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

axios_instance.interceptors.request.use((requestConfig) => {
  const endpoint = `${requestConfig.url.split('/')[0]}/`;
  if (WHITELIST.includes(endpoint)
    || (endpoint in BLACKLIST && BLACKLIST[endpoint] === requestConfig.method)) {
    requestConfig.headers.common.Authorization = '';
    return requestConfig;
  }

  const token = localStorage.getItem('accessToken');
  if (!token || token === '') {
    return requestConfig;
  }
  axios_instance.defaults.headers.common.Authorization = token;
  requestConfig.headers.common.Authorization = token;

  return requestConfig;
});

export function handleError(errorResponse, action) {
  if (!errorResponse) return 'Opss, ha pasado algo, revise la comunicaciòn con el servidor';
  if (errorResponse.status === 400) return 'Los datos entrados no son correctos';
  if (errorResponse.status
    === 401) return 'Su usuario y contraseña son incorrectos';
  if (errorResponse.status
    === 403) return 'No tiene permisos para continuar esta operación';
  if (errorResponse.status === 404) {
    if (action
      === vehiclesConstants.GET_VEHICLE_FAILURE) return 'Ups! No encontramos tu patente, por favor ingresa los datos manualmente.';
    return 'Ups!, No hemos encontrado lo que has solicitado';
  }

  return 'Opss, ha pasado algo, revise la comunicaciòn con el servidor';
}
