import { vehiclesConstants, constants } from '../constants';

const initialState = {
  isSearching: false,
  isSuccess: false,
  error: '',
  hasError: false,
  data: null,
};

export function vehicle(state = initialState, action) {
  switch (action.type) {
    case vehiclesConstants.GET_VEHICLE_REQUEST:
      return {
        ...state,
        isSearching: true,
        error: '',
        hasError: false,
        isSuccess: false,
      };
    case vehiclesConstants.GET_VEHICLE_SUCCESS: {
      return {
        ...state,
        data: action.vehicle,
        isSearching: false,
        error: '',
        hasError: false,
        isSuccess: true,
      };
    }
    case vehiclesConstants.GET_VEHICLE_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isSearching: false,
        config: null,
      };
    case constants.RESET_ERROR_STATE:
      return {
        ...state,
        error: '',
        hasError: false,
        success: false,
        isSuccess: false,
      };
    default:
      return state;
  }
}
