import React from "react";
import styles from "./Wizard.scss";
import transitions from "./Wizard.scss";
import StepWizard from "react-step-wizard";
import Station from "./Steps/Station/Station";
import classNames from "classnames/bind";
import SelectDate from "./Steps/Date/Date";
import Car from "./Steps/Car/Car";
import Client from "./Steps/Client/Client";
import Confirm from "./Steps/Confirm/Confirm";
import "moment/locale/es";
import { showNotification } from "../../actions";
import { errorType } from "../../constants";
import step1 from "../../commons/assets/step1.svg";
import step2 from "../../commons/assets/step2.svg";
import step3 from "../../commons/assets/step3.svg";
import step4 from "../../commons/assets/step4.svg";
import step5 from "../../commons/assets/step5.svg";

const cx = classNames.bind(styles);
const tr = classNames.bind(transitions);

const Nav = (props) => {
  const dots = [];
  for (let i = 1; i <= props.totalSteps; i += 1) {
    const isActive = props.currentStep >= i;
    dots.push(
      <span
        key={`step-${i}`}
        className={`${cx("dot")} ${isActive ? cx("active") : ""}  ${
          i === 5 ? cx("w-20") : "w-25"
        }`}
      >
        <div className="text-muted text-step mb-2 d-none d-lg-block">
          {props.order[i - 1].text}
        </div>
        <div className="text-muted icon-step mb-2 d-block d-lg-none">
          {props.order[i - 1].icon}
        </div>
        <div className="dot-line">
          <div className="big-line" />
          <div className="big-dot" />
        </div>
      </span>
    );
  }

  return <div className={`${cx("nav")} text-right`}>{dots}</div>;
};

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      station: null,
      date: null,
      patent: "",
      brand: "",
      model: "",
      year: "",
      fuel_type: "",
      name: "",
      email: "",
      phone: "",
      rut: "",
      is_ghost: false,
      promotional_code: "",
      transitions: {
        enterRight: `${tr("animated")} ${tr("enterRight")}`,
        enterLeft: `${tr("animated")} ${tr("enterLeft")}`,
        exitRight: `${tr("animated")} ${tr("exitRight")}`,
        exitLeft: `${tr("animated")} ${tr("exitLeft")}`,
        intro: `${tr("animated")} ${tr("intro")}`,
      },
      instance: null,
    };

    this.bookingData = this.bookingData.bind(this);
    this.setInstance = this.setInstance.bind(this);
    this.onStepChange = this.onStepChange.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.createBooking = this.createBooking.bind(this);
    this.onChangeStation = this.onChangeStation.bind(this);
    this.onChangeCarData = this.onChangeCarData.bind(this);
    this.onChangeClientData = this.onChangeClientData.bind(this);
  }

  setInstance(SW) {
    this.setState({ instance: SW });
  }

  onStepChange = (stats) => {
    this.props.updateStepAction(stats.activeStep);
  };

  onChangeStation(selectedStation) {
    this.setState({ station: selectedStation });
    this.props.getConfig(selectedStation.id);
    this.props.getBlocks(selectedStation.id);
    this.props.getStationProductsByStation(selectedStation.id);
  }

  onChangeDate(date) {
    this.setState({ date: date });
  }

  onChangeCarData(data) {
    this.setState({
      patent: data.patent,
      brand: data.brand,
      model: data.model,
      year: data.year,
      fuel_type: data.fuel_type
    });
  }

  onChangeClientData(data) {
    this.setState({
      name: data.name,
      email: data.email,
      phone: data.phone,
      rut: data.rut,
      promotional_code: data.promotional_code
    });
  }

  bookingData() {
    return {
      station: this.state.station,
      customer: {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        rut: this.state.rut,
      },
      vehicle: {
        patent: this.state.patent,
        brand: this.state.brand,
        model: this.state.model,
        year: this.state.year,
        fuel_type: this.state.fuel_type,
      },
      date: this.state.date,
      prom_code: this.state.promotional_code,
      is_ghost: false,
      // captcha_code: this.state.captcha_code,
    };
  }

  createBooking() {
    let data = this.bookingData();
    if (
      data.station !== null &&
      data.date !== null &&
      data.vehicle.patent !== "" &&
      data.vehicle.brand !== "" &&
      data.vehicle.model !== "" &&
      data.vehicle.year !== "" &&
      data.vehicle.fuel_type !== "" &&
      data.customer.name !== "" &&
      data.customer.email
    ) {
      data.station = data.station.id;
      data.date = new Date(
        Date.UTC(
          data.date.getFullYear(),
          data.date.getMonth(),
          data.date.getDate(),
          data.date.getHours(),
          data.date.getMinutes(),
          0
        )
      );
      this.props.createBooking(data);
    }
  }

  componentDidMount() {
    this.props.getRegions();
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.booking.hasError) {
      showNotification(errorType.ERROR, nextProps.booking.error);
      this.props.resetNotification();
    }

    /*
    if (nextProps.captcha.hasError) {
      showNotification(errorType.ERROR, nextProps.captcha.error);
      this.props.resetNotification();
    }
*/

    if (nextProps.booking.success) {
      this.props.history.push("/app/info");
      window.location.reload(true);
    }
  }

  render() {
    const exceptions = this.props.block.list;
    const config = this.props.station.config;
    return (
      <div>
        <StepWizard
          onStepChange={this.onStepChange}
          isHashEnabled
          transitions={this.state.transitions} // comment out for default transitions
          nav={
            <Nav
              order={[
                {
                  text: "Estación de Servicio",
                  icon: (
                    <img
                      src={step1}
                      alt={"Estación de Servicio"}
                      className={"float-right margin-top--10 margin-bottom-10"}
                      width={24}
                      height={"auto"}
                    />
                  ),
                },
                {
                  text: "Fecha",
                  icon: (
                    <img src={step2} alt={"Fecha"} width={24} height={"auto"} />
                  ),
                },
                {
                  text: "Vehículo",
                  icon: (
                    <img
                      src={step3}
                      alt={"Vehículo"}
                      width={38}
                      height={"auto"}
                    />
                  ),
                },
                {
                  text: "Cliente",
                  icon: (
                    <img
                      src={step4}
                      alt={"Cliente"}
                      width={24}
                      height={"auto"}
                    />
                  ),
                },
                {
                  text: "Confirmar",
                  icon: (
                    <img
                      src={step5}
                      alt={"Confirmar"}
                      width={24}
                      height={"auto"}
                    />
                  ),
                },
              ]}
            />
          }
          instance={this.setInstance}
        >
          <Station
            hashKey={"station"}
            getCommunes={this.props.getCommunes}
            getStations={this.props.getStations}
            regions={this.props.station.regions}
            communes={this.props.station.communes}
            stations={this.props.station.stations}
            products={this.props.products}
            onChangeStation={this.onChangeStation}
          />

          <SelectDate
            hashKey={"date"}
            exceptions={exceptions}
            config={config}
            onChangeDate={this.onChangeDate}
          />

          <Car
            hashKey={"car"}
            onChangeCarData={this.onChangeCarData}
            vehicle={this.props.vehicle}
            retrieveVehicle={this.props.retrieveVehicle}
            resetNotification={this.props.resetNotification}
          />

          <Client
            hashKey={"client"}
            onChangeClientData={this.onChangeClientData}
            validatePromotionalCode={this.props.validatePromotionalCode}
            promotionalCode={this.props.promotionalCode}
          />

          <Confirm
            hashKey={"confirm"}
            isLoading={this.props.booking.isLoading}
            data={this.bookingData()}
            createBooking={this.createBooking}
            products={this.props.products}
            // validateCaptcha={ this.props.validateCaptchaAction }
            // captcha={this.props.captcha}
          />
        </StepWizard>
      </div>
    );
  }
}

export default Wizard;
