import React from 'react';

const SectionRow = ({ children }) => {
    return(
      <div className="row pt-md-5 text-center justify-content-center">
        {children}
      </div>
    );
  }

export default SectionRow