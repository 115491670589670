import { axios_instance } from "./index";

const fileDownload = require("js-file-download");

export async function list() {
  return axios_instance.get("bookings/");
}

export async function edit(bookingId, data) {
  return axios_instance.patch(`bookings/${bookingId}/`, data);
}

export async function create(data) {
  axios_instance.defaults.timeout = 30000;
  return axios_instance.post("bookings/", data);
}

export async function get_booking(data) {
  return axios_instance.post("bookings/get_booking/", data);
}

export async function downloadReport(startDate, endDate) {
  return axios_instance
    .get(`export_bookings/?start_date=${startDate}&end_date=${endDate}`, {
      responseType: "blob",
    })
    .then((response) => {
      fileDownload(response.data, "report.xls");
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function validateCaptcha(token) {
  return axios_instance.get(`captcha/${token}/`);
}

export async function validatePromotionalCode(promotionalCode) {
  return axios_instance.get(`promo_code/${encodeURIComponent(promotionalCode)}/validate/`);
}

export const bookingService = {
  list,
  edit,
  create,
  downloadReport,
  validateCaptcha,
  validatePromotionalCode,
  get_booking,
};
