import React, { useState, useCallback, useMemo } from "react";
import {
  serviceFactory,
  PAGESIZE,
  getCurrentUser,
} from "../../../../utils/helpers";
import { LubricatorsApi } from "../../../../services/api";
import GenericList from "../Generics/List";
import LubricatorsForm from "./form";

const lubricatorsApi = serviceFactory(LubricatorsApi);

const Lubricators = () => {
  const currentUser = useMemo(() => getCurrentUser(), []);
  const initialLubricatorForm = {
    full_name: "",
    birthday: "",
    rut: "",
    email: "",
    phone: "",
  };

  const formTitles = {
    full_name: {
      title: "Nombre completo",
      required: true,
    },
    birthday: {
      title: "Fecha de nacimiento",
      required: true,
    },
    rut: {
      title: "Rut",
      required: true,
    },
    email: {
      title: "Correo",
      required: true,
    },
    phone: {
      title: "Teléfono",
      required: true,
    },
  };

  const [lubricators, setLubricators] = useState([]);
  const [lubricatorForm, setLubricatorForm] = useState(initialLubricatorForm);

  const getLubricators = useCallback((page) => {
    return lubricatorsApi
      .lubricatorsList({
        station: currentUser.station.id,
        page: page,
        pageSize: PAGESIZE,
        ordering: "-is_active, full_name",
      })
      .then((response) => {
        setLubricators(response.data);
      });
  }, [currentUser]);

  const createLubricator = () => {
    const data = {
      station: currentUser.station.id,
      ...lubricatorForm,
      rut: lubricatorForm.rut.replaceAll(".", ""),
    };
    return lubricatorsApi.lubricatorsCreate({ data });
  };

  const updateLubricator = (id) => {
    const data = {
      station: currentUser.station.id,
      ...lubricatorForm,
      rut: lubricatorForm.rut.replaceAll(".", ""),
      is_active: true,
    };
    return lubricatorsApi.lubricatorsPartialUpdate({ id, data });
  };

  const inactiveLubricator = (id) => {
    const data = {
      is_active: false,
    };
    return lubricatorsApi.lubricatorsPartialUpdate({ id, data });
  };

  const tableHeaders = [
    { name: "full_name", title: "Nombre completo" },
    { name: "rut", title: "Rut" },
    { name: "email", title: "Correo" },
    // { name: "birthday", title: "Fecha de nacimiento" },
    { name: "phone", title: "Teléfono" },
  ];

  return (
    <>
      <GenericList
        data={{
          tableHeaders,
          objList: lubricators,
          deleteItem: inactiveLubricator,
          title: "Lubricadores",
          createItem: createLubricator,
          updateItem: updateLubricator,
          FormElement: LubricatorsForm,
          setFormData: setLubricatorForm,
          modalTitle: "lubricador",
          formData: lubricatorForm,
          formTitles: formTitles,
          deleteItemColumnReference: "full_name",
          initialData: initialLubricatorForm,
        }}
        reloadTable={getLubricators}
      />
    </>
  );
};

export default Lubricators;
