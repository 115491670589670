import { axios_instance } from './index';

export async function list() {
  return axios_instance.get('products/');
}

export async function listStationProducts() {
  return axios_instance.get('station_products/');
}

export async function listStationProductsByStation(stationId) {
  return axios_instance.get(`station_products/station/${stationId}/`);
}

export async function createStationProduct(data) {
  return axios_instance.post('station_products/', data);
}

export async function deleteStationProduct(id) {
  return axios_instance.delete(`station_products/${id}/`);
}

export async function changeStationProductPrice(id, data) {
  return axios_instance.patch(`station_products/${id}/`, data);
}

export async function createBookingStationProductPrice(data) {
  return axios_instance.post('/booking_station_products/', data);
}

export const productsService = {
  list,
  listStationProducts,
  createStationProduct,
  changeStationProductPrice,
  deleteStationProduct,
  createBookingStationProductPrice,
  listStationProductsByStation,
};
