import * as React from "react";
import { Checkbox } from "react-elemental";

class TermsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  closeModal() {
    this.setState({ visible: false });
  }
  openModal() {
    this.setState({ visible: true });
  }

  render() {
    return (
      <div>
        <Checkbox
          label="Acepto los "
          checked={this.props.terms}
          onChange={this.props.onUpdate}
        ></Checkbox>
        <span
          className="pl-1"
          style={{
            cursor: "pointer",
            color: "blue",
            textDecoration: "underline",
          }}
          onClick={this.openModal}
        >
          términos y condiciones
        </span>
        {this.state.visible && (
          <div>
            <div className="modal-backdrop fade show"></div>
            <div
              className="modal fade show d-block"
              id="termsModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Terminos y condiciones
                    </h5>
                    <button
                      type="button"
                      className="close"
                      onClick={this.closeModal}
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-12">
                        <p>
                          De conformidad con lo dispuesto en la Ley 19.628, de
                          Protección de Datos de Carácter Personal y su
                          normativa de desarrollo, se informa al USUARIO del
                          SERVICIO que sus datos de carácter personal serán
                          incorporados en los ficheros titularidad de ENEX.
                        </p>
                        <p>
                          Los USUARIOS, al utilizar el servicio descrito en
                          estas Condiciones Generales, aceptan expresamente y de
                          forma libre e inequívoca que sus datos personales,
                          proporcionados por su dispositivo de acceso y/o por la
                          plataforma de forma directa por el USUARIO sean
                          tratados por parte ENEX con las siguientes
                          finalidades:
                        </p>
                        <ol className="pl-3">
                          <li>
                            Remisión de comunicaciones comerciales por e-mail,
                            fax, SMS, MMS, redes sociales o cualquier otro medio
                            electrónico o físico, presente o futuro, que
                            posibilite realizar comunicaciones comerciales.
                            Dichas comunicaciones comerciales pueden estar
                            relacionadas con productos o servicios ofrecidos por
                            ENEX o cualquiera de sus filiales, como
                            comunicaciones comerciales con ofertas y servicios
                            ofrecidos por parte de los colaboradores o partners
                            con los que ENEX alcance algún acuerdo de promoción
                            comercial entre sus clientes de las ofertas y
                            servicios ofrecidos por dichos colaboradores o
                            partners.
                          </li>
                          <li>
                            Realizar estudios estadísticos por parte de ENEX y
                            sus filiales
                          </li>
                          <li>
                            Remitir el boletín de noticias de ENEX (si es que
                            hubiere)
                          </li>
                          <li>Gestión del servicio de Agendamiento Online</li>
                        </ol>
                        <p>
                          ENEX garantiza a los interesados el ejercicio de los
                          derechos de acceso, rectificación, cancelación, y
                          oposición, en los términos previstos en la legislación
                          vigente. Por ello, de conformidad con lo dispuesto en
                          la Ley 19.628 de Protección de Datos de Carácter
                          Personal podrá ejercer sus derechos remitiendo una
                          solicitud expresa, junto a una copia de su Documento
                          de Identidad, a través de cualquiera de los siguientes
                          medios:
                        </p>
                        <p>
                          E-Mail:{" "}
                          <a
                            href="mailto:cambiaconhelix@enex.cl"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            cambiaconhelix@enex.cl
                          </a>
                        </p>
                        <p>
                          ENEX le informa de que ha adoptado todas las medidas
                          técnicas y de organización necesarias para garantizar
                          la seguridad e integridad de los datos de carácter
                          personal que trate, así como para evitar su pérdida,
                          alteración y/o acceso por parte de terceros no
                          autorizados, habida cuenta el estado de la tecnología.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={this.closeModal}
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default TermsModal;
