export const environment = {
  DEBUG: false,
};

export const config = {
  BASE_URL: environment.DEBUG
    ? 'http://127.0.0.1:8000/api/v1'
    : process.env.REACT_APP_BASE_API_URL,
  SERVER_URL: environment.DEBUG
    ? 'http://127.0.0.1:8000'
    : process.env.REACT_APP_SERVER_URL,
  TOTAL_DAYS: 12,
  SCHEDULE_START_TIME: 7,
  SCHEDULE_END_TIME: 21,
};
