import { constants, stationConstants } from '../constants';

const initialState = {
  config: null,
  regions: [],
  communes: [],
  stations: [],
  isLoading: false,
  error: '',
  hasError: false,
  successChange: false,
};

export function station(state = initialState, action) {
  switch (action.type) {
    case stationConstants.GET_CONFIG_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
        hasError: false,
      };
    case stationConstants.GET_CONFIG_SUCCESS: {
      return {
        ...state,
        config: action.configurations.length > 0 ? action.configurations[0] : null,
        isLoading: false,
        error: '',
        hasError: false,
      };
    }
    case stationConstants.GET_CONFIG_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isLoading: false,
        config: null,
      };

    case stationConstants.EDIT_CONFIG_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
        hasError: false,
      };
    case stationConstants.EDIT_CONFIG_SUCCESS: {
      let temp = action.configuration;
      if (temp) {
        temp = {
          ...state.config,
          attention_time: temp.attention_time,
          stock: temp.stock,
          wait_time: temp.wait_time,
          description: temp.description,
          discount_percent: temp.discount_percent,
        };
      }
      return {
        ...state,
        config: temp,
        isLoading: false,
        error: '',
        hasError: false,
      };
    }
    case stationConstants.EDIT_CONFIG_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isLoading: false,
        config: null,
      };
    case stationConstants.EDIT_FULL_CONFIG_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
        hasError: false,
        successChange: false,
      };
    case stationConstants.EDIT_FULL_CONFIG_SUCCESS: {
      const temp = {
        ...state.config,
        stock: action.data.stock,
      };

      return {
        ...state,
        config: temp,
        isLoading: false,
        error: '',
        hasError: false,
        successChange: true,
      };
    }
    case stationConstants.EDIT_FULL_CONFIG_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isLoading: false,
      };
    case stationConstants.GET_REGIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
        hasError: false,
      };
    case stationConstants.GET_REGIONS_SUCCESS: {
      return {
        ...state,
        regions: action.regions,
        isLoading: false,
        error: '',
        hasError: false,
      };
    }
    case stationConstants.GET_REGIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isLoading: false,
        config: null,
      };

    case stationConstants.GET_COMMUNES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
        hasError: false,
      };
    case stationConstants.GET_COMMUNES_SUCCESS: {
      return {
        ...state,
        communes: action.communes,
        isLoading: false,
        error: '',
        hasError: false,
      };
    }
    case stationConstants.GET_COMMUNES_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isLoading: false,
        config: null,
      };

    case stationConstants.GET_STATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
        hasError: false,
      };
    case stationConstants.GET_STATIONS_SUCCESS: {
      return {
        ...state,
        stations: action.stations,
        isLoading: false,
        error: '',
        hasError: false,
      };
    }
    case stationConstants.GET_STATIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isLoading: false,
        config: null,
      };
    case constants.RESET_ERROR_STATE:
      return {
        ...state,
        error: '',
        hasError: false,
        success: false,
        successChange: false,
      };
    default:
      return state;
  }
}
