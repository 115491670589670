import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { login, resetNotification, reset_password } from '../../actions';
import Login from './Login';

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  login: (username, password) => {
    dispatch(login(username, password));
  },
  resetNotification: () => {
    dispatch(resetNotification());
  },
  reset_password: (username) => {
    dispatch(reset_password(username));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
