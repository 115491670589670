import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Image from 'react-bootstrap/Image';
import bidon from '../../../commons/assets/bidon_default.png';
import { config } from '../../../config';
import './style.scss';

const ProductCarousel = ({ products }) => {
  const responsive = {
    desktop_large: {
      breakpoint: { max: 3000, min: 1200 },
      items: 8,
      slidesToSlide: 1, // optional, default to 1.
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 8,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const numberWithCommas = (x) => {
    const parts = x.toString().split(',');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  if (!products || products.length === 0) return null;

  return (
    <div className="carousel-wrapper">
      <h3 className="custom-title black mt-3" id="label-products">Productos</h3>
      <div className="mb-3 text-muted">
        Precio referencial bidones 4 Litros (Precio no incluye filtros)
      </div>

      <Carousel
        swipeable={ false }
        draggable={ false }
        showDots={ false }
        responsive={ responsive }
        ssr // means to render carousel on server-side.
        infinite
        autoPlay
        autoPlaySpeed={ 5000 }
        keyBoardControl
        // customTransition="all .5"
        transitionDuration={ 500 }
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {
          products.sort((a, b) => (a.price - b.price)).map((p) => (
            <div
              key={ `p_${p.id}` }
              className="product-wrapper"
            >
              <div className="p-image">
                {
                  p.product.image && p.product.image !== null
                    ? (
                      <Image
                        className="img-fluid img-bidon mx-auto d-block"
                        alt="bidon-default"
                        src={ `${config.SERVER_URL}${p.product.image}` }
                        width="auto"
                        height="180px"
                      />
                    )
                    : (
                      <Image
                        className="img-fluid img-bidon mx-auto d-block"
                        alt="bidon-default"
                        src={ bidon }
                        width="auto"
                        height="180px"
                      />
                    )
                }

              </div>
              <div
                className="text-muted p-name ml-auto mr-auto"
              >
                { p.product.name }
              </div>
              <div
                className="text-muted p-name ml-auto mr-auto"
              >
                { p.product.code }
              </div>
              <h3
                className="custom-title black p-price mt-2 text-center"
              >
                { `$${numberWithCommas(
                  p.price,
                )}` }
              </h3>
            </div>
          ))
        }
      </Carousel>

    </div>

  );
};

export default ProductCarousel;
