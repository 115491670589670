import React from "react";

const Header = ({ user }) => {
  return (
    <div className="row">
      <div className="col-12">
        <h3 className="red-text">¡Hola {user ? user.first_name ? user.first_name : user.username : ''}!</h3>
      </div>
    </div>
  );
};

export default Header;
