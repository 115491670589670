import React from 'react';
import './StationCards.scss';

function StationCards(props) {
  const sucursal = props.selectedStation;
  const showDistance = props.showDistance;
  return (
    <>
      {props.stations.map((location, i) => (
        <div key={location.id}
          className={"card card-station mt-2" + ( parseInt(location.id) === parseInt(sucursal?.id) ? ' selected':'')}
          onClick={ () => props.selectStation({ value: location.id }) }>
          <div className="card-body p-2">
            <p className="card-text">{location.address}
              {/*<br/><small> {i== 0 ? 'Mas cercana': ''}({location.distance.toFixed(2)} Km)</small>*/}
              <br/>{ (showDistance) &&  <small> {i === 0 ? 'Mas cercana': ''}({location.distance.toFixed(2)} Km)</small> }
            </p>
          </div>
        </div>
      ))}
    </>
  );
}

export default StationCards;
