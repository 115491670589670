/* tslint:disable */
/* eslint-disable */
/**
 * Enex API
 * API to manage bookings for oil change with Enex
 *
 * The version of the OpenAPI document: v1
 * Contact: jliznaga@linets.cl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Lubricator } from '../models';
// @ts-ignore
import { LubricatorsList200Response } from '../models';
/**
 * LubricatorsApi - axios parameter creator
 * @export
 */
export const LubricatorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Viewset model crud of customer.
         * @param {Lubricator} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lubricatorsCreate: async (data: Lubricator, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('lubricatorsCreate', 'data', data)
            const localVarPath = `/lubricators/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this lubricador.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lubricatorsDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lubricatorsDelete', 'id', id)
            const localVarPath = `/lubricators/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset model crud of customer.
         * @param {number} [station] 
         * @param {string} [fullName] 
         * @param {string} [fullNameContains] 
         * @param {string} [rut] 
         * @param {string} [rutContains] 
         * @param {string} [isActive] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] Inform the page. Starting with 1. Default: 1
         * @param {number} [pageSize] Limit per page, Default: 8.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lubricatorsList: async (station?: number, fullName?: string, fullNameContains?: string, rut?: string, rutContains?: string, isActive?: string, ordering?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lubricators/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (station !== undefined) {
                localVarQueryParameter['station'] = station;
            }

            if (fullName !== undefined) {
                localVarQueryParameter['full_name'] = fullName;
            }

            if (fullNameContains !== undefined) {
                localVarQueryParameter['full_name__contains'] = fullNameContains;
            }

            if (rut !== undefined) {
                localVarQueryParameter['rut'] = rut;
            }

            if (rutContains !== undefined) {
                localVarQueryParameter['rut__contains'] = rutContains;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this lubricador.
         * @param {Lubricator} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lubricatorsPartialUpdate: async (id: number, data: Lubricator, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lubricatorsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('lubricatorsPartialUpdate', 'data', data)
            const localVarPath = `/lubricators/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this lubricador.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lubricatorsRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lubricatorsRead', 'id', id)
            const localVarPath = `/lubricators/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this lubricador.
         * @param {Lubricator} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lubricatorsUpdate: async (id: number, data: Lubricator, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lubricatorsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('lubricatorsUpdate', 'data', data)
            const localVarPath = `/lubricators/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LubricatorsApi - functional programming interface
 * @export
 */
export const LubricatorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LubricatorsApiAxiosParamCreator(configuration)
    return {
        /**
         * Viewset model crud of customer.
         * @param {Lubricator} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lubricatorsCreate(data: Lubricator, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lubricator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lubricatorsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this lubricador.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lubricatorsDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lubricatorsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Viewset model crud of customer.
         * @param {number} [station] 
         * @param {string} [fullName] 
         * @param {string} [fullNameContains] 
         * @param {string} [rut] 
         * @param {string} [rutContains] 
         * @param {string} [isActive] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] Inform the page. Starting with 1. Default: 1
         * @param {number} [pageSize] Limit per page, Default: 8.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lubricatorsList(station?: number, fullName?: string, fullNameContains?: string, rut?: string, rutContains?: string, isActive?: string, ordering?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LubricatorsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lubricatorsList(station, fullName, fullNameContains, rut, rutContains, isActive, ordering, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this lubricador.
         * @param {Lubricator} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lubricatorsPartialUpdate(id: number, data: Lubricator, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lubricator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lubricatorsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this lubricador.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lubricatorsRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lubricator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lubricatorsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this lubricador.
         * @param {Lubricator} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lubricatorsUpdate(id: number, data: Lubricator, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lubricator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lubricatorsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LubricatorsApi - factory interface
 * @export
 */
export const LubricatorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LubricatorsApiFp(configuration)
    return {
        /**
         * Viewset model crud of customer.
         * @param {Lubricator} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lubricatorsCreate(data: Lubricator, options?: any): AxiosPromise<Lubricator> {
            return localVarFp.lubricatorsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this lubricador.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lubricatorsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.lubricatorsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset model crud of customer.
         * @param {number} [station] 
         * @param {string} [fullName] 
         * @param {string} [fullNameContains] 
         * @param {string} [rut] 
         * @param {string} [rutContains] 
         * @param {string} [isActive] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] Inform the page. Starting with 1. Default: 1
         * @param {number} [pageSize] Limit per page, Default: 8.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lubricatorsList(station?: number, fullName?: string, fullNameContains?: string, rut?: string, rutContains?: string, isActive?: string, ordering?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<LubricatorsList200Response> {
            return localVarFp.lubricatorsList(station, fullName, fullNameContains, rut, rutContains, isActive, ordering, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this lubricador.
         * @param {Lubricator} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lubricatorsPartialUpdate(id: number, data: Lubricator, options?: any): AxiosPromise<Lubricator> {
            return localVarFp.lubricatorsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this lubricador.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lubricatorsRead(id: number, options?: any): AxiosPromise<Lubricator> {
            return localVarFp.lubricatorsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this lubricador.
         * @param {Lubricator} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lubricatorsUpdate(id: number, data: Lubricator, options?: any): AxiosPromise<Lubricator> {
            return localVarFp.lubricatorsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for lubricatorsCreate operation in LubricatorsApi.
 * @export
 * @interface LubricatorsApiLubricatorsCreateRequest
 */
export interface LubricatorsApiLubricatorsCreateRequest {
    /**
     * 
     * @type {Lubricator}
     * @memberof LubricatorsApiLubricatorsCreate
     */
    readonly data: Lubricator
}

/**
 * Request parameters for lubricatorsDelete operation in LubricatorsApi.
 * @export
 * @interface LubricatorsApiLubricatorsDeleteRequest
 */
export interface LubricatorsApiLubricatorsDeleteRequest {
    /**
     * A unique integer value identifying this lubricador.
     * @type {number}
     * @memberof LubricatorsApiLubricatorsDelete
     */
    readonly id: number
}

/**
 * Request parameters for lubricatorsList operation in LubricatorsApi.
 * @export
 * @interface LubricatorsApiLubricatorsListRequest
 */
export interface LubricatorsApiLubricatorsListRequest {
    /**
     * 
     * @type {number}
     * @memberof LubricatorsApiLubricatorsList
     */
    readonly station?: number

    /**
     * 
     * @type {string}
     * @memberof LubricatorsApiLubricatorsList
     */
    readonly fullName?: string

    /**
     * 
     * @type {string}
     * @memberof LubricatorsApiLubricatorsList
     */
    readonly fullNameContains?: string

    /**
     * 
     * @type {string}
     * @memberof LubricatorsApiLubricatorsList
     */
    readonly rut?: string

    /**
     * 
     * @type {string}
     * @memberof LubricatorsApiLubricatorsList
     */
    readonly rutContains?: string

    /**
     * 
     * @type {string}
     * @memberof LubricatorsApiLubricatorsList
     */
    readonly isActive?: string

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof LubricatorsApiLubricatorsList
     */
    readonly ordering?: string

    /**
     * Inform the page. Starting with 1. Default: 1
     * @type {number}
     * @memberof LubricatorsApiLubricatorsList
     */
    readonly page?: number

    /**
     * Limit per page, Default: 8.
     * @type {number}
     * @memberof LubricatorsApiLubricatorsList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for lubricatorsPartialUpdate operation in LubricatorsApi.
 * @export
 * @interface LubricatorsApiLubricatorsPartialUpdateRequest
 */
export interface LubricatorsApiLubricatorsPartialUpdateRequest {
    /**
     * A unique integer value identifying this lubricador.
     * @type {number}
     * @memberof LubricatorsApiLubricatorsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Lubricator}
     * @memberof LubricatorsApiLubricatorsPartialUpdate
     */
    readonly data: Lubricator
}

/**
 * Request parameters for lubricatorsRead operation in LubricatorsApi.
 * @export
 * @interface LubricatorsApiLubricatorsReadRequest
 */
export interface LubricatorsApiLubricatorsReadRequest {
    /**
     * A unique integer value identifying this lubricador.
     * @type {number}
     * @memberof LubricatorsApiLubricatorsRead
     */
    readonly id: number
}

/**
 * Request parameters for lubricatorsUpdate operation in LubricatorsApi.
 * @export
 * @interface LubricatorsApiLubricatorsUpdateRequest
 */
export interface LubricatorsApiLubricatorsUpdateRequest {
    /**
     * A unique integer value identifying this lubricador.
     * @type {number}
     * @memberof LubricatorsApiLubricatorsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Lubricator}
     * @memberof LubricatorsApiLubricatorsUpdate
     */
    readonly data: Lubricator
}

/**
 * LubricatorsApi - object-oriented interface
 * @export
 * @class LubricatorsApi
 * @extends {BaseAPI}
 */
export class LubricatorsApi extends BaseAPI {
    /**
     * Viewset model crud of customer.
     * @param {LubricatorsApiLubricatorsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LubricatorsApi
     */
    public lubricatorsCreate(requestParameters: LubricatorsApiLubricatorsCreateRequest, options?: AxiosRequestConfig) {
        return LubricatorsApiFp(this.configuration).lubricatorsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset model crud of customer.
     * @param {LubricatorsApiLubricatorsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LubricatorsApi
     */
    public lubricatorsDelete(requestParameters: LubricatorsApiLubricatorsDeleteRequest, options?: AxiosRequestConfig) {
        return LubricatorsApiFp(this.configuration).lubricatorsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset model crud of customer.
     * @param {LubricatorsApiLubricatorsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LubricatorsApi
     */
    public lubricatorsList(requestParameters: LubricatorsApiLubricatorsListRequest = {}, options?: AxiosRequestConfig) {
        return LubricatorsApiFp(this.configuration).lubricatorsList(requestParameters.station, requestParameters.fullName, requestParameters.fullNameContains, requestParameters.rut, requestParameters.rutContains, requestParameters.isActive, requestParameters.ordering, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset model crud of customer.
     * @param {LubricatorsApiLubricatorsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LubricatorsApi
     */
    public lubricatorsPartialUpdate(requestParameters: LubricatorsApiLubricatorsPartialUpdateRequest, options?: AxiosRequestConfig) {
        return LubricatorsApiFp(this.configuration).lubricatorsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset model crud of customer.
     * @param {LubricatorsApiLubricatorsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LubricatorsApi
     */
    public lubricatorsRead(requestParameters: LubricatorsApiLubricatorsReadRequest, options?: AxiosRequestConfig) {
        return LubricatorsApiFp(this.configuration).lubricatorsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset model crud of customer.
     * @param {LubricatorsApiLubricatorsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LubricatorsApi
     */
    public lubricatorsUpdate(requestParameters: LubricatorsApiLubricatorsUpdateRequest, options?: AxiosRequestConfig) {
        return LubricatorsApiFp(this.configuration).lubricatorsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
