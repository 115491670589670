import { LubricatorsApi, Configuration } from "../services/api";
import { config } from "../config";
import { axios_instance } from "../services/index";

const pageRegex = /page=\d/;

const onlyLettersPattern = /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/;
const onlyNumbersPattern = /^[+]?[\d]*$/;
export const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const serviceFactory = (apiService) => {
  const configuration = new Configuration({ basePath: config.BASE_URL });
  return new apiService(configuration, config.BASE_URL, axios_instance);
};

const lubricatorsApi = serviceFactory(LubricatorsApi);

export const getCurrentUser = () => {
  const rootData = JSON.parse(localStorage.getItem("persist:root"));
  const auth = rootData ? JSON.parse(rootData.auth) : undefined;
  return auth ? auth.currentUser : auth;
};

export const calculatePages = (total_items, page_size) => {
  total_items = parseInt(total_items);
  page_size = parseInt(page_size);
  const preCalculatedTotalPages = total_items % page_size;
  const totalPagesDivision = total_items / page_size;
  return preCalculatedTotalPages === 0
    ? totalPagesDivision
    : Math.floor(totalPagesDivision) + 1;
};

export const getNextPage = (next) => {
  const preNextPage = next?.match(pageRegex);
  return preNextPage ? parseInt(preNextPage[0].split("=")[1]) : null;
};

export const getPreviousPage = (previous) => {
  const prePreviousPage = previous ? previous.match(pageRegex) : false;
  return prePreviousPage === false
    ? null
    : prePreviousPage
    ? parseInt(prePreviousPage[0].split("=")[1])
    : 1;
};

export const formatRut = (inputRut) => {
  let formattedRut = inputRut.replace(/\./g, "").replace("-", "");

  if (formattedRut.match(/^(\d{2})(\d{3}){2}(\w{1})$/)) {
    formattedRut = formattedRut.replace(
      /^(\d{2})(\d{3})(\d{3})(\w{1})$/,
      "$1.$2.$3-$4"
    );
  } else if (formattedRut.match(/^(\d)(\d{3}){2}(\w{0,1})$/)) {
    formattedRut = formattedRut.replace(
      /^(\d)(\d{3})(\d{3})(\w{0,1})$/,
      "$1.$2.$3-$4"
    );
  } else if (formattedRut.match(/^(\d)(\d{3})(\d{0,2})$/)) {
    formattedRut = formattedRut.replace(/^(\d)(\d{3})(\d{0,2})$/, "$1.$2.$3");
  } else if (formattedRut.match(/^(\d)(\d{0,2})$/)) {
    formattedRut = formattedRut.replace(/^(\d)(\d{0,2})$/, "$1.$2");
  }
  formattedRut = formattedRut.toUpperCase();
  return formattedRut;
};

export const rutISValid = (_, value) => {
  const errorMessage = "Rut no válido";
  if (value && value.includes("-")) {
    let suma = 0;
    const arrRut = value.replaceAll(".", "").split("-");
    let rutSolo = Number(arrRut[0]);
    const verif = arrRut[1];
    let passed = true;
    for (let i = 2; passed; i++) {
      suma += Math.trunc(rutSolo % 10) * i;
      rutSolo = rutSolo / 10;
      i = i === 7 ? 1 : i;
      passed = rutSolo === 0 ? false : true;
    }
    const resto = Math.trunc(suma % 11);
    const dv = 11 - resto;
    if (
      isKdigitValid(verif, dv) ||
      isZerodigitValid(verif, dv) ||
      isSamedigitValid(verif, dv)
    )
      return Promise.resolve();
    return Promise.reject(new Error(errorMessage));
  }
  return Promise.reject(new Error(errorMessage));
};

export const formatLimitedRut = (prevValue, value) => {
  const valueLength = value.length;
  if (valueLength <= 12) {
    if (prevValue.length < valueLength) {
      return formatRut(value);
    }
    return value;
  }
  return prevValue;
};

const isKdigitValid = (digit, dv) => dv === 10 && digit.toUpperCase() === "K";
const isZerodigitValid = (verif, dv) => dv === 11 && verif === "0";
const isSamedigitValid = (verif, dv) => dv === Number(verif);

export const allowOnlyLetters = (value, prevValue) => {
  return genericRegexValidation(value, prevValue, onlyLettersPattern);
};

export const allowOnlyNumbers = (value, prevValue) => {
  return genericRegexValidation(value, prevValue, onlyNumbersPattern);
};

const genericRegexValidation = (value, prevValue, pattern) => {
  // debugger;
  if (prevValue && prevValue < value) {
    const validString = pattern.test(value);
    if (!validString) return prevValue ? prevValue : "";
  }
  if (!prevValue && value) {
    const validString = pattern.test(value);
    if (!validString) return prevValue ? prevValue : "";
  }
  return value;
};

export const PAGESIZE = 10;

export const NEWS_TYPE = {
  NEWS: "notification",
  INCENTIVES: "incentive",
  PROMOTIONS: "promotion",
};

export const USER_ROLES = {
  LUBRICATOR: "lubricator",
  MANAGER: "manager",
};

export const getAllLubricators = async (data) => {
  let lubricatorsList = [];
  let pageCount = 1;
  let nextPage = true;
  while (nextPage) {
    const response = await lubricatorsApi.lubricatorsList({
      ...data,
      page: pageCount,
    });
    lubricatorsList.push(...response.data.results);
    nextPage = response.data.next ? true : false;
    pageCount++;
  }
  return lubricatorsList;
};
