import React from "react";
import {
  emailPattern,
  allowOnlyLetters,
  allowOnlyNumbers,
  formatLimitedRut,
} from "../../../../../utils/helpers";
import {validateRut2, validateEmail, validatePhone} from "../../../../Commons/Validators"
import { get_customer_by_rut } from "../../../../../services/auth";


const FirstStep = ({
  setNewOrderData,
  setFormStep,
  newOrderData,
  lubricators,
}) => {
  const rutValue = newOrderData.booking.customer?.rut;
  const nameValue = newOrderData.booking.customer?.name;
  const emailValue = newOrderData.booking.customer?.email;
  const lubricatorValue = newOrderData.lubricator;
  const phoneValue = newOrderData.booking.customer?.phone;

  const disabled =
    rutValue === "" ||
    nameValue === "" ||
    emailValue === "" ||
    !emailPattern.test(emailValue) ||
    lubricatorValue === "" ||
    phoneValue === "";

  const onChangeRut = (e) => {
    e.persist();
    const formattedRut = formatLimitedRut(rutValue, e.target.value);
    setNewOrderData((prevState) => ({
      ...prevState,
      booking: {
        ...prevState.booking,
        customer: {
          ...prevState.booking.customer,
          rut: formattedRut,
        },
      },
    }));
  }

  const onBlurRut = (e) => {
    e.persist();
    get_customer_by_rut(e.target.value.replaceAll('.','')).then( resp => {
      if (resp.status === 200 && resp.data){
        setNewOrderData((prevState) => ({
          ...prevState,
          booking: {
            ...prevState.booking,
            customer: {
              ...prevState.booking.customer,
              name: resp.data.name,
              email: resp.data.email,
              phone: resp.data.phone,
            },
          },
        }));
      }
    });
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-6 col-md-4 form-group">
          <label className="d-block" htmlFor="rut">
            Rut <span className="symbol required"></span>
          </label>
          <input
            name="rut"
            placeholder="Rut"
            required
            value={rutValue}
            className={"form-control rounded-pill" + (validateRut2(rutValue)?' is-invalid': '')}
            onChange={onChangeRut}
            onBlur={onBlurRut}
          />
        <div className="invalid-feedback">No es un RUT válido</div>
        </div>
        <div className="col-sm-6 col-md-8 form-group">
          <label className="d-block" htmlFor="name">
            Nombre completo
          </label>
          <input
            name="name"
            placeholder="Nombre completo"
            value={nameValue}
            className="form-control rounded-pill"
            onChange={(e) => {
              e.persist();
              const fullName = allowOnlyLetters(e.target.value, nameValue);
              setNewOrderData((prevState) => ({
                ...prevState,
                booking: {
                  ...prevState.booking,
                  customer: {
                    ...prevState.booking.customer,
                    name: fullName,
                  },
                },
              }));
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-8 form-group">
          <label className="d-block" htmlFor="email">
            Correo
          </label>
          <input
            name="email"
            type="email"
            placeholder="Correo"
            value={emailValue}
            className={"form-control rounded-pill" + (validateEmail(emailValue)?' is-invalid': '')}
            onChange={(e) => {
              e.persist();
              setNewOrderData((prevState) => ({
                ...prevState,
                booking: {
                  ...prevState.booking,
                  customer: {
                    ...prevState.booking.customer,
                    email: e.target.value,
                  },
                },
              }));
            }}
          />
        <div className="invalid-feedback">No es un correo válido</div>
        </div>
        <div className="col-sm-6 col-md-4 form-group">
          <label className="d-block" htmlFor="phone">
            Teléfono
          </label>
          <input
            name="phone"
            placeholder="Teléfono"
            value={phoneValue}
            className={"form-control rounded-pill" + (validatePhone(phoneValue)?' is-invalid': '')}
            onChange={(e) => {
              e.persist();
              const phone = allowOnlyNumbers(e.target.value, phoneValue);
              setNewOrderData((prevState) => ({
                ...prevState,
                booking: {
                  ...prevState.booking,
                  customer: {
                    ...prevState.booking.customer,
                    phone: phone,
                  },
                },
              }));
            }}
          />
        <div className="invalid-feedback">No es un teléfono válido</div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 form-group form-group">
          <label className="d-block" htmlFor="lubricator">
            Asignar lubricador
          </label>

          <select
            name="lubricator"
            className="form-control rounded-pill"
            value={lubricatorValue}
            onChange={(e) => {
              e.persist();
              setNewOrderData((prevState) => ({
                ...prevState,
                lubricator: Number(e.target.value),
              }));
            }}
          >
            <option value="" disable="true" hidden>
              Seleccionar lubricador
            </option>
            {lubricators?.map((lubricator) => (
              <option key={lubricator.id} value={lubricator.id}>
                {lubricator.full_name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row next-button">
        <div className="col-12">
          <div className="text-right">
            <button
              type="button"
              disabled={disabled}
              className="btn new-order-button font-weight-bold"
              onClick={() => setFormStep(prevState => ++prevState)}
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstStep;
