import * as React from 'react';
import './Schedule.scss';

class Block extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      status: 'available',
      _class: '',
    };
    this.toggleBlock = this.toggleBlock.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
  }

  toggleBlock() {
    if (this.state.status === 'available' && this.state.id == null) {
      let {
        startDate, endDate, weekDay, configuration,
      } = this.props;
      const date = new Date(startDate);
      const edate = new Date(endDate);
      startDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(),
          date.getHours(), date.getMinutes(), 0),
      );
      endDate = new Date(
        Date.UTC(edate.getFullYear(), edate.getMonth(), edate.getDate(),
          edate.getHours(), edate.getMinutes(), 0),
      );
      const start_dt = startDate.toJSON()
      const start_str = start_dt.replaceAll('-','').replace('T','').split(':')[0]
      const objectId = parseInt(`${configuration.id}${start_str}`);
      const data = {
        start_date: startDate,
        end_date: endDate,
        week_day: weekDay,
        origin: 'config',
        settings: configuration.id,
        object_id: objectId,
      };
      this.props.createBlock(data);
    }

    if (this.state.status === 'disabled') {
      this.props.removeBlock(this.state.id);
    }
  }

  updateStatus(exceptions) {
    const { startDate, weekDay, configuration } = this.props;
    let open_time = null;
    let close_time = null;
    open_time = new Date(startDate);
    close_time = new Date(startDate);
    let open_hour = 0;
    let close_hour = 0;

    if (weekDay === 5) {
      open_hour = configuration.station.sat_opened_at.split(':')[0];
      close_hour = configuration.station.sat_closed_at.split(':')[0];
    } else if (weekDay === 6) {
      open_hour = configuration.station.sun_opened_at.split(':')[0];
      close_hour = configuration.station.sun_closed_at.split(':')[0];
    } else {
      open_hour = configuration.station.opened_at.split(':')[0];
      close_hour = configuration.station.closed_at.split(':')[0];
    }

    open_time.setHours(open_hour);
    close_time.setHours(close_hour);

    if (new Date(startDate).getTime() < new Date(open_time).getTime()
      || new Date(startDate).getTime() >= new Date(close_time).getTime()
    ) {
      this.setState({ _class: 'off-time' });
      this.setState({ status: 'inactive' });
      return;
    }

    const block = exceptions.find((item) => new Date(item.start_date).getTime()
      === new Date(startDate).getTime());

    if (block) {
      this.setState({ id: block.id });
      if (block.origin === 'booking') {
        this.setState({ _class: 'reserved' });
        this.setState({ status: 'reserved' });
      } else {
        this.setState({ _class: 'disabled' });
        this.setState({ status: 'disabled' });
      }
    } else {
      this.setState({ _class: '' });
      this.setState({ status: 'available' });
      this.setState({ id: null });
    }

    if (new Date(startDate).getTime() < new Date().getTime()) {
      this.setState({ _class: 'it-past' });
      this.setState({ status: 'inactive' });
    }
  }

  componentDidMount() {
    this.updateStatus(this.props.exceptions);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.exceptions && nextProps.exceptions.length
      !== this.props.exceptions.length) {
      this.updateStatus(nextProps.exceptions);
    }
  }

  render() {
    const { _class } = this.state;
    return (
      <td
        className={ `block text-center ${_class}` }
        onClick={ this.toggleBlock }
      />
    );
  }
}

export default Block;
