import * as React from 'react';
import {
  Button,
  Label,
  Modal,
  Spacing,
  Text,
  TextField,
  TextArea,
} from 'react-elemental';

class EditConfigurationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stock: props.configuration.stock,
      attention_time: props.configuration.attention_time,
      discount_percent: props.configuration.discount_percent,
      description: props.configuration.description,
    };

    this.isDisabled = this.isDisabled.bind(this);
    this.editConfiguration = this.editConfiguration.bind(this);
    this.onHandleChangeStock = this.onHandleChangeStock.bind(this);
    this.onHandleChangePercent = this.onHandleChangePercent.bind(this);
  }

  editConfiguration() {
    const data = {
      stock: this.state.stock,
      discount_percent: this.state.discount_percent,
      description: this.state.description,
      station_id: this.props.configuration.station.id,
    };
    this.props.editConfig(this.props.configuration.id, data);
    this.props.setVisibility(false);
  }

  onHandleChangeStock(value) {
    if (!Number.isNaN(value)) { this.setState({ stock: value }); }
  }

  onHandleChangePercent(value) {
    if (!Number.isNaN(value)) { this.setState({ discount_percent: value }); }
  }

  isDisabled() {
    return this.state.stock === '' || this.state.discount_percent === '';
  }

  render() {
    return (
      <Modal
        size="beta"
        onHide={ () => {
          this.props.setVisibility(false);
        } }
      >
        <Spacing
          size="large"
          top
          right
          bottom
          left
          padding
        >
          <Spacing bottom>
            <Text
              size="epsilon"
              color="gray50"
            >
              Editar configuración
            </Text>
          </Spacing>

          <Spacing bottom>
            <div className="row">
              <div className="col-md-4 col-sm-12 col-xs-12">
                <Spacing bottom>
                  <Label sublabel="Pozos" />
                  <TextField
                    placeholder="Pozos"
                    type="number"
                    min={ 1 }
                    value={ this.state.stock }
                    onChange={ (e) => {
                      this.onHandleChangeStock(e.target.value);
                    } }
                  />
                </Spacing>
              </div>

              <div className="col-md-4 col-sm-12 col-xs-12">
                <Spacing bottom>
                  <Label sublabel="Porciento de descuento" />
                  <TextField
                    placeholder="Porciento de descuento"
                    value={ this.state.discount_percent }
                    onChange={ (e) => {
                      this.onHandleChangePercent(e.target.value);
                    } }
                  />
                </Spacing>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <Spacing bottom>
                  <Label sublabel="Descripción" />
                  <TextArea
                    style={ { height: '100px', width: '100%' } }
                    value={ this.state.description }
                    onChange={ (e) => this.setState(
                      { description: e.target.value },
                    ) }
                  />
                </Spacing>
              </div>
            </div>
          </Spacing>

          <Text right>
            <Button
              text="Cancelar"
              secondary
              className="mr-3"
              onClick={ () => this.props.setVisibility(false) }
            />
            <Button
              text="Guardar"
              onClick={ () => this.editConfiguration() }
              disabled={ this.isDisabled() }
            />
          </Text>

        </Spacing>
      </Modal>
    );
  }
}

export default EditConfigurationModal;
