import React, { useState, useEffect, useCallback } from "react";
import Card from "./Card";
import {
  serviceFactory,
  calculatePages,
  getPreviousPage,
  getNextPage,
  PAGESIZE,
} from "../../../../utils/helpers";
import { NewsApi } from "../../../../services/api";
import "./News.scss";

const newsApi = serviceFactory(NewsApi);
const News = ({ type, typeNot }) => {
  const [newsList, setNewsList] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [previousPage, setPreviousPage] = useState(0);
  const [nextPage, setNextPage] = useState(0);

  const getNews = useCallback(
    () =>
      newsApi
        .newsList({
          page: currentPage,
          page_size: PAGESIZE,
          type: type ? type : "",
          typeNot: typeNot ? typeNot : "",
          ordering: "-created_at",
        })
        .then((response) => setNewsList(response.data)),
    [currentPage, type, typeNot, ]
  );
  useEffect(() => {
    getNews();
  }, [getNews]);

  useEffect(() => {
    setTotalPages(calculatePages(newsList?.count, PAGESIZE));
    setPreviousPage(getPreviousPage(newsList?.links?.previous));
    setNextPage(getNextPage(newsList?.links?.next));
  }, [newsList]);

  return (
    <>
      <div className="row mb-4">
        <div className="col-12">
          <h4 className="red-text">Noticias y promociones</h4>
        </div>
      </div>
      {newsList?.results?.map((news, index) => (
        <div key={`news-${index}`}>
          <Card
            title={news.title}
            message={news.description}
            files={news.files}
            newsDate={news.created_at}
          />
        </div>
      ))}
      <div className="col-12 text-right">
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li
              className={`page-item ${
                totalPages === 0 || (!previousPage && "disabled")
              }`}
            >
              <div
                onClick={() => {
                  if (previousPage) setCurrentPage(previousPage);
                  return null;
                }}
              >
                Anterior
              </div>
            </li>
            {totalPages > 0 ? (
              new Array(totalPages).fill(1).map((_, index) => {
                const pageSelection = ++index;
                return (
                  <li
                    className={` ${pageSelection === currentPage && "active"}`}
                    key={`select-page-${pageSelection}`}
                  >
                    <div onClick={() => setCurrentPage(pageSelection)}>
                      {pageSelection}
                    </div>
                  </li>
                );
              })
            ) : (
              <li className="page-item disabled">
                <div className="page-link">1</div>
              </li>
            )}

            <li
              className={`page-item ${
                totalPages === 0 || (!nextPage && "disabled")
              }`}
            >
              <div
                onClick={() => {
                  if (nextPage) setCurrentPage(nextPage);
                  return null;
                }}
              >
                Siguiente
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default News;
