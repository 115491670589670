import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import NextButton from '../NextButton';
import ActionButton from '../ActionButton';
import './styles.scss';

const Schedule = () => {
  const [toBooking, setToBooking] = useState(false);
  if (toBooking) return (<Redirect to="/app" />);
  return (
    <>
      <div className="shell-background">
        <div className="dark-layer shell-content">
          <div className="container schedule-box d-flex align-items-center align-items-lg-start justify-content-center position-relative flex-column">
            {/* <h1 className=" text-white">Cambio de aceite fácil y rápido</h1> */}
            <div className="row pt-md-5 text-white">
              <div className="col-12 text-center">
                <p className="hero-title">Agenda tu cita para el cambio de aceite en Shell Helix</p>
                <p className="hero-subtitle">Elige dónde, cuándo, tu auto ¡y listo!</p>
                <ActionButton text='¡Agenda aquí!' actionFunction={() => { setToBooking(true); }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <NextButton arrowColor='yellowArrow' sectionId='#features' />
    </>
  );
};

export default Schedule;
