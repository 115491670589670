import React from "react";

const GenericDetail = ({ data, title }) => {
  const keyList = Object.keys(data);
  const itemsPerColumn = Math.floor(keyList.length / 2);
  const firstList = keyList.slice(0, itemsPerColumn + 1);
  const secondList = keyList.slice(itemsPerColumn + 1);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h4 className="red-text">Detalles de {title}</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          {firstList.map((itemKey) => (
            <div>
              <span className="red-text font-weight-bold">{`${data[itemKey].name}: `}</span>
              {data[itemKey].value}
            </div>
          ))}
        </div>
        <div className="col-6">
          {secondList.map((itemKey) => (
            <div>
              <span className="red-text font-weight-bold">{`${data[itemKey].name}: `}</span>
              {data[itemKey].value}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default GenericDetail;
