import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { Elemental } from "react-elemental";
import ReactNotification from "react-notifications-component";
import Home from "../Home/Home";
import Login from "../Login/LoginContainer";
import ResetPassword from "../ResetPassword/ResetPasswordContainer";
import Header from "../Header/HeaderContainer";
import Footer from "../Footer/Footer";
import Planning from "../Planning/PlanningContainer";
import Products from "../Products";
import Booking from "../Booking/BookingContainer";
import Ticket from "../Ticket/TicketContainer";
import { history } from "../../redux/history";
import Info from "../Info/Info";
import InfoTicket from "../InfoTicket/InfoTicket";
import Configuration from "../Configuration/ConfigurationContainer";
import Dashboard from "../Dashboard/Dashboard";
import PrivateRoute from "../PrivateRoute";
import { axios_instance } from "../../services";
// import Landing from '../Landing';
import LandingV2 from "../LandingV2";
import "./App.scss";
import "../../commons/style/rules.scss";
import "../../commons/style/colors.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications-component/dist/theme.css";
import "react-datepicker/dist/react-datepicker.css";
import { initPixel, pageViewPixel, initTikTokPixel } from "../../utils/pixels";
import { serviceFactory } from "../../utils/helpers";
import { TokenApi } from "../../services/api";
import { useDispatch } from "react-redux";
import { logout } from "../../actions";
const tokenApi = serviceFactory(TokenApi);

const colorOpts = {
  primary: "#de1d22",
};

const AppWrapper = () => {
  return (
    <Router history={history}>
      <App />
    </Router>
  );
};

const App = () => {
  const routerHistory = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    if (token) {
      tokenApi
        .tokenRefreshCreate({ data: { refresh: refreshToken } })
        .then(
          (response) =>
            (axios_instance.defaults.headers.common.Authorization = `Bearer ${response.data.access}`)
        )
        .catch((error) => {
          dispatch(logout());
          routerHistory.push("/app/login");
        });
    }

    initPixel();
    pageViewPixel();
  });

  return (
    <Switch>

      <PrivateRoute path="/dashboard">
        <Dashboard />
      </PrivateRoute>

      <div id="main" className="div-main">
        <Route exact path="/" component={LandingV2} />

        <Route path="/app">
          <Elemental colorOpts={colorOpts}>
            <div className="App">
              <ReactNotification />
              <div id="container">
                <Header />
                <div className="container wrapper-main">
                  <Route exact path="/app" component={Home} />
                  <Route path="/app/login" component={Login} />
                  <Route path="/app/reset_password/:token" component={ResetPassword} />
                  <Route path="/app/planning" component={Planning} />
                  <Route path="/app/products" component={Products} />
                  <Route path="/app/bookings" component={Booking} />
                  <Route path="/app/config" component={Configuration} />
                  <Route path="/app/info" component={Info} />
                  <Route path="/app/info-ticket" component={InfoTicket} />
                  <Route path="/app/registro-boleta" component={Ticket} />
                </div>
              </div>
            </div>
          </Elemental>
        </Route>
      </div>
      <Footer />
    </Switch>
  );
};

export default AppWrapper;
