import React from 'react';
// import bidon from '../../commons/assets/bidon.png';
// import banner1 from '../../commons/assets/background1.jpg';
import banner2 from '../../commons/assets/background2.jpg';
import banner3 from '../../commons/assets/background3.jpg';
import banner4 from '../../commons/assets/background4.jpg';
// import ferrari from '../../commons/assets/ferrari.svg';
// import celular_banner from '../../commons/assets/celular_banner.png';
import CustomNavBar from '../Commons/CustomNavBar';
import './Header.scss';

class Header extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showMenu: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.bannerByStep = this.bannerByStep.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout() {
    this.props.logout();
    this.props.history.push('/app');
  }

  toggleMenu() {
    this.setState({ showMenu: !this.state.showMenu });
  }

  bannerByStep() {
    const { currentStep } = this.props.wizard;
    let banner = banner2;
    if (currentStep === 1 || currentStep === 2) {
      banner = banner2;
    } else if (currentStep === 3) {
      banner = banner3;
    } else if (currentStep === 4 || currentStep === 5) {
      banner = banner4;
    }

    return banner;
  }

  componentDidMount() {
    this.setState({ showMenu: false });
    this.bannerByStep();
  }

  render() {
    if (this.props.history.location.pathname === '/') return null;
    // const hash = this.props.location.hash;
    // const banner = this.bannerByStep();
    // const primaryTextClass = hash === '#Date' || hash === '#Car' || hash
    // === '#Client' ? 'text-white' : 'text-white';
    // const secondaryTextClass = hash === '#Date' || hash === '#Car' || hash
    // === '#Client' ? 'text-white' : 'text-yellow';
    // const positionBannerClass = banner === banner1 ? 'position-up' : banner
    // === banner4 ? 'position-down' : '';
    const atHome = this.props.history.location.pathname === '/app'
      || this.props.history.location.pathname === '/app/info'
      || this.props.history.location.pathname === '/app/config';
    // const location = this.props.history.location.pathname;

    // let bannerComponent = null;
    // let bannerMobileComponent = null;
    // switch (banner) {
    //   case banner1:
    //     // bannerComponent = <div className='row mr-0 ml-0'>
    //     //                       <div className='banner-text col-7 pr-0'>
    //     //                           <h1 className={`banner-text-primary font-weight-bold ${primaryTextClass}`}>Agenda online<br/>tu cambio de aceite</h1>
    //     //                           <h6 className={`banner-text-secondary font-weight-bold mt-2 ${secondaryTextClass}`}>Ahora más fácil y rápido.</h6>
    //     //                       </div>
    //     //                       <img className='bidon1 col-3 p-0 mr-auto' alt="Cambia con Helix" src={bidon}/>
    //     //                   </div>

    //     // PROMOTION BANNER
    //     bannerComponent = (
    //       <div className="row mr-0 ml-0 promotions">
    //         <div className="banner-text col-6 pr-0">
    //           <h6
    //             className={ `banner-text-secondary font-weight-bold mt-2 ${secondaryTextClass}` }
    //           >
    //             Agenda
    //             online y podrás ganar
    //           </h6>
    //           <h1
    //             className={ `banner-text-primary font-weight-bold ${primaryTextClass}` }
    //           >
    //             ¡1
    //             de los 6 años
    //             <br />
    //             de combustible!
    //           </h1>
    //           <h6>
    //             <span
    //               className={ `banner-text-secondary font-weight-bold mt-2 mb-3 ${secondaryTextClass}` }
    //             >
    //               4to Ganador:
    //             </span>
    //             <span
    //               className="banner-text-secondary font-weight-bold mt-2 mb-3 text-white"
    //             >
    //               Aldo Olivares
    //             </span>
    //           </h6>
    //         </div>
    //         <img
    //           className="celular-banner col-6 p-0 mr-auto"
    //           alt="Cambia con Helix"
    //           src={ celular_banner }
    //         />
    //       </div>
    //     );
    //     bannerMobileComponent = (
    //       <div className="mr-0 ml-0 promotions">
    //         <div className="banner-text pr-0 pl-0 ml-auto mr-auto">
    //           <h6
    //             className={ `banner-text-secondary font-weight-bold mt-2 mb-3 ${secondaryTextClass}` }
    //           >
    //             Agenda
    //             online y podrás ganar
    //           </h6>
    //           <h4
    //             className={ `banner-text-primary font-weight-bold ${primaryTextClass}` }
    //           >
    //             ¡1
    //             de los 6 años
    //             <br />
    //             de combustible!
    //           </h4>
    //           <h6>
    //             <span
    //               className={ `banner-text-secondary font-weight-bold mt-2 mb-3 ${secondaryTextClass}` }
    //             >
    //               4to Ganador:
    //             </span>
    //             <span
    //               className="banner-text-secondary font-weight-bold mt-2 mb-3 text-white"
    //             >
    //               Aldo Olivares
    //             </span>
    //           </h6>
    //         </div>
    //       </div>
    //     );
    //     break;
    //   case banner2:
    //   case banner3:
    //     bannerComponent = (
    //       <div className="row mr-0 ml-0">
    //         <div className="banner-text col-7 pr-0">
    //           <h1
    //             className={ `banner-text-primary font-weight-bold ${primaryTextClass}` }
    //           >
    //             Agenda
    //             online
    //             <br />
    //             tu cambio de aceite
    //           </h1>
    //           <h6
    //             className={ `banner-text-secondary font-weight-bold mt-2 ${secondaryTextClass}` }
    //           >
    //             Ahora
    //             más fácil y rápido.
    //           </h6>
    //         </div>
    //       </div>
    //     );
    //     bannerMobileComponent = (
    //       <div className="row mr-0 ml-0">
    //         <div className="banner-text col-7 pr-0 pl-0">
    //           <h4
    //             className={ `banner-text-primary font-weight-bold ${primaryTextClass}` }
    //           >
    //             Agenda
    //             online
    //             <br />
    //             tu cambio de aceite
    //           </h4>
    //           <h6
    //             className={ `banner-text-secondary font-weight-bold mt-2 ${secondaryTextClass}` }
    //           >
    //             Ahora
    //             más fácil y rápido.
    //           </h6>
    //         </div>
    //       </div>
    //     );
    //     break;
    //   case banner4:
    //     bannerComponent = (
    //       <div className="row mr-0 ml-0 banner4">
    //         <img
    //           className="bidon"
    //           alt="Cambia con Helix"
    //           src={ bidon }
    //           height="120px"
    //           width="auto"
    //         />
    //         <div className="banner-text col-7 margin-top--20 ">
    //           <h1
    //             className={ `banner-text-primary font-weight-bold ${primaryTextClass}` }
    //           >
    //             Directamente
    //             de las pistas a tu auto
    //           </h1>
    //           <img
    //             className="mt-3"
    //             alt="Cambia con Helix"
    //             src={ ferrari }
    //             height="50px"
    //             width="auto"
    //           />
    //         </div>
    //       </div>
    //     );
    //     bannerMobileComponent = (
    //       <div className="row mr-0 ml-0 banner4">
    //         <img
    //           className="bidon col-3"
    //           alt="Cambia con Helix"
    //           src={ bidon }
    //         />
    //         <div className="banner-text col-8">
    //           <h4
    //             className={ `banner-text-primary font-weight-bold ${primaryTextClass}` }
    //           >
    //             Directamente
    //             de las pistas a tu auto
    //           </h4>
    //           <img
    //             className="mt-1"
    //             alt="Cambia con Helix"
    //             src={ ferrari }
    //             height="50px"
    //             width="auto"
    //           />
    //         </div>
    //       </div>
    //     );
    //     break;
    //   default:
    //     bannerComponent = <div />;
    //     bannerMobileComponent = <div />;
    //     break;
    // }

    return (
      <div>
        <CustomNavBar />
        {
          atHome
          && (
          <div>
            {/*<div className="banner d-none d-lg-block">
              <img
                className={ `banner w-100 ${positionBannerClass}` }
                alt=""
                src={ banner }
              />
              <div
                className="container padding-left-270 padding-right-150 banner-container"
              >
                { bannerComponent }
              </div>
            </div>*/}

            {/*<div className="banner mobile-banner d-lg-none d-xl-none ">
              <img
                className={ `banner w-100 ${positionBannerClass}` }
                alt=""
                src={ banner }
              />
              <div className="container banner-container">
                { bannerMobileComponent }
              </div>
            </div>*/}
          </div>
          )
        }

      </div>
    );
  }
}

export default Header;
