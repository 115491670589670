import React, { useState, useEffect, useCallback, useMemo } from "react";
import Modal from "../Modal";
import "./NewOrder.scss";
import GenericDetail from "../Generics/Detail";
import FirstStep from "./OrderForm/FirstStep";
import SecondStep from "./OrderForm/SecondStep";
import ThirdStep from "./OrderForm/ThirdStep";
import FourthStep from "./OrderForm/FourthStep";
import { WorkOrdersApi } from "../../../../services/api";
import { BookingsApi, BookingStationProductsApi } from "../../../../services/api";
import { showNotification, resetNotification } from "../../../../actions";
import { errorType } from "../../../../constants";
import {
  getCurrentUser,
  calculatePages,
  getPreviousPage,
  getNextPage,
  serviceFactory,
  PAGESIZE,
  getAllLubricators,
} from "../../../../utils/helpers";
import moment from "moment";
import CreateOrder from "../CreateOrder";

const workOrdersApi = serviceFactory(WorkOrdersApi);
const bookingsApi = serviceFactory(BookingsApi);
const bookingStationProductsApi = serviceFactory(BookingStationProductsApi);

const NavSteps = ({ formStep }) => {
  const steps = [
    { step: 1, title: "Datos del cliente" },
    { step: 2, title: "Datos del vehículo" },
    { step: 3, title: "Servicios realizados" },
    { step: 4, title: "Resumen" },
  ];
  return (
    <div className="row">
      {steps.map((step, index) => (
        <div className="col text-center text-md-left" key={index}>
          <div className="d-inline-block align-middle">
            <div
              className={`d-flex text-white align-items-center justify-content-center mr-md-3 shell-bold ${
                formStep === step.step
                  ? "number-circle"
                  : "number-circle-inactive"
              }`}
            >
              {step.step}
            </div>
          </div>
          <div className="d-inline-block title-text">
            <h6
              className={`${
                formStep === step.step ? "red-text" : "gray-text"
              } m-0`}
            >
              {step.title}
            </h6>
          </div>
        </div>
      ))}
    </div>
  );
};

const NewOrder = () => {
  const currentUser = useMemo(() => getCurrentUser(), []);
  const initialOrderData = {
    booking: {
      customer: {
        name: "",
        phone: "",
        rut: "",
        email: "",
      },
      vehicle: {
        patent: "",
        type: "",
        fuel_type: "",
        brand: "",
        model: "",
        year: "",
        cylinder_capacity: "",
        mileage: "",
      },
      is_ghost: true,
      station: 0,
    },
    services: [],
    lubricator: "",
    ticket_number: "",
    ot_number: "",
  };

  const [createOrderView, setCreateOrderView] = useState(false);
  const [formStep, setFormStep] = useState(1);
  const [newOrderData, setNewOrderData] = useState(initialOrderData);
  const [lubricators, setLubricators] = useState(null);
  const [bookings, setBookings] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [previousPage, setPreviousPage] = useState(0);
  const [nextPage, setNextPage] = useState(0);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [detailData, setDetailData] = useState(null);

  const submitOrder = async () => {
    const submitData = {
      ...newOrderData,
      booking: {
        ...newOrderData.booking,
        customer: {
          ...newOrderData.booking.customer,
          rut: newOrderData.booking.customer.rut.replaceAll(".", ""),
        },
      },
    };
    try {

      const response = await workOrdersApi.workOrdersCreate({ data: submitData });
      let booking_id = null;
      
      if (response.status === 201) {
        booking_id = response.data.booking.id;
        showNotification(
          errorType.SUCCESS,
          "Orden de trabajo creada exitosamente"
        );
        setCreateOrderView(false);
        restartOrderData();
        setFormStep(1);
        getBookings();
      } else {
        showNotification(errorType.ERROR, "Error creando orden de trabajo");
      }
      resetNotification();
      
      if (booking_id != null && Array.isArray(newOrderData.products) && newOrderData.products.length > 0) {
        const data = {
          ...newOrderData.products[0],
          booking: booking_id,
        }
        await bookingStationProductsApi.bookingStationProductsCreate({ data });
      }

    } catch (error) {
      showNotification(errorType.ERROR, "Error creando orden de trabajo");
      resetNotification();
    }
    
  };

  const getBookings = useCallback(() => {
    bookingsApi
      .bookingsList({
        page: currentPage,
        station: currentUser?.station?.id,
        pageSize: PAGESIZE,
        isGhost: false,
        ordering: "date",
        status: "activated",
        afterCreated: moment().subtract(8, "days").format("YYYY-MM-DD"),
      })
      .then((response) => setBookings(response.data));
  }, [currentPage, currentUser]);

  const restartOrderData = () => {
    setNewOrderData({
      ...initialOrderData,
      booking: {
        ...initialOrderData.booking,
        station: currentUser?.station?.id,
      },
    });
  };

  const getPerfomedServices = (obj, changed) => {
    const filteredServices = obj.services.filter(
      (element) => element.changed === changed
    );
    if (filteredServices.length > 0)
      return filteredServices.map((element) => element.service.name).join(", ");
    return changed
      ? "Ninguno"
      : obj.services.length > 0
      ? "Los componentes han sido cambiados"
      : "Ninguno";
  };

  useEffect(() => {
    setTotalPages(calculatePages(bookings?.count, PAGESIZE));
    setPreviousPage(getPreviousPage(bookings?.previous));
    setNextPage(getNextPage(bookings?.next));
  }, [bookings]);

  useEffect(() => {
    getBookings();
  }, [getBookings]);

  useEffect(() => {
    getAllLubricators({
      station: currentUser?.station?.id,
      isActive: true,
    }).then((lubricators) => setLubricators(lubricators));
    setNewOrderData((prevState) => ({
      ...prevState,
      booking: { ...prevState.booking, station: currentUser?.station?.id },
    }));
  }, [currentUser]);

  return (
    <>
      {createOrderView ? (
        <>
          <div className="my-4">
            <NavSteps formStep={formStep} />
          </div>

          <CreateOrder>
            {formStep === 1 && (
              <FirstStep
                setNewOrderData={setNewOrderData}
                setFormStep={setFormStep}
                newOrderData={newOrderData}
                lubricators={lubricators}
              />
            )}
            {formStep === 2 && (
              <SecondStep
                setNewOrderData={setNewOrderData}
                newOrderData={newOrderData}
                setFormStep={setFormStep}
              />
            )}
            {formStep === 3 && (
              <ThirdStep
                setNewOrderData={setNewOrderData}
                newOrderData={newOrderData}
                setFormStep={setFormStep}
              />
            )}
            {formStep === 4 && (
              <FourthStep
                setNewOrderData={setNewOrderData}
                newOrderData={newOrderData}
                submitOrder={submitOrder}
                setFormStep={setFormStep}
              />
            )}
          </CreateOrder>
        </>
      ) : (
        <>
          {isDetailVisible && (
            <Modal
              onCloseClick={() => {
                setIsDetailVisible(false);
                setDetailData(null);
              }}
            >
              <GenericDetail data={detailData} title="orden de trabajo" />
            </Modal>
          )}

          <div className="row new-order-box">
            <div className="col-12">
              <div className="text-right">
                <button
                  type="button"
                  className="btn new-order-button font-weight-bold"
                  onClick={() =>
                    setCreateOrderView((prevState) => {
                      if (!prevState === false) {
                        setFormStep(1);
                        restartOrderData();
                      }
                      return !prevState;
                    })
                  }
                >
                  Nueva orden sin agendar
                </button>
              </div>
            </div>
          </div>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Fecha y hora</th>
                <th scope="col">Estado</th>
                <th scope="col">Patente</th>
                <th scope="col">Marca</th>
                <th scope="col">Modelo</th>
                <th scope="col">Año</th>
                <th scope="col">Combustible</th>
                <th scope="col">Nombre</th>
                <th scope="col">Promo</th>
                <th scope="col">Acción</th>
              </tr>
            </thead>
            <tbody>
              {bookings?.results?.map((booking) => {
                const bookingDate = moment(booking.date, "YYYY-MM-DDTHH:mm:ss");
                return (
                  <tr key={booking.id}>
                    <th scope="row">
                      {bookingDate.format("DD-MM-YYYY HH:mm")}
                    </th>
                    <td>
                      {bookingDate.isAfter() ? (
                        <span className="badge badge-success">Activa</span>
                      ) : (
                        <span className="badge badge-danger">Caducada</span>
                      )}
                    </td>
                    <td>{booking.vehicle?.patent}</td>
                    <td>{booking.vehicle?.brand}</td>
                    <td>{booking.vehicle?.model}</td>
                    <td>{booking.vehicle?.year}</td>
                    <td>{booking.vehicle?.fuel_type}</td>
                    <td>{booking.customer?.name}</td>
                    <td>{booking.prom_code}</td>
                    <td>
                      {booking.work_order ? (
                        <button
                          className="btn btn-outline-danger rounded-pill btn-sm font-weight-bold"
                          onClick={() => {
                            workOrdersApi
                              .workOrdersRead({ id: booking.work_order })
                              .then((response) => {
                                const responseData = response.data;
                                const detailData = {
                                  id: {
                                    value: responseData.id,
                                    name: "ID",
                                  },
                                  booking: {
                                    value: `${moment(
                                      responseData.booking.date
                                    ).format("YYYY/MM/DD HH:mm")} - ${
                                      responseData.booking.customer.name
                                    } - ${responseData.booking.vehicle.patent}`,
                                    name: "Reserva",
                                  },
                                  workOrderDate: {
                                    value: moment(
                                      responseData.created_at
                                    ).format("YYYY/MM/DD HH:mm"),
                                    name: "Fecha de trabajo finalizado",
                                  },
                                  inspected: {
                                    value: getPerfomedServices(
                                      responseData,
                                      false
                                    ),
                                    name: "Componentes revisados",
                                  },
                                  changed: {
                                    value: getPerfomedServices(
                                      responseData,
                                      true
                                    ),
                                    name: "Componentes cambiados",
                                  },
                                  ticketNumber: {
                                    value: responseData.ticket_number,
                                    name: "N° de boleta",
                                  },
                                  businessName: {
                                    value: responseData.business_name,
                                    name: "Razón social",
                                  },
                                };
                                setDetailData(detailData);
                                setIsDetailVisible(true);
                              });
                          }}
                        >
                          Ver detalle
                        </button>
                      ) : (
                        <button
                          className="btn new-order-button btn-sm font-weight-bold"
                          onClick={() => {
                            setCreateOrderView(true);
                            setNewOrderData((prevState) => ({
                              ...prevState,
                              booking: {
                                ...booking,
                                is_ghost: false,
                              },
                            }));
                          }}
                        >
                          Nueva orden
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="col-12 text-right">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li
                  className={`page-item ${
                    totalPages === 0 || (!previousPage && "disabled")
                  }`}
                >
                  <div
                    onClick={() => {
                      if (previousPage) setCurrentPage(previousPage);
                      return null;
                    }}
                  >
                    Anterior
                  </div>
                </li>
                {totalPages > 0 ? (
                  new Array(totalPages).fill(1).map((_, index) => {
                    const pageSelection = ++index;
                    return (
                      <li
                        className={` ${
                          pageSelection === currentPage && "active"
                        }`}
                        key={`select-page-${pageSelection}`}
                      >
                        <div onClick={() => setCurrentPage(pageSelection)}>
                          {pageSelection}
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <li className="page-item disabled">
                    <div className="page-link">1</div>
                  </li>
                )}

                <li
                  className={`page-item ${
                    totalPages === 0 || (!nextPage && "disabled")
                  }`}
                >
                  <div
                    onClick={() => {
                      if (nextPage) setCurrentPage(nextPage);
                      return null;
                    }}
                  >
                    Siguiente
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </>
      )}
    </>
  );
};

export default NewOrder;
