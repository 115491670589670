import React from 'react';
import NextButton from '../NextButton';
import './styles.scss';

const Schedule = () => {
  return (
    <>
      <div className="video-background generic-padding" id="shell-experience">
        <div className="container">
          <div className="row pt-5 text-white">
            <div className="col text-center video-box">
              <h4>Descubre la experiencia Shell Helix</h4>
              <div className="mt-4 embed-responsive embed-responsive-16by9">
                <iframe className="mx-auto embed-responsive-item" src="https://www.youtube.com/embed/-OzS1Hi5GpY" 
                title="Shell Helix Ultra" frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NextButton arrowColor='yellowArrow' sectionId='#findlub' />
    </> 
  );
};

export default Schedule;
