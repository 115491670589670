import styled from "styled-components";
import { Modal as BaseModal } from "react-elemental";
import { AiOutlineClose as BaseClose } from "react-icons/ai";

export const Close = styled(BaseClose)`
  position: absolute;
  right: 12%;
  top: 2%;
  color: white;
  cursor: pointer;
  svg {
    font-size: 1.8rem;
    font-weight: 600;
  }
`;

export const Modal = styled(BaseModal)`
  text-align: center;
  background-color: transparent !important;
  box-shadow: none;
  border: none;
  max-width: 100% !important;
  z-index: 900 !important;
  outline-width: 0 !important;
  &:focus-visible {
    outline-width: 0;
    outline-style: none;
    outline-color: transparent;
  }
  img{
    max-width: 100%;
  }
`
