import { USER_ROLES } from "../../utils/helpers";

export const sidebarLinks = () => {
  let items = [
    {
      name: "Noticias y promociones",
      url: "/news",
      external: false,
      children: null,
      permissions: [USER_ROLES.LUBRICATOR, USER_ROLES.MANAGER]
    },
    // {
    //   name: "Inicio",
    //   url: "/",
    //   external: false,
    //   children: null,
    //   permissions: [USER_ROLES.LUBRICATOR, USER_ROLES.MANAGER]
    // },
    {
      name: "Cambia con Helix",
      url: null,
      external: false,
      permissions: [USER_ROLES.LUBRICATOR, USER_ROLES.MANAGER],
      children: [
        {
          key: "child-1",
          name: "Gestión y planificación",
          url: "/cambia-con-helix/management",
          external: false,
        },
        {
          key: "child-2",
          name: "Productos",
          url: "/cambia-con-helix/products",
          external: false,
        },
        {
          key: "child-3",
          name: "Reservar",
          url: "/cambia-con-helix/booking",
          external: false,
        },
      ],
    },
    {
      name: "Órdenes de trabajo",
      url: null,
      external: false,
      permissions: [USER_ROLES.LUBRICATOR, USER_ROLES.MANAGER],
      children: [
        {
          key: "workorder-1",
          name: "Lista de órdenes",
          url: "/work-orders/list",
          external: false,
        },
        {
          key: "workorder-2",
          name: "Nueva orden de trabajo",
          url: "/work-orders/new-order",
          external: false,
        },
      ],
    },
    {
      name: "Lubricadores",
      url: "/lubricators",
      external: false,
      children: null,
      permissions: [USER_ROLES.MANAGER]
    },
    // {
    //   name: "Avance ventas",
    //   url: "/sales",
    //   external: false,
    //   children: null,
    //   permissions: [USER_ROLES.MANAGER]
    // },
    {
      name: "Incentivos",
      url: "/incentives",
      external: false,
      children: null,
      permissions: [USER_ROLES.LUBRICATOR, USER_ROLES.MANAGER]
    },
    {
      name: "Capacitaciones",
      url: "/trainings",
      external: false,
      children: null,
      permissions: [USER_ROLES.LUBRICATOR, USER_ROLES.MANAGER]
    },
    {
      name: "Configuración",
      url: "/settings",
      external: false,
      children: null,
      permissions: [USER_ROLES.MANAGER]
    },
  ];
  let key_value = 1;
  items.forEach((element) => {
    element.key = `sidebar-button-${key_value}`;
    ++key_value;
  });
  return items;
};
