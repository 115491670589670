import { axios_instance } from './index';

export async function getConfig(stationId) {
  return axios_instance.get(`settings/station/${stationId}/`);
}

export async function editConfig(configId, data) {
  return axios_instance.patch(`settings/${configId}/`, data);
}

export async function getRegions() {
  return axios_instance.get('regions/');
}

export async function getCommunes(regionId) {
  return axios_instance.get(`communes/?region_id=${regionId}`);
}

export async function getStations(communeId = null) {
  if (communeId === null) {
    return axios_instance.get('stations/');
  }
  return axios_instance.get(`stations/?commune_id=${communeId}`);
}

export async function updateFullConfig(data) {
  return axios_instance.post('update_configuration/', data);
}

export const stationService = {
  getConfig,
  editConfig,
  getRegions,
  getCommunes,
  getStations,
  updateFullConfig,
};
