import React from 'react';
import ReactDOM from 'react-dom';
import './fonts/Lato-Black.ttf';
import './fonts/Lato-BlackItalic.ttf';
import './fonts/Lato-Bold.ttf';
import './fonts/Lato-BoldItalic.ttf';
import './fonts/Lato-Italic.ttf';
import './fonts/Lato-Light.ttf';
import './fonts/Lato-Regular.ttf';
import './fonts/Lato-Thin.ttf';
import './fonts/Lato-ThinItalic.ttf';
import './index.scss';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import AppWrapper from './components/App/App';
import * as serviceWorker from './serviceWorker';
import { persistor, store } from './redux/store';

ReactDOM.render(
  <Provider store={ store }>
    <PersistGate persistor={ persistor }>
      <AppWrapper />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
