import { axios_instance } from './index';

export async function login(username, password) {
  const credentials = {
    username,
    password,
  };

  const response = await axios_instance.post('token/', credentials);
  if (response.data.access) {
    const token = response.data.access;
    const refresh = response.data.refresh;
    axios_instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem('accessToken', `Bearer ${token}`);
    localStorage.setItem('refreshToken', refresh);
    return axios_instance.get('user');
  }
}

export async function reset_password(username) {
  const credentials = {
    username,
  };
  return axios_instance.post('forgot-password/', credentials);
}

export async function change_password(token, pass, pass2) {
  const credentials = {
    new_password: pass,
    repeated_password: pass2,
  };
  return axios_instance.post(`reset-password/${token}/`, credentials);
}

export async function get_customer_by_rut(rut) {
  return axios_instance.get(`customers/rut/${rut}/`);
}

export const authService = {
  login,
  reset_password,
  change_password,
  get_customer_by_rut,
};
