import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import {
  formatLimitedRut,
  allowOnlyLetters,
  allowOnlyNumbers,
} from "../../../../utils/helpers";

const LubricatorsForm = ({ setFormData, formData, formTitles }) => {
  const [startDate, setStartDate] = useState(null);

  useEffect(() => {
    if (startDate) {
      const birthday = startDate.toISOString().split("T")[0];
      setFormData((prevState) => ({
        ...prevState,
        birthday: birthday,
      }));
    }
  }, [startDate, setFormData]);

  useEffect(() => {
    if (formData && formData.birthday !== "") {
      const currentDate = formData.birthday.replaceAll("-", "/");
      setStartDate(new Date(currentDate));
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <label className="d-block">{formTitles["full_name"].title}</label>
          <input
            name="full_name"
            placeholder={formTitles["full_name"].title}
            className="d-block form-control rounded-pill"
            required
            onChange={(e) => {
              e.persist();
              const full_name = allowOnlyLetters(
                e.target.value,
                formData.full_name
              );

              setFormData((prevState) => ({
                ...prevState,
                full_name: full_name,
              }));
            }}
            value={formData?.full_name}
          />
        </div>
        <div className="col-md-6">
          <label className="d-block">{formTitles["email"].title}</label>
          <input
            name="email"
            type="email"
            placeholder={formTitles["email"].title}
            className="d-block form-control rounded-pill"
            onChange={(e) => {
              e.persist();
              setFormData((prevState) => ({
                ...prevState,
                email: e.target.value,
              }));
            }}
            value={formData?.email}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-xl-4">
          <label className="d-block">{formTitles["rut"].title}</label>
          <input
            name="rut"
            placeholder={formTitles["rut"].title}
            className="d-block form-control rounded-pill"
            required
            onChange={(e) => {
              e.persist();
              const rut = formatLimitedRut(formData.rut, e.target.value);
              setFormData((prevState) => ({
                ...prevState,
                rut: rut,
              }));
            }}
            value={formData.rut}
          />
        </div>

        <div className="col-md-6 col-xl-4">
          <label className="d-block">{formTitles["birthday"].title}</label>
          <DatePicker
            placeholderText="aaaa/mm/dd"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            showYearDropdown
            showMonthDropdown
            locale={"es-ES"}
            dateFormat={"yyyy-MM-dd"}
            className="form-control rounded-pill"
          />
        </div>
        <div className="col-md-6 col-xl-4">
          <label className="d-block">{formTitles["phone"].title}</label>
          <input
            name="phone"
            placeholder={formTitles["phone"].title}
            className="d-block form-control rounded-pill"
            onChange={(e) => {
              e.persist();
              let phone = allowOnlyNumbers(e.target.value, formData.phone);
              phone = e.target.value.length <= 20 ? phone : formData.phone;
              setFormData((prevState) => ({
                ...prevState,
                phone: phone,
              }));
            }}
            value={formData?.phone}
          />
        </div>
      </div>
    </>
  );
};

export default LubricatorsForm;
