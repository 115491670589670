import React , { useState, useEffect }from 'react';
import whiteArrow from './assets/white-arrow.svg';
import './styles.scss';

const ActionButton = ({ actionFunction, text, animatedArrow }) => {
    const [showArrow, setShowArrow ] = useState(false);
    const [toggleStyle, setToggleStyle] = useState(false)
    const [style, setStyle] = useState(undefined)

    const upArrow = {
        transform: 'rotate(0deg)',
        transition: 'transform 0.2s'
    }
    const downArrow = {
        transform: 'rotate(-180deg)',
        transition: 'transform 0.2s'
    }

    useEffect(() => {
        if (animatedArrow) {
            setShowArrow(true);
        }
    }, [animatedArrow]);

    const rotateArrow = () => {
        setToggleStyle(prevCheck => !prevCheck)
        setStyle(toggleStyle ? upArrow : downArrow)
    }

    const runFunctions = () => {
        actionFunction();
        if (animatedArrow) {
            rotateArrow();
        }
    }

    return(
        <button className="btn-icon btn-red rounded-shell mt-5 mx-auto text-uppercase text-white shell-bold" onClick={runFunctions}>
            {text} {showArrow && <img className="arrow-svg" style={style} src={whiteArrow} alt="Open section" />}
        </button>
    );
}

export default ActionButton;