/* tslint:disable */
/* eslint-disable */
/**
 * Enex API
 * API to manage bookings for oil change with Enex
 *
 * The version of the OpenAPI document: v1
 * Contact: jliznaga@linets.cl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Vehicle
 */
export interface Vehicle {
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    'patent': string;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    'fuel_type'?: VehicleFuelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    'brand'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    'model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vehicle
     */
    'year': string;
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    'cylinder_capacity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Vehicle
     */
    'mileage'?: number | null;
}

export const VehicleFuelTypeEnum = {
    Diesel: 'diesel',
    Gasoline: 'gasoline'
} as const;

export type VehicleFuelTypeEnum = typeof VehicleFuelTypeEnum[keyof typeof VehicleFuelTypeEnum];


