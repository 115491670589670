export const stationConstants = {
  GET_CONFIG_REQUEST: 'GET_CONFIG_REQUEST',
  GET_CONFIG_SUCCESS: 'GET_CONFIG_SUCCESS',
  GET_CONFIG_FAILURE: 'GET_CONFIG_FAILURE',

  EDIT_CONFIG_REQUEST: 'EDIT_CONFIG_REQUEST',
  EDIT_CONFIG_SUCCESS: 'EDIT_CONFIG_SUCCESS',
  EDIT_CONFIG_FAILURE: 'EDIT_CONFIG_FAILURE',

  EDIT_FULL_CONFIG_REQUEST: 'EDIT_FULL_CONFIG_REQUEST',
  EDIT_FULL_CONFIG_SUCCESS: 'EDIT_FULL_CONFIG_SUCCESS',
  EDIT_FULL_CONFIG_FAILURE: 'EDIT_FULL_CONFIG_FAILURE',

  GET_REGIONS_REQUEST: 'GET_REGIONS_REQUEST',
  GET_REGIONS_SUCCESS: 'GET_REGIONS_SUCCESS',
  GET_REGIONS_FAILURE: 'GET_REGIONS_FAILURE',

  GET_COMMUNES_REQUEST: 'GET_COMMUNES_REQUEST',
  GET_COMMUNES_SUCCESS: 'GET_COMMUNES_SUCCESS',
  GET_COMMUNES_FAILURE: 'GET_COMMUNES_FAILURE',

  GET_STATIONS_REQUEST: 'GET_STATIONS_REQUEST',
  GET_STATIONS_SUCCESS: 'GET_STATIONS_SUCCESS',
  GET_STATIONS_FAILURE: 'GET_STATIONS_FAILURE',
};
