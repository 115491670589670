/* tslint:disable */
/* eslint-disable */
/**
 * Enex API
 * API to manage bookings for oil change with Enex
 *
 * The version of the OpenAPI document: v1
 * Contact: jliznaga@linets.cl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { StationSaveProduct } from '../models';
/**
 * StationProductsApi - axios parameter creator
 * @export
 */
export const StationProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint that allows manage StationProducts.
         * @param {StationSaveProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationProductsCreate: async (data: StationSaveProduct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stationProductsCreate', 'data', data)
            const localVarPath = `/station_products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows manage StationProducts.
         * @param {number} id A unique integer value identifying this producto en sucursal.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationProductsDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stationProductsDelete', 'id', id)
            const localVarPath = `/station_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all station_products from Station
         * @param {string} stationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationProductsGetStationProducts: async (stationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stationId' is not null or undefined
            assertParamExists('stationProductsGetStationProducts', 'stationId', stationId)
            const localVarPath = `/station_products/station/{station_id}/`
                .replace(`{${"station_id"}}`, encodeURIComponent(String(stationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows manage StationProducts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationProductsList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/station_products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows manage StationProducts.
         * @param {number} id A unique integer value identifying this producto en sucursal.
         * @param {StationSaveProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationProductsPartialUpdate: async (id: number, data: StationSaveProduct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stationProductsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stationProductsPartialUpdate', 'data', data)
            const localVarPath = `/station_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows manage StationProducts.
         * @param {number} id A unique integer value identifying this producto en sucursal.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationProductsRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stationProductsRead', 'id', id)
            const localVarPath = `/station_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows manage StationProducts.
         * @param {number} id A unique integer value identifying this producto en sucursal.
         * @param {StationSaveProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationProductsUpdate: async (id: number, data: StationSaveProduct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stationProductsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stationProductsUpdate', 'data', data)
            const localVarPath = `/station_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StationProductsApi - functional programming interface
 * @export
 */
export const StationProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StationProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * API endpoint that allows manage StationProducts.
         * @param {StationSaveProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stationProductsCreate(data: StationSaveProduct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StationSaveProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stationProductsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API endpoint that allows manage StationProducts.
         * @param {number} id A unique integer value identifying this producto en sucursal.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stationProductsDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stationProductsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all station_products from Station
         * @param {string} stationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stationProductsGetStationProducts(stationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StationSaveProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stationProductsGetStationProducts(stationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API endpoint that allows manage StationProducts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stationProductsList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StationSaveProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stationProductsList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API endpoint that allows manage StationProducts.
         * @param {number} id A unique integer value identifying this producto en sucursal.
         * @param {StationSaveProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stationProductsPartialUpdate(id: number, data: StationSaveProduct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StationSaveProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stationProductsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API endpoint that allows manage StationProducts.
         * @param {number} id A unique integer value identifying this producto en sucursal.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stationProductsRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StationSaveProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stationProductsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API endpoint that allows manage StationProducts.
         * @param {number} id A unique integer value identifying this producto en sucursal.
         * @param {StationSaveProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stationProductsUpdate(id: number, data: StationSaveProduct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StationSaveProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stationProductsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StationProductsApi - factory interface
 * @export
 */
export const StationProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StationProductsApiFp(configuration)
    return {
        /**
         * API endpoint that allows manage StationProducts.
         * @param {StationSaveProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationProductsCreate(data: StationSaveProduct, options?: any): AxiosPromise<StationSaveProduct> {
            return localVarFp.stationProductsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows manage StationProducts.
         * @param {number} id A unique integer value identifying this producto en sucursal.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationProductsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.stationProductsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all station_products from Station
         * @param {string} stationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationProductsGetStationProducts(stationId: string, options?: any): AxiosPromise<Array<StationSaveProduct>> {
            return localVarFp.stationProductsGetStationProducts(stationId, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows manage StationProducts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationProductsList(options?: any): AxiosPromise<Array<StationSaveProduct>> {
            return localVarFp.stationProductsList(options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows manage StationProducts.
         * @param {number} id A unique integer value identifying this producto en sucursal.
         * @param {StationSaveProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationProductsPartialUpdate(id: number, data: StationSaveProduct, options?: any): AxiosPromise<StationSaveProduct> {
            return localVarFp.stationProductsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows manage StationProducts.
         * @param {number} id A unique integer value identifying this producto en sucursal.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationProductsRead(id: number, options?: any): AxiosPromise<StationSaveProduct> {
            return localVarFp.stationProductsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows manage StationProducts.
         * @param {number} id A unique integer value identifying this producto en sucursal.
         * @param {StationSaveProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stationProductsUpdate(id: number, data: StationSaveProduct, options?: any): AxiosPromise<StationSaveProduct> {
            return localVarFp.stationProductsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for stationProductsCreate operation in StationProductsApi.
 * @export
 * @interface StationProductsApiStationProductsCreateRequest
 */
export interface StationProductsApiStationProductsCreateRequest {
    /**
     * 
     * @type {StationSaveProduct}
     * @memberof StationProductsApiStationProductsCreate
     */
    readonly data: StationSaveProduct
}

/**
 * Request parameters for stationProductsDelete operation in StationProductsApi.
 * @export
 * @interface StationProductsApiStationProductsDeleteRequest
 */
export interface StationProductsApiStationProductsDeleteRequest {
    /**
     * A unique integer value identifying this producto en sucursal.
     * @type {number}
     * @memberof StationProductsApiStationProductsDelete
     */
    readonly id: number
}

/**
 * Request parameters for stationProductsGetStationProducts operation in StationProductsApi.
 * @export
 * @interface StationProductsApiStationProductsGetStationProductsRequest
 */
export interface StationProductsApiStationProductsGetStationProductsRequest {
    /**
     * 
     * @type {string}
     * @memberof StationProductsApiStationProductsGetStationProducts
     */
    readonly stationId: string
}

/**
 * Request parameters for stationProductsPartialUpdate operation in StationProductsApi.
 * @export
 * @interface StationProductsApiStationProductsPartialUpdateRequest
 */
export interface StationProductsApiStationProductsPartialUpdateRequest {
    /**
     * A unique integer value identifying this producto en sucursal.
     * @type {number}
     * @memberof StationProductsApiStationProductsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {StationSaveProduct}
     * @memberof StationProductsApiStationProductsPartialUpdate
     */
    readonly data: StationSaveProduct
}

/**
 * Request parameters for stationProductsRead operation in StationProductsApi.
 * @export
 * @interface StationProductsApiStationProductsReadRequest
 */
export interface StationProductsApiStationProductsReadRequest {
    /**
     * A unique integer value identifying this producto en sucursal.
     * @type {number}
     * @memberof StationProductsApiStationProductsRead
     */
    readonly id: number
}

/**
 * Request parameters for stationProductsUpdate operation in StationProductsApi.
 * @export
 * @interface StationProductsApiStationProductsUpdateRequest
 */
export interface StationProductsApiStationProductsUpdateRequest {
    /**
     * A unique integer value identifying this producto en sucursal.
     * @type {number}
     * @memberof StationProductsApiStationProductsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {StationSaveProduct}
     * @memberof StationProductsApiStationProductsUpdate
     */
    readonly data: StationSaveProduct
}

/**
 * StationProductsApi - object-oriented interface
 * @export
 * @class StationProductsApi
 * @extends {BaseAPI}
 */
export class StationProductsApi extends BaseAPI {
    /**
     * API endpoint that allows manage StationProducts.
     * @param {StationProductsApiStationProductsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationProductsApi
     */
    public stationProductsCreate(requestParameters: StationProductsApiStationProductsCreateRequest, options?: AxiosRequestConfig) {
        return StationProductsApiFp(this.configuration).stationProductsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows manage StationProducts.
     * @param {StationProductsApiStationProductsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationProductsApi
     */
    public stationProductsDelete(requestParameters: StationProductsApiStationProductsDeleteRequest, options?: AxiosRequestConfig) {
        return StationProductsApiFp(this.configuration).stationProductsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all station_products from Station
     * @param {StationProductsApiStationProductsGetStationProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationProductsApi
     */
    public stationProductsGetStationProducts(requestParameters: StationProductsApiStationProductsGetStationProductsRequest, options?: AxiosRequestConfig) {
        return StationProductsApiFp(this.configuration).stationProductsGetStationProducts(requestParameters.stationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows manage StationProducts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationProductsApi
     */
    public stationProductsList(options?: AxiosRequestConfig) {
        return StationProductsApiFp(this.configuration).stationProductsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows manage StationProducts.
     * @param {StationProductsApiStationProductsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationProductsApi
     */
    public stationProductsPartialUpdate(requestParameters: StationProductsApiStationProductsPartialUpdateRequest, options?: AxiosRequestConfig) {
        return StationProductsApiFp(this.configuration).stationProductsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows manage StationProducts.
     * @param {StationProductsApiStationProductsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationProductsApi
     */
    public stationProductsRead(requestParameters: StationProductsApiStationProductsReadRequest, options?: AxiosRequestConfig) {
        return StationProductsApiFp(this.configuration).stationProductsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows manage StationProducts.
     * @param {StationProductsApiStationProductsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationProductsApi
     */
    public stationProductsUpdate(requestParameters: StationProductsApiStationProductsUpdateRequest, options?: AxiosRequestConfig) {
        return StationProductsApiFp(this.configuration).stationProductsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
