import { bookingConstants, constants } from '../constants';

const initialState = {
  captcha: { validated: false },
  isLoading: false,
  error: '',
  hasError: false,
  success: false,
};

export function captcha(state = initialState, action) {
  switch (action.type) {
    case bookingConstants.VALIDATE_CAPTCHA_REQUEST:
      return initialState;
    case bookingConstants.VALIDATE_CAPTCHA_SUCCESS: {
      return {
        ...state,
        captcha: action.captcha,
        isLoading: false,
        error: '',
        hasError: false,
      };
    }
    case bookingConstants.VALIDATE_CAPTCHA_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isLoading: false,
      };
    case constants.RESET_ERROR_STATE:
      return {
        ...state,
        error: '',
        hasError: false,
        success: false,
      };
    default:
      return state;
  }
}
