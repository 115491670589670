import { combineReducers } from "redux";
import { station } from "./station";
import { auth } from "./auth";
import { booking } from "./booking";
import { block } from "./block";
import { vehicle } from "./vehicle";
import { products } from "./products";
import { wizard } from "./wizard";
import { captcha } from "./captcha";
import { promotionalCode } from "./promotionalCode";
import { ticket } from "./ticket";

const rootReducer = combineReducers({
  station,
  booking,
  auth,
  block,
  vehicle,
  products,
  wizard,
  captcha,
  promotionalCode,
  ticket,
});

export default rootReducer;
