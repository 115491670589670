import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import "./SidebarButtons.scss";
import Button from "./Button";
import ChildrenButton from "./ChildrenButton";

const SidebarButtons = ({
  element,
  path,
  setChildActive,
  childActive,
  itemActive,
  setItemActive,
}) => {
  const [openChildren, setOpenChildren] = useState(false);

  const openChildrenMenu = useCallback(() => {
    setOpenChildren((prevState) => !prevState);
    setItemActive(element.key);
  }, [setOpenChildren, setItemActive, element]);

  useEffect(() => {
    if (element.children && itemActive !== element.key) {
      setOpenChildren(false);
      setChildActive(null);
    }
  }, [itemActive, element, setChildActive]);

  useEffect(() => {
    if (element.children) {
      const pathname = window.location.pathname.replace("/dashboard", "");
      const childElement = element.children.find(
        (child) => child.url === pathname
      );
      if (childElement) {
        openChildrenMenu();
        setChildActive(childElement.key);
      }
    }
  }, [element, openChildrenMenu, setChildActive]);

  return (
    <>
      {element.url ? (
        element.external ? (
          <div className="sidebar-button">
            <a href={element.url} target="_blank" rel="noopener noreferrer">
              {element.name}
            </a>
          </div>
        ) : (
          <Link to={`${path}${element.url}`}>
            <Button
              element={element}
              openChildrenMenu={openChildrenMenu}
              setItemActive={setItemActive}
              itemActive={itemActive}
            >
              {element.name}
            </Button>
          </Link>
        )
      ) : (
        <Button
          element={element}
          openChildrenMenu={openChildrenMenu}
          setItemActive={setItemActive}
          itemActive={itemActive}
        >
          {element.name}{" "}
          {element.children &&
            (itemActive === element.key ? (
              <IoIosArrowUp />
            ) : (
              <IoIosArrowDown />
            ))}
        </Button>
      )}

      {element.children &&
        openChildren &&
        itemActive === element.key &&
        element.children.map((child) => (
          <div key={child.key}>
            <Link to={`${path}${child.url}`}>
              <ChildrenButton
                child={child}
                setChildActive={setChildActive}
                childActive={childActive}
              >
                {child.name}{" "}
              </ChildrenButton>
            </Link>
          </div>
        ))}
    </>
  );
};

export default SidebarButtons;
