import React from "react";
import Header from "./components/Header";
import Schedule from "./components/Schedule";
import Features from "./components/Features";
import Steps from "./components/Steps"
import LubProducts from "./components/LubProducts";
import ShellExperience from "./components/ShellExperience";
import FindLub from "./components/FindLub";
import ScheduleButton from "./components/ScheduleButton";
import KnowOilPrice from "./components/KnowOilPrice";
// import Services from "./components/Services";
import WelcomeModal from "../WelcomeModal/WelcomeModal";
import OilFAQs from "./components/OilFAQs";

import "./styles.scss";

const Landing = () => {
  return (
    <div className="landing">
      <Header />
      <div className="landing-content">
        <ScheduleButton className="ml-auto" />
        <Schedule />
        <Features />
        <Steps />
        <LubProducts />
        <ShellExperience />
        <OilFAQs />
        {/* <FindLub />
        <KnowOilPrice /> */}
      </div>
      <WelcomeModal />
    </div>
  );
};

export default Landing;
