import * as React from 'react';
import './Booking.scss';
import {
  colors, Spacing, Text, TextField, Tooltip,
} from 'react-elemental';
import {
  Table, Thead, Tbody, Tr, Th, Td,
} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Pagination from 'react-js-pagination';
import {
  FiThumbsUp as ThumbsUp,
  FiEdit2 as Edit,
  FiFilter as Filter,
} from 'react-icons/fi';
import { BiCalendarCheck as Calendar } from 'react-icons/bi';
import { MdClear as ClearFilter } from 'react-icons/md';
import {
  IoIosArrowBack as ArrowLeft,
  IoIosArrowForward as ArrowRight,
  IoIosArrowRoundUp as SortArrowUp,
  IoIosArrowRoundDown as SortArrowDown,
  IoIosPerson as Person
} from 'react-icons/io';
import Moment from 'react-moment';
import OptionsDropdown from '../Commons/Dropdown';
import EditBookingModal from './EditBookingModal';
import DownloadReport from './DownloadReport';
import ReagendBooking from './ReagendBooking';
import DetailsClientModal from './DetailsClientModal';
import { showNotification } from '../../actions';
import { errorType } from '../../constants';

const ascFunction = (a, b) => new Date(a.date) - new Date(b.date);
const descFunction = (a, b) => new Date(b.date) - new Date(a.date);
const PAGE_SIZE = 10;
const SEARCH_LENGTH = 1

class Booking extends React.Component {
  constructor(props) {
    super(props);
    const previous = new Date();
    previous.setDate(previous.getDate()-30)
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate()+8)

    this.state = {
      isVisible: false,
      isReAgendVisible: false,
      isDetailClientVisible: false,
      selectedBooking: null,
      filterStr: '',
      activePage: 1,
      total: 0,
      isSortAsc: false,
      dates: {
        startDate: previous,
        endDate: tomorrow
      }
    };
    this.setVisibility = this.setVisibility.bind(this);
    this.setReAgendVisibility = this.setReAgendVisibility.bind(this);
    this.setDetailClientVisibility = this.setDetailClientVisibility.bind(this);
    this.editBooking = this.editBooking.bind(this);
    this.reAgendBooking = this.reAgendBooking.bind(this);
    this.viewClientDetail = this.viewClientDetail.bind(this);
    this.filteredBookings = this.filteredBookings.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.getBookingsByPage = this.getBookingsByPage.bind(this);
    this.updateDates = this.updateDates.bind(this);
  }

  editBooking(event, booking) {
    const target = event.target;

    const tagNames = ['TIME', 'TD', 'DIV'];
    const classNames = ['btn-edit-container'];

    if (target.id !== 'actions' && (tagNames.includes(target.tagName)
      || classNames.includes(target.className))) {
      this.setState({ selectedBooking: booking });
      this.setVisibility(true);
    }
  }

  reAgendBooking(booking) {
    this.setState({ selectedBooking: booking });
    this.setReAgendVisibility(true);
  }

  viewClientDetail(booking) {
    this.setState({ selectedBooking: booking.customer });
    this.setDetailClientVisibility(true);
  }

  setVisibility(isVisible) {
    this.setState({ isVisible });
  }

  setReAgendVisibility(isReAgendVisible) {
    this.setState({ isReAgendVisible });
  }

  setDetailClientVisibility(isDetailClientVisible) {
    this.setState({ isDetailClientVisible });
  }

  componentDidMount() {
    if (!this.props.auth.isLogged) this.props.history.push('/app');
    const query = {
      afterDate: this.state.dates.startDate?.toISOString().slice(0,10),
      station: this.props.auth.currentUser.station.id,
      ordering: this.state.isSortAsc ? 'date' : '-date',
    };
    this.props.list(query);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.booking.hasError) {
      showNotification(errorType.ERROR, nextProps.booking.error);
      this.props.resetNotification();
    }

    if (nextProps.booking.list) {
      this.setState({ total: nextProps.booking.total });
    }
  }

  handlePageChange(pageNumber) {
    const dates = this.state.dates;
    this.setState({ activePage: pageNumber }, () => {
      const query = {
        // search: search,
        afterDate: this.state.dates.startDate?.toISOString().slice(0,10),
        beforeDate: dates.endDate?.toISOString().slice(0,10),
        station: this.props.auth.currentUser.station.id,
        ordering: this.state.isSortAsc ? 'date' : '-date',
        page: pageNumber
      };
      if (this.state.filterStr.length > SEARCH_LENGTH) {
        query.search = this.state.filterStr
      }
      // debugger
      this.props.list(query);
    });
  }

  getBookingsByPage(list, page) {
    // const endIndex = page * PAGE_SIZE;
    // const startIndex = endIndex - PAGE_SIZE;
    // return list.slice(startIndex, endIndex);
    return list;
  }

  handleFilterChange(search=''){
    const is_update = search != this.state.filterStr && (this.state.filterStr.length > SEARCH_LENGTH || search.length > SEARCH_LENGTH);
    this.setState({ filterStr: search }, () => {
      // debugger
      if (is_update){
        this.handlePageChange(1);
      }
    })
  }

  filteredBookings() {
    let bookings = [];
    let activePage = this.state.activePage;
    const filter = this.state.filterStr.toLowerCase();
    if (this.state.filterStr === '') {
      bookings = this.props.booking.list;
    } else {
      bookings = this.props.booking.list.filter(
        (booking) => booking.date.toLowerCase().includes(filter)
          || booking.customer.name.toLowerCase().includes(filter)
          || booking.customer.email.toLowerCase().includes(filter)
          || booking.vehicle.patent.toLowerCase().includes(filter)
          || booking.vehicle.brand.toLowerCase().includes(filter)
          || booking.vehicle.model.toLowerCase().includes(filter)
          || booking.status.toLowerCase().includes(filter)
          || booking.vehicle.year.toLowerCase().includes(filter),
      );
      activePage = 1;
    }
    bookings = bookings.slice();
      // .sort(this.state.isSortAsc ? ascFunction : descFunction);
    return this.getBookingsByPage(bookings, activePage);
    // return bookings;
  }

  updateDates = (dates) => {
    this.setState({dates}, ()=>{
        this.handlePageChange(1);
    });
  }

  changeOrder = () => {
    console.log('changing order from date ', this.state.isSortAsc)
    this.setState({ isSortAsc: !this.state.isSortAsc },
        ()=> this.handlePageChange(1)
        );
  }

  render() {
    const bookings = this.props.booking.list;
    const { isVisible, filterStr, isReAgendVisible, isDetailClientVisible, dates} = this.state;
    return (
      <div className="mt-3">

        <div className="row">
          <div className="mr-auto col-md-8 col-xs-12 col-sm-12">
            <DownloadReport dates={dates} onChange={this.updateDates}/>
          </div>
          <div className="ml-auto col-md-3 col-xs-12 col-sm-12">
            <Spacing
              bottom
              className=""
            >
              <Filter className="filterIcon" />
              <TextField
                className="padding-left-35 rounded-pill"
                placeholder="Filtrar"
                value={ filterStr }
                onChange={ (e) => this.handleFilterChange(e.target.value) }
              />
              {
                filterStr
                && (
                  <ClearFilter
                    color={ colors.gray45 }
                    className="clear-filter"
                    onClick={ () => this.handleFilterChange('') }
                  />
                )
              }
            </Spacing>
          </div>
        </div>

        <Spacing
          size="micro"
          bottom
        >
          {
            bookings.length > 0
              ? (
                <div className="table-container">
                  <Table
                    id="booking-table"
                    className="table table-hover"
                  >
                    <Thead>
                      <Tr>
                        <Th>
                          <span className="d-inline-flex">
                            <span>Fecha</span>
                            <Tooltip
                              bottom
                              width="160px"
                              element="span"
                              contents={ (
                                <Text
                                  color="gray10"
                                  size="lambda"
                                  bottom
                                  className="mb-0"
                                >
                                  { this.state.isSortAsc
                                    ? 'Cambiar a orden descendente'
                                    : 'Cambiar a orden ascendente' }
                                </Text>
                                          ) }
                            >
                              <span
                                className="sort-icon"
                                onClick={ this.changeOrder }
                              >
                                {
                                  this.state.isSortAsc
                                    ? <SortArrowUp />
                                    : <SortArrowDown />
                                }
                              </span>
                            </Tooltip>
                          </span>

                        </Th>
                        <Th>Estado</Th>
                        <Th>Patente</Th>
                        <Th>Marca</Th>
                        <Th>Modelo</Th>
                        <Th>Año</Th>
                        <Th>Combustible</Th>
                        {/* <Th>Kilometraje</Th> */ }
                        <Th>Cliente</Th>
                        <Th>Código promocional</Th>
                        <Th>Acciones</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      { bookings.map((booking) => (
                        <Tr
                          key={ booking.id }
                          onClick={ (event) => {
                            this.editBooking(event, booking);
                          } }
                        >
                          <Td>
                            <div>
                              <Moment
                                format="DD-MM-YYYY"
                              >
                                { booking.date }
                              </Moment>
                            </div>
                            <div>
                              <Moment format="HH:mm">{ booking.date }</Moment>
                            </div>
                          </Td>
                          <Td>
                            { booking.status === 'activated'
                            && (
                              <div className="tag activated">
                                Activa
                              </div>
                            ) }

                            { booking.status === 'cancelled'
                            && (
                              <div className="tag cancelled">
                                Cancelada
                              </div>
                            ) }

                            { booking.status === 'expired'
                            && (
                              <div className="tag expired">
                                Caducada
                              </div>
                            ) }

                            { booking.status === 'done'
                            && (
                              <div className="tag done">
                                Realizada
                              </div>
                            ) }
                          </Td>
                          <Td>{ booking.vehicle.patent }</Td>
                          <Td>{ booking.vehicle.brand }</Td>
                          <Td>{ booking.vehicle.model }</Td>
                          <Td>{ booking.vehicle.year }</Td>
                          <Td className="text-capitalize">
                            { booking.vehicle.fuel_type
                          === 'gasoline' ? 'Bencina' : booking.vehicle.fuel_type }
                          </Td>
                          {/* <Td>{booking.mileage}</Td> */ }
                          <Td>{ booking.customer.name }</Td>
                          <Td>{ booking.prom_code ? booking.prom_code : '-' }</Td>
                          <Td id="actions">
                            <OptionsDropdown>
                              <span
                                color={ colors.green }
                                className="btn-reagend-container"
                                onClick={ () => {
                                  this.reAgendBooking(booking);
                                } }
                              >
                                <Calendar size="1.5em" />
                                Re-agendar
                              </span>

                              <span
                                color={ colors.green }
                                className="btn-edit-container"
                                onClick={ (event) => {
                                  this.editBooking(event, booking);
                                } }
                              >
                                <Edit />
                                Editar
                              </span>
                              <span 
                                color={ colors.green }
                                className="btn-client-detail-container"
                                onClick={ () => {
                                  this.viewClientDetail(booking);
                                  }
                                }
                              >
                                <Person />
                                Cliente
                              </span>
                            </OptionsDropdown>
                          </Td>
                        </Tr>
                      )) }
                    </Tbody>
                  </Table>

                  <div className="pagination-container text-center mt-5">
                    <Pagination
                      activePage={ this.state.activePage }
                      itemsCountPerPage={ PAGE_SIZE }
                      totalItemsCount={ this.state.total }
                      pageRangeDisplayed={ 5 }
                      prevPageText="Anterior"
                      nextPageText="Siguiente"
                      lastPageText={ <ArrowRight /> }
                      firstPageText={ <ArrowLeft /> }
                      onChange={ this.handlePageChange.bind(this) }
                    />
                  </div>

                </div>
              )
              : (
                <div>
                  <Text
                    size="epsilon"
                    className="text-center"
                    color={ colors.gray45 }
                    bold
                  >
                    No existen reservas registradas en esta sucursal
                  </Text>
                  <Text
                    size="beta"
                    className="text-center"
                    color={ colors.gray45 }
                    bold
                  >
                    <ThumbsUp />
                  </Text>
                </div>
              )
          }
        </Spacing>
        { isVisible && (
          <EditBookingModal
            booking={ this.state.selectedBooking }
            setVisibility={ this.setVisibility }
            editBookig={ this.props.edit }
            getStationProducts={ this.props.getStationProducts }
            stationProducts={ this.props.stationProducts }
            createBookingStationProduct={ this.props.createBookingStationProduct }
          />
        ) }

        { isReAgendVisible && (
          <ReagendBooking
            booking={ this.state.selectedBooking }
            setVisibility={ this.setReAgendVisibility }
          />
        ) }

        { isDetailClientVisible && (
          <DetailsClientModal
          name={this.state.selectedBooking.name}
          phone={this.state.selectedBooking.phone}
          email={this.state.selectedBooking.email}
          setVisibility={this.setDetailClientVisibility}
          />
        )}
      </div>
    );
  }
}

export default Booking;
