export const firstColumnCheckboxValues = [
  {
    key: "first-1",
    name: "Nivel de aceite",
    attribute: "oilLevel",
  },
  {
    key: "first-2",
    name: "Nivel de líquido de frenos",
    attribute: "brakeLiquidLevel",
  },
  {
    key: "first-3",
    name: "Nivel de líquido de dirección",
    attribute: "steeringLiquidLevel",
  },
  {
    key: "first-4",
    name: "Nivel de líquido refrigerante",
    attribute: "coolantLiquidLevel",
  },
];

export const secondColumnCheckboxValues = [
  {
    key: "second-1",
    name: "Nivel de agua limpia parabrisas",
    attribute: "wiperWasherWaterLevel",
  },
  {
    key: "second-2",
    name: "Estado de luces",
    attribute: "headlightsStatus",
  },
  {
    key: "second-3",
    name: "Estado de plumillas",
    attribute: "wiperWasherStatus",
  },
];

export const spanishFuelValues = {
  Gasoline: "Gasolina",
  Diesel: "Diesel",
};
