import React, { useEffect, useState } from "react";

const GenericCreate = ({
  createItem,
  setShowCreate,
  reloadTable,
  FormElement,
  formData,
  setFormData,
  title,
  formTitles,
}) => {
  const [responseCode, setResponseCode] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (responseCode === 201) {
      reloadTable();
      setShowCreate(false);
    }
  }, [reloadTable, setShowCreate, responseCode]);
  return (
    <>
      <div className="row">
        <div className="col-12">
          <h5 className="red-text">Crear {title}</h5>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <FormElement
            setFormData={setFormData}
            formTitles={formTitles}
            formData={formData}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <button
            className="btn btn-primary rounded-pill my-3"
            onClick={() => {
              setError(null);
              const emptyFields = Object.keys(formData)
                .filter(
                  (field) =>
                    formData[field] === "" &&
                    formTitles[field].required === true
                )
                .map((field) => formTitles[field].title);

              if (emptyFields.length === 0) {
                createItem().then((response) =>
                  setResponseCode(response.status)
                );
              } else {
                setError(emptyFields.join(", "));
              }
            }}
          >
            Crear
          </button>
        </div>
      </div>
      {error && (
        <div className="row my-3">
          <div className="col-12 red-text">
            Debe llenar los siguientes campos: {error}.
          </div>
        </div>
      )}
    </>
  );
};

export default GenericCreate;
