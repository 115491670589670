/* tslint:disable */
/* eslint-disable */
/**
 * Enex API
 * API to manage bookings for oil change with Enex
 *
 * The version of the OpenAPI document: v1
 * Contact: jliznaga@linets.cl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Booking } from '../models';
// @ts-ignore
import { BookingsList200Response } from '../models';
/**
 * BookingsApi - axios parameter creator
 * @export
 */
export const BookingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint that allows bookings to be viewed or edited.
         * @param {Booking} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsCreate: async (data: Booking, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('bookingsCreate', 'data', data)
            const localVarPath = `/bookings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows bookings to be viewed or edited.
         * @param {number} id A unique integer value identifying this Reserva.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingsDelete', 'id', id)
            const localVarPath = `/bookings/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows bookings to be viewed or edited.
         * @param {string} [secretKey] 
         * @param {string} [date] 
         * @param {string} [isGhost] 
         * @param {number} [station] 
         * @param {string} [status] 
         * @param {string} [afterDate] 
         * @param {string} [beforeDate] 
         * @param {string} [afterCreated] 
         * @param {string} [beforeCreated] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] Which field to use when search query.
         * @param {number} [page] Inform the page. Starting with 1. Default: 1
         * @param {number} [pageSize] Limit per page, Default: 8.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsList: async (secretKey?: string, date?: string, isGhost?: string, station?: number, status?: string, afterDate?: string, beforeDate?: string, afterCreated?: string, beforeCreated?: string, ordering?: string, search?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bookings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (secretKey !== undefined) {
                localVarQueryParameter['secret_key'] = secretKey;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (isGhost !== undefined) {
                localVarQueryParameter['is_ghost'] = isGhost;
            }

            if (station !== undefined) {
                localVarQueryParameter['station'] = station;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (afterDate !== undefined) {
                localVarQueryParameter['after_date'] = afterDate;
            }

            if (beforeDate !== undefined) {
                localVarQueryParameter['before_date'] = beforeDate;
            }

            if (afterCreated !== undefined) {
                localVarQueryParameter['after_created'] = afterCreated;
            }

            if (beforeCreated !== undefined) {
                localVarQueryParameter['before_created'] = beforeCreated;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows bookings to be viewed or edited.
         * @param {number} id A unique integer value identifying this Reserva.
         * @param {Booking} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsPartialUpdate: async (id: number, data: Booking, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('bookingsPartialUpdate', 'data', data)
            const localVarPath = `/bookings/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows bookings to be viewed or edited.
         * @param {number} id A unique integer value identifying this Reserva.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingsRead', 'id', id)
            const localVarPath = `/bookings/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint that allows bookings to be viewed or edited.
         * @param {number} id A unique integer value identifying this Reserva.
         * @param {Booking} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsUpdate: async (id: number, data: Booking, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('bookingsUpdate', 'data', data)
            const localVarPath = `/bookings/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingsApi - functional programming interface
 * @export
 */
export const BookingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingsApiAxiosParamCreator(configuration)
    return {
        /**
         * API endpoint that allows bookings to be viewed or edited.
         * @param {Booking} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsCreate(data: Booking, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API endpoint that allows bookings to be viewed or edited.
         * @param {number} id A unique integer value identifying this Reserva.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API endpoint that allows bookings to be viewed or edited.
         * @param {string} [secretKey] 
         * @param {string} [date] 
         * @param {string} [isGhost] 
         * @param {number} [station] 
         * @param {string} [status] 
         * @param {string} [afterDate] 
         * @param {string} [beforeDate] 
         * @param {string} [afterCreated] 
         * @param {string} [beforeCreated] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] Which field to use when search query.
         * @param {number} [page] Inform the page. Starting with 1. Default: 1
         * @param {number} [pageSize] Limit per page, Default: 8.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsList(secretKey?: string, date?: string, isGhost?: string, station?: number, status?: string, afterDate?: string, beforeDate?: string, afterCreated?: string, beforeCreated?: string, ordering?: string, search?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsList(secretKey, date, isGhost, station, status, afterDate, beforeDate, afterCreated, beforeCreated, ordering, search, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API endpoint that allows bookings to be viewed or edited.
         * @param {number} id A unique integer value identifying this Reserva.
         * @param {Booking} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsPartialUpdate(id: number, data: Booking, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API endpoint that allows bookings to be viewed or edited.
         * @param {number} id A unique integer value identifying this Reserva.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API endpoint that allows bookings to be viewed or edited.
         * @param {number} id A unique integer value identifying this Reserva.
         * @param {Booking} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsUpdate(id: number, data: Booking, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookingsApi - factory interface
 * @export
 */
export const BookingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingsApiFp(configuration)
    return {
        /**
         * API endpoint that allows bookings to be viewed or edited.
         * @param {Booking} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsCreate(data: Booking, options?: any): AxiosPromise<Booking> {
            return localVarFp.bookingsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows bookings to be viewed or edited.
         * @param {number} id A unique integer value identifying this Reserva.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.bookingsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows bookings to be viewed or edited.
         * @param {string} [secretKey] 
         * @param {string} [date] 
         * @param {string} [isGhost] 
         * @param {number} [station] 
         * @param {string} [status] 
         * @param {string} [afterDate] 
         * @param {string} [beforeDate] 
         * @param {string} [afterCreated] 
         * @param {string} [beforeCreated] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] Inform the page. Starting with 1. Default: 1
         * @param {number} [pageSize] Limit per page, Default: 8.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsList(secretKey?: string, date?: string, isGhost?: string, station?: number, status?: string, afterDate?: string, beforeDate?: string, afterCreated?: string, beforeCreated?: string, ordering?: string, search?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<BookingsList200Response> {
            return localVarFp.bookingsList(secretKey, date, isGhost, station, status, afterDate, beforeDate, afterCreated, beforeCreated, ordering, search, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows bookings to be viewed or edited.
         * @param {number} id A unique integer value identifying this Reserva.
         * @param {Booking} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsPartialUpdate(id: number, data: Booking, options?: any): AxiosPromise<Booking> {
            return localVarFp.bookingsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows bookings to be viewed or edited.
         * @param {number} id A unique integer value identifying this Reserva.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsRead(id: number, options?: any): AxiosPromise<Booking> {
            return localVarFp.bookingsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API endpoint that allows bookings to be viewed or edited.
         * @param {number} id A unique integer value identifying this Reserva.
         * @param {Booking} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsUpdate(id: number, data: Booking, options?: any): AxiosPromise<Booking> {
            return localVarFp.bookingsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for bookingsCreate operation in BookingsApi.
 * @export
 * @interface BookingsApiBookingsCreateRequest
 */
export interface BookingsApiBookingsCreateRequest {
    /**
     * 
     * @type {Booking}
     * @memberof BookingsApiBookingsCreate
     */
    readonly data: Booking
}

/**
 * Request parameters for bookingsDelete operation in BookingsApi.
 * @export
 * @interface BookingsApiBookingsDeleteRequest
 */
export interface BookingsApiBookingsDeleteRequest {
    /**
     * A unique integer value identifying this Reserva.
     * @type {number}
     * @memberof BookingsApiBookingsDelete
     */
    readonly id: number
}

/**
 * Request parameters for bookingsList operation in BookingsApi.
 * @export
 * @interface BookingsApiBookingsListRequest
 */
export interface BookingsApiBookingsListRequest {
    /**
     * 
     * @type {string}
     * @memberof BookingsApiBookingsList
     */
    readonly secretKey?: string

    /**
     * 
     * @type {string}
     * @memberof BookingsApiBookingsList
     */
    readonly date?: string

    /**
     * 
     * @type {string}
     * @memberof BookingsApiBookingsList
     */
    readonly isGhost?: string

    /**
     * 
     * @type {number}
     * @memberof BookingsApiBookingsList
     */
    readonly station?: number

    /**
     * 
     * @type {string}
     * @memberof BookingsApiBookingsList
     */
    readonly status?: string

    /**
     * 
     * @type {string}
     * @memberof BookingsApiBookingsList
     */
    readonly afterDate?: string

    /**
     * 
     * @type {string}
     * @memberof BookingsApiBookingsList
     */
    readonly beforeDate?: string

    /**
     * 
     * @type {string}
     * @memberof BookingsApiBookingsList
     */
    readonly afterCreated?: string

    /**
     * 
     * @type {string}
     * @memberof BookingsApiBookingsList
     */
    readonly beforeCreated?: string

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof BookingsApiBookingsList
     */
    readonly ordering?: string

    /**
     * Which field to use when search query.
     * @type {string}
     * @memberof BookingsApiBookingsList
     */
    readonly search?: string

    /**
     * Inform the page. Starting with 1. Default: 1
     * @type {number}
     * @memberof BookingsApiBookingsList
     */
    readonly page?: number

    /**
     * Limit per page, Default: 8.
     * @type {number}
     * @memberof BookingsApiBookingsList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for bookingsPartialUpdate operation in BookingsApi.
 * @export
 * @interface BookingsApiBookingsPartialUpdateRequest
 */
export interface BookingsApiBookingsPartialUpdateRequest {
    /**
     * A unique integer value identifying this Reserva.
     * @type {number}
     * @memberof BookingsApiBookingsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Booking}
     * @memberof BookingsApiBookingsPartialUpdate
     */
    readonly data: Booking
}

/**
 * Request parameters for bookingsRead operation in BookingsApi.
 * @export
 * @interface BookingsApiBookingsReadRequest
 */
export interface BookingsApiBookingsReadRequest {
    /**
     * A unique integer value identifying this Reserva.
     * @type {number}
     * @memberof BookingsApiBookingsRead
     */
    readonly id: number
}

/**
 * Request parameters for bookingsUpdate operation in BookingsApi.
 * @export
 * @interface BookingsApiBookingsUpdateRequest
 */
export interface BookingsApiBookingsUpdateRequest {
    /**
     * A unique integer value identifying this Reserva.
     * @type {number}
     * @memberof BookingsApiBookingsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Booking}
     * @memberof BookingsApiBookingsUpdate
     */
    readonly data: Booking
}

/**
 * BookingsApi - object-oriented interface
 * @export
 * @class BookingsApi
 * @extends {BaseAPI}
 */
export class BookingsApi extends BaseAPI {
    /**
     * API endpoint that allows bookings to be viewed or edited.
     * @param {BookingsApiBookingsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsCreate(requestParameters: BookingsApiBookingsCreateRequest, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).bookingsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows bookings to be viewed or edited.
     * @param {BookingsApiBookingsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsDelete(requestParameters: BookingsApiBookingsDeleteRequest, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).bookingsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows bookings to be viewed or edited.
     * @param {BookingsApiBookingsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsList(requestParameters: BookingsApiBookingsListRequest = {}, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).bookingsList(requestParameters.secretKey, requestParameters.date, requestParameters.isGhost, requestParameters.station, requestParameters.status, requestParameters.afterDate, requestParameters.beforeDate, requestParameters.afterCreated, requestParameters.beforeCreated, requestParameters.ordering, requestParameters.search, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows bookings to be viewed or edited.
     * @param {BookingsApiBookingsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsPartialUpdate(requestParameters: BookingsApiBookingsPartialUpdateRequest, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).bookingsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows bookings to be viewed or edited.
     * @param {BookingsApiBookingsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsRead(requestParameters: BookingsApiBookingsReadRequest, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).bookingsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint that allows bookings to be viewed or edited.
     * @param {BookingsApiBookingsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsUpdate(requestParameters: BookingsApiBookingsUpdateRequest, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).bookingsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
