import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiPlusCircle as Plus } from 'react-icons/fi';
import { IoMdHelpCircle as Help } from 'react-icons/io';
import { AiOutlineMinusCircle as Minus } from 'react-icons/ai';
import { RiExchangeDollarLine as Change } from 'react-icons/ri';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import StationProductModal from './components/StationProductModal';
import ConfirmModal from '../Commons/ConfirmModal';
import {
  getProducts,
  createStationProduct,
  getStationProducts,
  editStationProducts,
  removeStationProducts,
} from '../../actions/products';
import './style.scss';

const Products = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showSPModal, setShowSPModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedStationProduct, setSelectedStationProduct] = useState(null);
  const state = useSelector((s) => ({
    productList: s.products.products,
    stationId: s.auth.currentUser.station.id,
    stationProductList: s.products.stationProducts,
  }));
  const [products, setProducts] = useState(state.productList);
  const [stationProducts, setStationProducts] = useState(
    state.stationProductList,
  );

  useEffect(() => {
    dispatch(getProducts());
    dispatch(getStationProducts());
  }, [dispatch]);

  useEffect(() => {
    setProducts(state.productList);
  }, [state.productList]);

  useEffect(() => {
    setStationProducts(state.stationProductList);
  }, [state.stationProductList]);

  const saveStationProduct = (stationPrice) => {
    const data = {
      station: state.stationId,
      product: selectedProduct.id,
      price: stationPrice,
    };

    dispatch(createStationProduct(data));
  };

  const editStationProductPrice = (id, stationPrice) => {
    const data = {
      station: state.stationId,
      product: selectedStationProduct.product.id,
      price: stationPrice,
    };
    dispatch(editStationProducts(id, data));
  };

  const isAssigned = (productId) => stationProducts.findIndex((sp) => sp.product.id === productId) > -1;

  return (
    <div className="row">
      <div className="col-md-6 col-xs-12 col-sm-12">
        <h3 className="custom-title black mt-5">
          Listado general de
          productos
        </h3>
        <div className="mb-5 text-muted margin-top--5">
          Selecciona los productos a utilizar en la sucursal
          <OverlayTrigger
            placement="right"
            overlay={ (
              <Tooltip id="tooltip-help">
                Adiciona a esta sucursal los productos a utilizar teniendo en
                cuenta el precio sugerido.
              </Tooltip>
            ) }
          >
            <Help
              className="ml-2"
              color="#919191"
              size="1.2em"
            />
          </OverlayTrigger>
        </div>

        <div className="mt-5">
          { products.length > 0
            ? products.map((p) => (
              <div className={ `product-item text-muted ${isAssigned(p.id)
                ? 'assigned'
                : ''}` }
              >
                <span>
                  { p.name }
                  &nbsp;
                  { p.code }
                </span>
                { !isAssigned(p.id)
                && (
                <span
                  className="p-action-icon float-right ml-5"
                  onClick={ () => {
                    setSelectedProduct(p);
                    setShow(true);
                  } }
                >
                  <Plus
                    color="#e2422e"
                    size="1.2em"
                  />
                </span>
                ) }
                <span
                  className="p-sugessted-price float-right"
                >
                  { `${p.suggested_price} CLP` }
                </span>
              </div>
            ))

            : (
              <div className="text-muted">
                No existen productos para asociar a la
                sucursal
              </div>
            )}
          { selectedProduct !== null && (
          <StationProductModal
            show={ show }
            onHandleClose={ () => {
              setShow(false);
            } }
            onHandleChangePrice={ (p) => {
              saveStationProduct(
                p,
              );
            } }
            defaultPrice={ selectedProduct.suggested_price }
          />
          ) }

        </div>
      </div>

      <div className="col-md-6 col-xs-12 col-sm-12">
        <h3 className="custom-title black mt-5">Productos de esta sucursal</h3>
        <div className="mb-5 text-muted margin-top--5">
          Elimina de la lista los
          productos que no vas a utilizar
        </div>

        <div className="mt-5">
          { stationProducts.length > 0
            ? stationProducts.map((sp) => (
              <div className="product-item text-muted">
                <span>
                  { sp.product.name }
                  &nbsp;
                  { sp.product.code }
                </span>
                <span
                  className="p-action-icon float-right ml-3"
                  onClick={ () => {
                    setSelectedStationProduct(sp);
                    setShowDeleteConfirm(true);
                  } }
                >
                  <Minus
                    color="#e2422e"
                    size="1.2em"
                  />
                </span>
                <span
                  className="p-action-icon float-right ml-5"
                  onClick={ () => {
                    setSelectedStationProduct(sp);
                    setShowSPModal(true);
                  } }
                >
                  <Change
                    color="#e2422e"
                    size="1.3em"
                  />
                </span>
                <span
                  className="p-sugessted-price float-right"
                >
                  { `${sp.price} CLP` }
                </span>
              </div>
            ))

            : (
              <div className="text-muted">
                No existen productos asignados a esta
                sucursal
              </div>
            )}
          { selectedStationProduct !== null
          && (
          <StationProductModal
            show={ showSPModal }
            onHandleClose={ () => {
              setShowSPModal(false);
            } }
            onHandleChangePrice={ (price) => {
              editStationProductPrice(selectedStationProduct.id, price);
            } }
            defaultPrice={ selectedStationProduct.price }
          />
          ) }
          { selectedStationProduct !== null
          && (
          <ConfirmModal
            show={ showDeleteConfirm }
            onHandleClose={ () => { setShowDeleteConfirm(false); } }
            onHandleSubmit={ () => {
              dispatch(
                removeStationProducts(selectedStationProduct.id),
              );
            } }
            message="¿Desea quitar este producto de la lista?"
          />
          ) }
        </div>

      </div>
    </div>
  );
};

Products.propTypes = {};

Products.defaultProps = {};

export default Products;
