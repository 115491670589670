/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import { Checkbox } from 'react-elemental';
import Select from 'react-select';
import { serviceFactory } from '../../../../../utils/helpers';
import { ServicesApi, StationProductsApi } from '../../../../../services/api';

const servicesApi = serviceFactory(ServicesApi);
const stationProductsApi = serviceFactory(StationProductsApi);

const ThirdStep = ({ setNewOrderData, newOrderData, setFormStep }) => {
  const [services, setServices] = useState([]);
  const [stationProducts, setStationProducts] = useState([]);
  const [flagOil, setFlagOil] = useState(false);
  const [products, setProducts] = useState([]);

  const servicesValue = newOrderData.services;

  useEffect(() => {
    servicesApi.servicesList().then((response) => setServices(response.data));
    stationProductsApi.stationProductsList().then((response) => setStationProducts(response.data));
  }, []);

  const getService = (name) => {
    return servicesValue.find((e) => e.service.name === name);
  };
  const getServiceCheked = (name) => {
    return servicesValue.find((e) => e.service.name === name && e.changed);
  };

  const isAdded = (stationProductId) => {
    return (
      products.findIndex(
        (p) => p.station_product === stationProductId,
      ) > -1
    );
  };

  const removeProduct = (stationProductId) => {
    const tempProducts = [].concat(products);
    const index = tempProducts.findIndex(
      (p) => p.station_product === stationProductId,
    );
    if (index > -1) {
      tempProducts.splice(index, 1);
      setProducts(tempProducts);
    }
  };

  const addProduct = (stationProductId) => {
    const product = {
      booking: null,
      station_product: stationProductId,
      quantity: 0,
    };
    setProducts((prev) => prev.concat([product]));
  };

  const onChangeAddedProduct = (id) => {
    return isAdded(id) ? removeProduct(id) : addProduct(id);
  };

  const disabledCheckbox = (id) => {
    return products.length > 0 && products.findIndex((p) => p.station_product === id) === -1;
  };

  const isValidQuantity = (quantity) => {
    const re = /^[0-9\b]+$/;
    return quantity === '' || quantity === null || re.test(quantity)
      ? ''
      : 'Solo debe entrar números';
  };

  const changeQuantity = (stationProductId, quantity) => {
    if (isValidQuantity(quantity) === '') {
      const tempProducts = [].concat(products);
      const index = tempProducts.findIndex(
        (p) => p.station_product === stationProductId,
      );
      if (index > -1) {
        tempProducts[index] = { ...tempProducts[index], quantity };
        setProducts(tempProducts);
      }
    }
  };

  const canContinueOilCheck = () => {
    return flagOil && products.filter((p) => p.quantity > 0).length === 0;
  };

  return (
    <>
      <div className="row new-order-checkbox-list">
        <div className="col-12">
          <div className="row">
            <div className="col-8 red-text font-weight-bold">
              <h4>Servicio</h4>
            </div>
            <div className="col-2 red-text font-weight-bold">
              <h4>Revisión</h4>
            </div>
            <div className="col-2 red-text font-weight-bold">
              <h4>Cambio</h4>
            </div>
          </div>
          {services.map((element, i) => (
            <div className="row my-4" key={`${element.name}-${i}`}>
              <div className="col-8">{element.name}</div>
              <div className="col-2">
                <input
                  type="checkbox"
                  className="new-order-checkboxes"
                  checked={ getService(element.name) }
                  disabled={ getServiceCheked(element.name) }
                  onChange={ (e) => {
                    e.persist();
                    setNewOrderData((prevState) => {
                      if (e.target.checked) {
                        const index = prevState.services.findIndex(
                          (el) => el.service.name === element.name,
                        );
                        if (index === -1) {
                          return {
                            ...prevState,
                            services: [
                              ...prevState.services,
                              {
                                service: { name: element.name },
                                changed: false,
                              },
                            ],
                          };
                        }
                      } else {
                        const index = prevState.services.findIndex(
                          (el) => el.service.name === element.name && !el.changed,
                        );
                        if (index > -1) {
                          const removedObject = prevState.services.filter(
                            (item) => item.service.name !== element.name,
                          );
                          return {
                            ...prevState,
                            services: removedObject,
                          };
                        }
                      }
                    });
                  } }
                />
              </div>
              <div className="col-2">
                <input
                  type="checkbox"
                  className="new-order-checkboxes"
                  checked={ getServiceCheked(element.name) }
                  onChange={ (e) => {
                    e.persist();
                    setNewOrderData((prevState) => {
                      /* Start if e.target.checked */
                      if (e.target.checked) {
                        if (element.name === 'Nivel de aceite') {
                          setFlagOil(true);
                        }

                        const index = prevState.services.findIndex((el) => {
                          return el.service.name === element.name;
                        });

                        /* Start if index */
                        if (index === -1) {
                          return {
                            ...prevState,
                            services: [
                              ...prevState.services,
                              {
                                service: { name: element.name },
                                changed: true,
                              },
                            ],
                          };
                        }
                        /* End if index */

                        const updateServices = prevState.services.map((serv) => {
                          if (serv.service.name === element.name) {
                            return {
                              service: { name: element.name },
                              changed: true,
                            };
                          }
                          return serv;
                        });
                        return {
                          ...prevState,
                          services: [...updateServices],
                        };
                      }
                      /* End if e.target.checked */
                      if (element.name === 'Nivel de aceite') {
                        setFlagOil(false);
                      }
                      const updateServices = prevState.services.filter((serv) => {
                        return serv.service.name !== element.name;
                      });
                      return {
                        ...prevState,
                        services: [...updateServices],
                      };
                    });
                  } }
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="row mt-3">
        { flagOil && (
        <div className="col-8">
          <div className="row">
            <div className="col-8 red-text font-weight-bold">
              <h4>Productos Utilizados</h4>
            </div>
            <div className="col-12">
              {stationProducts.map((sp) => (
                <div className="mb-2 row" key={`sp_${sp.id}`}>
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <Checkbox
                      checked={ isAdded(sp.id) }
                      onChange={ () => {
                        onChangeAddedProduct(sp.id);
                      } }
                      disabled={ disabledCheckbox(sp.id) }
                    />
                    <span
                      className="ml-2 text-muted"
                      onClick={ () => !disabledCheckbox(sp.id) && onChangeAddedProduct(sp.id) }
                    >
                      {sp.product.name} {sp.product.code}
                    </span>
                  </div>
                  {isAdded(sp.id) && (
                    <div className="col-md-6 col-sm-12 col-xs-12">
                      <Select
                        className="rounded-pill thin-input"
                        // styles={customThinSelect}
                        placeholder="Cantidad (Litros)"
                        onChange={ (item) => {
                          changeQuantity(sp.id, item.value);
                        } }
                        noOptionsMessage={ () => 'No hay datos disponibles' }
                        options={ Array.from(
                          { length: 10 }, (_, i) => i + 1,
                        ).map((n) => ({ label: `${n} Litros`, value: n })) }
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        )}
        <div className="col-6">
          <div>
            <button
              type="button"
              className="btn new-order-button font-weight-bold"
              onClick={ () => setFormStep((prevState) => --prevState) }
            >
              Atrás
            </button>
          </div>
        </div>
        <div className="col-6 text-right">
          <button
            type="button"
            className="btn new-order-button font-weight-bold"
            onClick={ () => {
              setNewOrderData((prev) => {
                return { ...prev, products };
              });
              setFormStep((prevState) => ++prevState);
            } }
            disabled={ newOrderData.services.length === 0 || canContinueOilCheck() }
          >
            Siguiente
          </button>
        </div>
      </div>
    </>
  );
};

export default ThirdStep;
