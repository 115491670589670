import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { featuresList, responsive } from './helpers';
import SectionRow from '../SectionRow';
import './styles.scss';
import NextButton from '../NextButton';

const Features = () => {

  // const allIconsElement = [...featuresList[0], ...featuresList[1]]

  const IconCol = ({ imgPath, title }) => {
    return (
      <div className="col-6 col-md-3 ">
        <div className="icon-box">
          <img src={imgPath} className="icon-img" alt={title} />
        </div>
        <div className="col-9 mx-auto"><p className="gray-text">{title}</p></div>
      </div>
    );
  }

  return (
    <>
      <div className="container generic-padding landing-scroll" id='features'>
        <SectionRow>
          <div className="col">
            <h2 className="gray-text">¿Qué incluye el servicio cambio de aceite?</h2>
            <p className="gray-text fs-2">Cada cambio de aceite incluye la revisión de:</p>
          </div>
        </SectionRow>
        <div className="d-md-none mt-3">
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr // means to render carousel on server-side.
            infinite
            autoPlay
            autoPlaySpeed={5000}
            keyBoardControl
            transitionDuration={500}
            containerClass="react-multi-carousel-size"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {featuresList.map((block, index) => <SectionRow key={index}> {block.map(data => <IconCol imgPath={data.image} title={data.title} key={data.key} />)} </SectionRow>)}
          </Carousel>
        </div>
        <div className="d-none d-md-block">
          <SectionRow>
            {featuresList[0].map(data => <IconCol imgPath={data.image} title={data.title} key={data.key} />)}
          </SectionRow>
          <SectionRow>
            {featuresList[1].map(data => <IconCol imgPath={data.image} title={data.title} key={data.key} />)}
          </SectionRow>
        </div>
      </div>
      <NextButton arrowColor='redArrow' sectionId='#steps' />
    </>
  );
};

export default Features;
