export const bookingConstants = {
  GET_BOOKINGS_REQUEST: "GET_BOOKINGS_REQUEST",
  GET_BOOKINGS_SUCCESS: "GET_BOOKINGS_SUCCESS",
  GET_BOOKINGS_FAILURE: "GET_BOOKINGS_FAILURE",

  EDIT_BOOKING_REQUEST: "EDIT_BOOKING_REQUEST",
  EDIT_BOOKING_SUCCESS: "EDIT_BOOKING_SUCCESS",
  EDIT_BOOKING_FAILURE: "EDIT_BOOKING_FAILURE",

  CREATE_BOOKING_REQUEST: "CREATE_BOOKING_REQUEST",
  CREATE_BOOKING_SUCCESS: "CREATE_BOOKING_SUCCESS",
  CREATE_BOOKING_FAILURE: "CREATE_BOOKING_FAILURE",

  DOWNLOAD_REPORT_REQUEST: "DOWNLOAD_REPORT_REQUEST",
  DOWNLOAD_REPORT_SUCCESS: "DOWNLOAD_REPORT_SUCCESS",
  DOWNLOAD_REPORT_FAILURE: "DOWNLOAD_REPORT_FAILURE",

  VALIDATE_CAPTCHA_REQUEST: "VALIDATE_CAPTCHA_REQUEST",
  VALIDATE_CAPTCHA_SUCCESS: "VALIDATE_CAPTCHA_SUCCESS",
  VALIDATE_CAPTCHA_FAILURE: "VALIDATE_CAPTCHA_FAILURE",

  VALIDATE_PROMOTIONAL_CODE_REQUEST: "VALIDATE_PROMOTIONAL_CODE_REQUEST",
  VALIDATE_PROMOTIONAL_CODE_SUCCESS: "VALIDATE_PROMOTIONAL_CODE_SUCCESS",
  VALIDATE_PROMOTIONAL_CODE_FAILURE: "VALIDATE_PROMOTIONAL_CODE_FAILURE",
};
