import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Label, Spacing, Text, TextField,
} from 'react-elemental';
import { Modal } from 'react-bootstrap';

const StationProductModal = ({
  show,
  onHandleChangePrice,
  onHandleClose,
  defaultPrice,
}) => {
  const [price, setPrice] = useState(defaultPrice);

  useEffect(() => {
    setPrice(defaultPrice);
  }, [defaultPrice]);

  const validatePrice = (price) => {
    if (price === '') return '';

    const re = new RegExp('^[0-9]+$');
    if (!re.test(price)) { return 'No es un precio válido'; }

    return '';
  };

  const onHandleChange = (price) => {
    if (validatePrice(price) === '') {
      setPrice(price);
    }
  };

  return (
    <Modal
      show={ show }
      id="modal_add_product"
      onHide={ onHandleClose }
      size="xs"
      centered
      dialogClassName="ap-modal"
      backdrop="static"
    >

      <h5 className="mb-4">
        Modifique el precio con el cual va a estar disponible
        el producto en la sucursal
      </h5>

      <div className="col-md-8 col-sm-12 col-xs-12 ml-auto mr-auto">
        <Spacing bottom>
          <Label sublabel="Precio" />
          <TextField
            className="rounded-pill"
            placeholder="Precio"
            error={ validatePrice(price) }
            value={ price }
            onChange={ (e) => { onHandleChange(e.target.value); } }
          />
        </Spacing>
      </div>

      <Text right>
        <Button
          text="Cancelar"
          secondary
          className="mr-3 rounded-pill"
          onClick={ () => onHandleClose() }
        />
        <Button
          text="Guardar"
          className="rounded-pill"
          onClick={ () => {
            onHandleChangePrice(price);
            onHandleClose();
          } }
          disabled={ validatePrice(price) || price === '' }
        />
      </Text>
    </Modal>
  );
};

StationProductModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHandleChangePrice: PropTypes.func.isRequired,
  onHandleClose: PropTypes.func.isRequired,
  defaultPrice: PropTypes.number.isRequired,
};

StationProductModal.defaultProps = {};

export default StationProductModal;
