import React from 'react';
import { lubIconList, responsive } from './helpers';
import './styles.scss';
import NextButton from '../NextButton';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


const LubProducts = () => {

  const LubIconCol = ({ imgPath, title, description }) => {
    return (
      <div className="col-md col-12 text-center">
        <div className="icon-box-oil mx-auto">
          <img src={imgPath} className="icon-img-oil" alt={title} />
        </div>
        <h5 className="red-text">{title}</h5>
        <p className="gray-text">{description}</p>
      </div>

    );
  }
  return (
    <>
      <div className="container generic-padding-carousel mb-5" id="oil-products">
        <div className="row text-center pading-carousel-title">
          <div className="col ">
            <h2 className="gray-text faq-lub-title">Elige el mejor lubricante para tu auto</h2>
          </div>
        </div>
        <Carousel
          swipeable={false}
          draggable={false}
          showDots={false}
          responsive={responsive}
          ssr // means to render carousel on server-side.
          infinite
          autoPlay
          autoPlaySpeed={5000}
          keyBoardControl
          // customTransition="all .5"
          transitionDuration={500}
          containerClass="react-multi-carousel-size"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {lubIconList.map((data, index) => (
              <div key={index}>
                <LubIconCol imgPath={data.imgPath} title={data.title} description={data.description} key={data.key} />
              </div>
            ))}
        </Carousel>
      </div>
      <NextButton arrowColor='redArrow' sectionId='#shell-experience' />
    </>
  );
};

export default LubProducts;
