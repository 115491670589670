import React from 'react';
import { stepsList } from './helpers';
import NextButton from '../NextButton';
import './styles.scss';

const Steps = () => {

  const ListCol = ({ number, text }) => {
    return (
      <div className="row mt-4 mt-md-5 d-flex justify-content-center">
        <div className="number-circle mr-2 d-flex align-items-center justify-content-center shell-bold">
        {number}
        </div>
        <div className="col-6 shell-bold">
        {text}
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="steps-background landing-scroll-steps" id="steps" >
        <div className="dark-layer">
          <div className="container generic-padding">
            <div className="row pt-5 d-block text-center text-white mb-5 mb-md-0">
              <h2 className="mx-auto steps-title">Pasos para agendar tu cambio de aceite:</h2>
            </div>
            <div className="pt-md-5 text-white align-items-center mb-3">
                {stepsList.map(data => <ListCol number={data.number} text={data.text} key={data.key} />)}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
            </div>
          </div>
        </div>
      </div>
      <NextButton arrowColor='redArrow' sectionId='#oil-products' />
    </>
  );
};

export default Steps;
