import React from 'react';
import Image from 'react-bootstrap/Image';
import { colors } from 'react-elemental';
import enex_gray from '../../commons/assets/enex-gray.svg';
import helix from '../../commons/assets/helix-footer.svg';
import { AiFillInstagram } from 'react-icons/ai';
import { FaFacebookF } from 'react-icons/fa';
import { GrMail } from 'react-icons/gr';
import { MdPhoneIphone } from 'react-icons/md';
import './Footer.scss';

const Footer = () => (
  <footer
    id="footer"
    className="footer row mr-0 p-4"
  >
    <div className="row ml-4 mr-4 text-center" style={{ width: "100%" }}>
      <div className="col-12 col-md-6 mb-3 ml-3">
        <div className="align-items-center justify-content-center ">
          <a target="_blank" className="mr-3" rel="noopener noreferrer" href="https://www.instagram.com/shellchile/">
            <AiFillInstagram color="#7F7F7F" size={26} />
          </a>
          <a target="_blank" className="mr-3" rel="noopener noreferrer" href="https://www.facebook.com/PromoShellChile">
            <FaFacebookF color="#7F7F7F" size={26} />
          </a>
          <a href="mailto:cambiaconhelix@enex.cl">
            <GrMail className="mr-3" color="#7F7F7F" size={26} />
            <span className="color-gray footer-font-size d-none d-md-inline-block">cambiaconhelix@enex.cl</span>
          </a>
          <a href="tel:6003502000" className="mr-3">
            <MdPhoneIphone className="ml-md-3" color="#7F7F7F" size={30} />
            <span className="color-gray ml-md-3 footer-font-size d-none d-md-inline-block">600 350 2000</span>
          </a>
        </div>
      </div>

      <div className="col-12 col-md-4 mb-3">
        <div className="align-items-center justify-content-md-end justify-content-center">
          <Image
            alt="Shell"
            src={helix}
            color={colors.primary}
            width="auto"
            height="30px"
          />
          <Image
            className="ml-3"
            alt="Shell"
            src={enex_gray}
            color={colors.primary}
            width="auto"
            height="35px"
          />
        </div>
      </div>

      <div className="col-12 col-md ">
        <span className="m-0 ml-4 ml-md-3 color-gray footer-font-size">©Copyright 2020 Enex S.A.</span>
      </div>
    </div>
  </footer>

);

export default Footer;
