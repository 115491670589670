import { authConstants, constants, stationConstants } from '../constants';
import { axios_instance } from '../services';

const initialState = {
  isLogged: false,
  isLoading: false,
  error: '',
  currentUser: null,
  resetPassword: false,
  redirect: false,

};

export function auth(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return { ...state, isLoadingLogin: true };
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        currentUser: action.user,
        isLoadingLogin: false,
        isLogged: true,
      };
    case authConstants.RESET_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
        hasError: false,
        resetPassword: false,
      };
    case authConstants.RESET_SUCCESS:
      return {
        ...state,
        resetPassword: true,
        isLoading: false,
      };
    case authConstants.RESET_FAILURE:
      return {
        ...state,
        isLoading: false,
        resetPassword: false,
        error: action.error,
        hasError: true,
      };
    case authConstants.CHANGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
        hasError: false,
        resetPassword: false,
        redirect: false,
      };
    case authConstants.CHANGE_SUCCESS:
      return {
        ...state,
        resetPassword: true,
        redirect: true,
        isLoading: false,
      };
    case authConstants.CHANGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        resetPassword: false,
        error: action.error,
        hasError: true,
        redirect: action.redirect,
      };
    case authConstants.LOGIN_FAILURE:
      return {
        ...state,
        isLoadingLogin: false,
        error: action.error,
        hasError: true,
        currentUser: null,
        isLogged: false,
      };
    case authConstants.LOGOUT_REQUEST:
      axios_instance.defaults.headers.common.Authorization = undefined;
      localStorage.removeItem('accessToken');
      return {
        ...state,
        isLoadingLogin: false,
        error: '',
        hasError: false,
        currentUser: null,
        isLogged: false,
      };
    case stationConstants.EDIT_FULL_CONFIG_REQUEST:
      return {
        ...state,
        isLoadingLogin: true,
        error: '',
        hasError: false,
      };
    case stationConstants.EDIT_FULL_CONFIG_SUCCESS: {
      const user = {
        ...state.currentUser,
        first_name: action.data.first_name,
        last_name: action.data.last_name,
        email: action.data.email,
        alternative_email: action.data.alternative_email,
        phone: action.data.phone,
        rut: action.data.rut,
        business_name: action.data.business_name,
      };

      return {
        ...state,
        currentUser: user,
        isLoadingLogin: false,
        isLogged: true,
      };
    }
    case stationConstants.EDIT_FULL_CONFIG_FAILURE:
      return {
        ...state,
        error: action.error,
        hasError: true,
        isLoadingLogin: false,
      };
    case constants.RESET_ERROR_STATE:
      return {
        ...state,
        error: '',
        hasError: false,
      };
    default:
      return state;
  }
}
