import React, { useEffect, useState, useCallback, useMemo } from "react";
import { WorkOrdersApi } from "../../../../../services/api";
import {
  serviceFactory,
  calculatePages,
  getPreviousPage,
  getNextPage,
  PAGESIZE,
  getCurrentUser,
  getAllLubricators,
  formatRut,
} from "../../../../../utils/helpers";
import DatePicker from "react-datepicker";
import moment from "moment";
import { element } from "prop-types";

const workOrdersApi = serviceFactory(WorkOrdersApi);

const ListOrder = () => {
  const currentUser = useMemo(() => getCurrentUser(), []);
  const [showModal, setShowModal] = useState(false);
  const [otData, setOtData] = useState({});
  const [workOrders, setWorkOrders] = useState(null);
  const [lubricators, setLubricators] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [previousPage, setPreviousPage] = useState(0);
  const [nextPage, setNextPage] = useState(0);
  const [updateVoucherNumber, setUpdateVoucherNumber] = useState(false);
  const [showUpdateError, setShowUpdateError] = useState(false);

  const [otNumberFilter, setOtNumberFilter] = useState(null);
  const [ticketNumberFilter, setTicketNumberFilter] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [plateNumberFilter, setPlateNumberFilter] = useState(null);
  const [rutFilter, setRutFilter] = useState(null);

  const openModalOT = (item) => {
    setShowModal(true);
    setOtData({ ...item });
    // console.log(item);
  };
  const closeModalOT = () => {
    setShowModal(false);
    setOtData(null);
  };

  const getWorkOrders = useCallback(
    (data) => {
      workOrdersApi
        .workOrdersList({
          page: currentPage,
          pageSize: PAGESIZE,
          station: currentUser.station.id,
          otNumber: data && data.otNumber ? data.otNumber : "",
          ticketNumber: data && data.ticketNumber ? data.ticketNumber : "",
          startDate:
            data && data.startDate
              ? data.startDate.toISOString().split("T")[0]
              : "",
          endDate:
            data && data.endDate
              ? data.endDate.toISOString().split("T")[0]
              : "",
          patent: data && data.patent ? data.patent : "",
          rut: data && data.rut ? data.rut : "",
        })
        .then((response) => setWorkOrders(response.data));
    },
    [currentPage, currentUser]
  );

  const searchItems = () => {
    getWorkOrders({
      otNumber: otNumberFilter,
      ticketNumber: ticketNumberFilter,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      patent: plateNumberFilter,
      rut: rutFilter.replaceAll(".", ""),
    });
  };

  useEffect(() => {
    setTotalPages(calculatePages(workOrders?.count, PAGESIZE));
    setPreviousPage(getPreviousPage(workOrders?.previous));
    setNextPage(getNextPage(workOrders?.next));
  }, [workOrders]);

  useEffect(() => {
    getWorkOrders();
  }, [getWorkOrders]);

  useEffect(() => {
    getAllLubricators({ station: currentUser.station.id }).then((lubricators) =>
      setLubricators(lubricators)
    );
  }, [currentUser]);

  return (
    <>
      <h5 className="red-text">Lista de órdenes de trabajo</h5>
      <div className="my-4">
        <div className="row">
          <div className="col-sm-6 col-md-4 col-lg form-group">
            <input
              type="text"
              value={otNumberFilter}
              className="search-input form-control"
              onChange={(e) => setOtNumberFilter(e.target.value)}
              placeholder="Nº OT"
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg form-group">
            <input
              type="text"
              value={ticketNumberFilter}
              className="search-input form-control"
              onChange={(e) => setTicketNumberFilter(e.target.value)}
              placeholder="Nº Boleta"
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg form-group">
            <input
              type="text"
              value={plateNumberFilter}
              className="search-input form-control"
              onChange={(e) =>
                setPlateNumberFilter(e.target.value.toUpperCase())
              }
              placeholder="Patente"
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg form-group">
            <input
              type="text"
              value={rutFilter}
              className="search-input form-control"
              onChange={(e) => setRutFilter(formatRut(e.target.value))}
              placeholder="Rut"
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg form-group">
            <DatePicker
              selected={dateRange.startDate}
              onChange={(date) =>
                setDateRange((prevState) => ({
                  ...prevState,
                  startDate: date,
                }))
              }
              placeholderText="Fecha inicio"
              selectsStart
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              className="form-control"
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg form-group">
            <DatePicker
              selected={dateRange.endDate}
              onChange={(date) =>
                setDateRange((prevState) => ({
                  ...prevState,
                  endDate: date,
                }))
              }
              placeholderText="Fecha fin"
              selectsEnd
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              minDate={dateRange.startDate}
              className="form-control"
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg form-group">
            <button
              className="btn new-order-button font-weight-bold"
              onClick={() => searchItems()}
            >
              Buscar
            </button>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>OT</th>
              <th>Cliente</th>
              <th>Rut</th>
              <th>Ver mas</th>
            </tr>
          </thead>
          <tbody>
            {workOrders?.results?.map((element) => (
              <tr key={element.id}>
                <td>{element.ot_number}</td>
                <td>{element.booking.customer?.name}</td>
                <td>{element.booking.customer?.rut}</td>
                <td className="text-center">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm rounded-pill"
                    onClick={() => {
                      openModalOT(element);
                    }}
                  >
                    Ver más
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="col-12 text-right mt-3">
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li
              className={`page-item ${
                totalPages === 0 || (!previousPage && "disabled")
              }`}
            >
              <div
                onClick={() => {
                  if (previousPage) setCurrentPage(previousPage);
                  return null;
                }}
              >
                Anterior
              </div>
            </li>
            {totalPages > 0 ? (
              new Array(totalPages).fill(1).map((_, index) => {
                const pageSelection = ++index;
                return (
                  <li
                    className={` ${pageSelection === currentPage && "active"}`}
                    key={`select-page-${pageSelection}`}
                  >
                    <div onClick={() => setCurrentPage(pageSelection)}>
                      {pageSelection}
                    </div>
                  </li>
                );
              })
            ) : (
              <li className="page-item disabled">
                <div className="page-link">1</div>
              </li>
            )}

            <li
              className={`page-item ${
                totalPages === 0 || (!nextPage && "disabled")
              }`}
            >
              <div
                onClick={() => {
                  if (nextPage) setCurrentPage(nextPage);
                  return null;
                }}
              >
                Siguiente
              </div>
            </li>
          </ul>
        </nav>
      </div>
      {showModal && (
        <div>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade show d-block"
            id="staticBackdrop"
            data-backdrop="static"
            data-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title text-red" id="staticBackdropLabel">
                    Detalles de la OT
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={closeModalOT}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-sm-6 form-group">
                      <strong>OT:</strong> {otData?.ot_number}
                    </div>
                    <div className="col-sm-6 form-group">
                      <strong>Lubricador:</strong>{" "}
                      {otData &&
                        lubricators?.find(
                          (item) => item.id === otData.lubricator
                        )?.full_name}
                    </div>
                    <div className="col-sm-6 form-group">
                      <strong>Fecha:</strong>{" "}
                      {otData &&
                        moment(otData.date, "YYYY-MM-DDTHH:mm:ss").format(
                          "lll"
                        )}
                    </div>
                    <div className="col-sm-6 form-group">
                      <strong>Cliente:</strong> {otData?.booking.customer?.name}
                    </div>
                    <div className="col-sm-6 form-group">
                      <strong>RUT:</strong> {otData?.booking.customer?.rut}
                    </div>
                    <div className="col-sm-6 form-group">
                      <strong>Patente:</strong>{" "}
                      {otData?.booking.vehicle?.patent}
                    </div>
                    <div className="col-sm-6 form-group">
                      <strong>N° de Boleta:</strong>
                      {updateVoucherNumber ? (
                        <>
                          <input
                            type="text"
                            className="ml-1 pl-1"
                            value={otData?.ticket_number}
                            onChange={(e) =>
                              setOtData({
                                ...otData,
                                ticket_number: e.target.value,
                              })
                            }
                          />
                          <div className="mt-2">
                            <button
                              className="btn btn-outline-secondary mt-3 d-block"
                              onClick={() => {
                                workOrdersApi
                                  .workOrdersPartialUpdate({
                                    id: otData?.id,
                                    data: {
                                      ticket_number: otData?.ticket_number,
                                    },
                                  })
                                  .then((response) => {
                                    if (response.status === 200) {
                                      getWorkOrders();
                                      setUpdateVoucherNumber(false);
                                    }
                                  })
                                  .catch((error) => setShowUpdateError(true));
                              }}
                            >
                              Actualizar
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          {otData?.ticket_number}
                          <button
                            className="btn btn-outline-primary mt-3 d-block"
                            onClick={() => setUpdateVoucherNumber(true)}
                          >
                            Editar
                          </button>
                        </>
                      )}{" "}
                    </div>
                    {showUpdateError && (
                      <span className="red-text">
                        Ocurrió un error, intente de nuevo
                      </span>
                    )}
                    <div className="col-md-12 form-group">
                      <strong>Servicios:</strong>
                      <ul className="list-group mt-2">
                        {otData?.services?.map((item) => (
                          <li
                            className="list-group-item p-2"
                            key={`${item.service.name}-${element.ot_number}`}
                          >
                            {item.service.name}{" "}
                            {item.changed ? (
                              <span className="badge badge-pill badge-success">
                                C
                              </span>
                            ) : (
                              <span className="badge badge-pill badge-primary">
                                R
                              </span>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-secondary rounded-pill"
                    onClick={closeModalOT}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ListOrder;
