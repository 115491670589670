import { bookingService } from "../services/booking";
import { bookingConstants } from "../constants";
import { handleError } from "../services";
import {BookingsApi} from '../services/api'
import {serviceFactory, PAGESIZE} from '../utils/helpers'

const bookingsApi = serviceFactory(BookingsApi);

export function list(data= {}) {
  function request() {
    return { type: bookingConstants.GET_BOOKINGS_REQUEST };
  }

  function success(list) {
    return { type: bookingConstants.GET_BOOKINGS_SUCCESS, list };
  }

  function failure(error) {
    error = handleError(error);
    return { type: bookingConstants.GET_BOOKINGS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    const query = {
      ...data,
      page: data?.page ? data?.page : 1,
      pageSize: PAGESIZE,
      isGhost: false
    }

    bookingsApi.bookingsList(query).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response))
    );
  };
}

export function edit(bookingId, data) {
  function request() {
    return { type: bookingConstants.EDIT_BOOKING_REQUEST };
  }

  function success(booking) {
    return { type: bookingConstants.EDIT_BOOKING_SUCCESS, booking };
  }

  function failure(error) {
    error = handleError(error);
    return { type: bookingConstants.EDIT_BOOKING_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    bookingService.edit(bookingId, data).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response))
    );
  };
}

export function create(data) {
  function request() {
    return { type: bookingConstants.CREATE_BOOKING_REQUEST };
  }

  function success(booking) {
    return { type: bookingConstants.CREATE_BOOKING_SUCCESS, booking };
  }

  function failure(error) {
    if (error.hasOwnProperty('data') && typeof error.data == 'object'){
      error = Object.values(error.data).join(', ');
    }else{
      switch (error.status) {
        case 422:
          error = "Código promocional inválido";
          break;
        case 408:
          error = "Error al validar captcha, inténtalo más tarde";
          break;
        case 406:
          error = "Tu captcha fue invalidado, inténtalo nuevamente";
          break;
        case 417:
          error = "No hay descuento para este código promocional";
          break;
        default:
          error =
            "Opps, Parece que otra persona agarró este horario, por favor inténtelo nuevamente";
        }
      }
    // error = handleError(error); // Uncomment if is needed
    return { type: bookingConstants.CREATE_BOOKING_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    bookingService.create(data).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response))
    ).catch((error) => {
      console.log(error)
      bookingService.get_booking(data).then(
        (response) => dispatch(success(response.data)),
        (error) => dispatch(failure(error.response))
      ).catch((e) => {
        return { type: bookingConstants.CREATE_BOOKING_FAILURE, error: 'Ocurrio un error desconocido'}
      })
    })
  };
}

export function downloadReport(startDate, endDate) {
  function request() {
    return { type: bookingConstants.DOWNLOAD_REPORT_REQUEST };
  }

  return (dispatch) => {
    dispatch(request());
    const startDateStr = `${startDate.getFullYear()}-${`0${
      startDate.getMonth() + 1
    }`.slice(-2)}-${`0${startDate.getDate()}`.slice(-2)}`;
    const endDateStr = `${endDate.getFullYear()}-${`0${
      endDate.getMonth() + 1
    }`.slice(-2)}-${`0${endDate.getDate()}`.slice(-2)}`;
    bookingService.downloadReport(startDateStr, endDateStr);
  };
}

export function validateCaptcha(token) {
  function request() {
    return { type: bookingConstants.VALIDATE_CAPTCHA_REQUEST };
  }

  function success(captcha) {
    return { type: bookingConstants.VALIDATE_CAPTCHA_SUCCESS, captcha };
  }
  function failure(error) {
    // error = handleError(error); // Uncomment if is needed
    switch (error.status) {
      case 408:
        error = "Error al validar captcha, inténtalo más tarde";
        break;
      case 406:
        error = "Tu captcha fue invalidado, inténtalo nuevamente";
        break;

      default:
        error = "Algo salió mal durante la validación, inténtelo de nuevo";
        break;
    }
    return { type: bookingConstants.VALIDATE_CAPTCHA_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    bookingService.validateCaptcha(token).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response))
    );
  };
}

export function retrievePromotionalCode(promotionalCode) {
  function request() {
    return { type: bookingConstants.VALIDATE_PROMOTIONAL_CODE_REQUEST };
  }

  function success(promotionalCode) {
    return {
      type: bookingConstants.VALIDATE_PROMOTIONAL_CODE_SUCCESS,
      promotionalCode,
    };
  }
  function failure(error) {
    // error = handleError(error); // Uncomment if is needed
    switch (error.status) {
      case 422:
        error = "El código promocional no existe o está caducado.";
        break;
      case 202:
        error = "El código promocional es valido";
        break;

      default:
        error = "Algo salió mal durante la validación, inténtelo de nuevo";
        break;
    }
    return { type: bookingConstants.VALIDATE_PROMOTIONAL_CODE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    bookingService.validatePromotionalCode(promotionalCode).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.response))
    );
  };
}
