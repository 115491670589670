import * as React from 'react';
import './Date.scss';
import { colors, Text } from 'react-elemental';
import Moment from 'react-moment';
import { FiClock as Clock } from 'react-icons/fi';
import { FaMapMarkerAlt as Marker } from 'react-icons/fa';
import { daysWeek } from '../../../../constants';
import Stats from '../../Stats';
import TimeBlock from './TimeBlock';

const ALLOWED_DAYS = 13;

class SelectDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      days: [],
      blocks: [],
      selectedDate: null,
      selectedBlock: null,
    };
    this.calculateDays = this.calculateDays.bind(this);
    this.addDays = this.addDays.bind(this);
    this.getBlocksInDay = this.getBlocksInDay.bind(this);
    this.isBlockDisabled = this.isBlockDisabled.bind(this);
    this.onHandleChangeDate = this.onHandleChangeDate.bind(this);
    this.onHandleChangeBlock = this.onHandleChangeBlock.bind(this);
    this.calculateBlockInSelectedDay = this.calculateBlockInSelectedDay.bind(
      this,
    );
  }

  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  getBlocksInDay(config) {
    const SCHEDULE_START_TIME = config.station.opened_at.split(':')[0];
    const SCHEDULE_END_TIME = config.station.closed_at.split(':')[0];
    const date1 = new Date();
    date1.setHours(SCHEDULE_START_TIME, 0, 0, 0);
    const date2 = new Date();
    date2.setHours(SCHEDULE_END_TIME, 0, 0, 0);
    const diff = (date2.getTime() - date1.getTime()) / (1000 * 60);
    return Math.floor(diff / config.attention_time);
  }

  calculateDays(config) {
    const days = [];
    const now = new Date();
    const pivot = this.addDays(now, 1);
    for (let i = 0; i <= ALLOWED_DAYS; i++) {
      const date = this.addDays(pivot, i);
      const item = {
        date,
        weekday: date.getDay(),
        blocks: [],
      };
      // if (date.getDay() !== 0)
      days.push(item);
    }

    this.setState({ days });

    if (days.length > 0) {
      this.setState({ selectedDate: days[0].date });
      this.calculateBlockInSelectedDay(days[0].date, config);
    }
  }

  calculateBlockInSelectedDay(selectedDate, config) {
    const SCHEDULE_START_TIME = config.station.opened_at.split(':')[0];
    const attention_time = config.attention_time;
    const blocks = [];
    const fixedDate = new Date(selectedDate);
    const initialDate = new Date(selectedDate);
    for (let j = 0; j < this.getBlocksInDay(config); j++) {
      initialDate.setHours(SCHEDULE_START_TIME, 0, 0, 0);
      const initial = new Date(initialDate.setMinutes(
        initialDate.getMinutes() + (attention_time * j),
      ));
      const end = new Date(
        initialDate.setMinutes(initialDate.getMinutes() + attention_time),
      );
      const start = new Date(fixedDate.getFullYear(), fixedDate.getMonth(),
        fixedDate.getDate(), initial.getHours(), initial.getMinutes());
      const data = {
        id: j,
        start,
        end: new Date(fixedDate.getFullYear(), fixedDate.getMonth(),
          fixedDate.getDate(), end.getHours(), end.getMinutes()),
        weekday: initialDate.getDay() === 0 ? 6 : initialDate.getDay() - 1,
      };

      // Is Saturday ?
      let canAddInSat = true;
      const SCHEDULE_END_TIME = config.station.sat_closed_at.split(':')[0];
      if (initialDate.getDay() === 6 && initialDate.getHours()
        > SCHEDULE_END_TIME) canAddInSat = false;

      // Is Sunday ?
      const SCHEDULE_END_TIME_SUN = config.station.sun_closed_at.split(':')[0];
      if (initialDate.getDay() === 0 && initialDate.getHours()
        > SCHEDULE_END_TIME_SUN) canAddInSat = false;

      const pivotDate = new Date(start).setHours(start.getHours());
      if (this.addDays(new Date(), 1).getTime()
        < new Date(pivotDate).getTime() && canAddInSat) blocks.push(data);
    }
    this.setState({ blocks });
  }

  isBlockDisabled(block) {
    if (!this.props.exceptions) return false;

    return this.props.exceptions.find((item) => new Date(item.start_date).getTime()
        === new Date(block.start).getTime()) !== undefined;
  }

  onHandleChangeDate(date) {
    this.setState({ selectedDate: date });
    this.setState({ selectedBlock: null });
    this.calculateBlockInSelectedDay(date, this.props.config);
  }

  onHandleChangeBlock(block) {
    if (!this.isBlockDisabled(block)) {
      this.setState({ selectedBlock: block });
      this.props.onChangeDate(block.start);
    }
  }

  componentDidMount() {
    if (this.props.config !== null) {
      this.calculateDays(this.props.config);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.config !== this.props.config && nextProps.config !== null) this.calculateDays(nextProps.config);
  }

  render() {
    const { days, blocks } = this.state;
    const { config } = this.props;
    return (
      <div className="mt-3">
        {
          config !== null
            ? (
              <div className="row mb-md-5 mb-3">
                <div className="col-md-6">

                  <h3 className="custom-title black">
                    Selecciona una
                    fecha
                  </h3>
                  <div className="text-muted margin-top--5 mb-3">
                    Reserva tu cambio
                    de aceite el día que quieras
                  </div>

                  <h5
                    className="text-bold custom-title  black text-capitalize text-month"
                  >
                    <Moment
                      locale="es"
                      format="MMMM  YYYY"
                      element="span"
                    >
                      { this.addDays(new Date(),
                        1) }
                    </Moment>
                  </h5>
                  <div className="select_date_container">
                    <div className="d-inline-flex">
                      { days.map((day, index) => (
                        <div key={ index }>
                          {
                            index <= 6
                            && (
                            <div
                              className="mb-2 label-day font-weight-bold"
                            >
                              { daysWeek[day.weekday].substring(
                                0, 2,
                              ) }
                            </div>
                            )
                          }
                        </div>
                      )) }
                    </div>

                    <div>
                      <div
                        className="d-inline-flex"
                        id="select_date_container"
                      >
                        { days.map((day, index) => (
                          <div>
                            {
                              index <= 6
                              && (
                              <div
                                key={ index }
                                className={ (this.state.selectedDate
                                     && this.state.selectedDate.getDate()
                                     === day.date.getDate())
                                  ? 'text-center day-container selected'
                                  : 'text-center day-container' }
                                onClick={ () => {
                                  this.onHandleChangeDate(day.date);
                                } }
                              >

                                <Moment
                                  format="DD"
                                  className={ (new Date().getDate()
                                          === day.date.getDate())
                                    ? 'current-date'
                                    : '' }
                                >
                                  { day.date }
                                </Moment>
                              </div>
                              )
                            }
                          </div>
                        )) }
                      </div>
                    </div>

                    <div>
                      <div
                        className="d-inline-flex"
                        id="select_date_container-2"
                      >
                        { days.map((day, index) => (
                          <div>
                            {
                              index > 6
                              && (
                              <div
                                key={ index }
                                className={ (this.state.selectedDate
                                     && this.state.selectedDate.getDate()
                                     === day.date.getDate())
                                  ? 'text-center day-container selected'
                                  : 'text-center day-container' }
                                onClick={ () => {
                                  this.onHandleChangeDate(day.date);
                                } }
                              >

                                <Moment
                                  format="DD"
                                  className={ (new Date().getDate()
                                          === day.date.getDate())
                                    ? 'current-date'
                                    : '' }
                                >
                                  { day.date }
                                </Moment>
                              </div>
                              )
                            }
                          </div>
                        )) }
                      </div>
                    </div>

                  </div>
                </div>

                <div className="col-md-6 col-lg-4 pt-2 pt-md-0">
                  <h3 className="custom-title black">Selecciona una hora</h3>
                  <div className="text-muted margin-top--5 mb-3">
                    Elige la hora que
                    más te acomode *
                  </div>
                  <div className="row px-2">
                    {
                    blocks.length > 0
                      ? this.props.exceptions
                      && blocks.map((block, index) => (
                        <div className="col-3 mb-3 pr-1 pl-1">
                          <TimeBlock
                            key={ index }
                            selected={ this.state.selectedBlock
                              && this.state.selectedBlock.id === block.id }
                            disabled={ this.isBlockDisabled(block) }
                            start={ block.start }
                            end={ block.end }
                            selectBlock={ () => this.onHandleChangeBlock(
                              block,
                            ) }
                          />
                        </div>
                      ))

                      : (
                        <div className="d-inline-flex">
                          <Text
                            size="iota"
                            className="text-center mt-3"
                            color={ colors.gray45 }
                            bold
                          >
                            <Clock />
                            No hay horas disponibles.
                          </Text>
                        </div>
                      )
                  }
                  </div>

                  <div className="text-muted w-75">
                    *Te esperaremos hasta 15
                    minutos después de la hora agendada.
                  </div>

                </div>

              </div>
            )
            : (
              <div className="d-inline-flex w-100">
                <Text
                  size="epsilon"
                  className="text-center mt-3 w-100"
                  color={ colors.gray45 }
                  bold
                >
                  <Marker />
                  No has seleccionado una sucursal.
                </Text>
              </div>
            )
        }

        <Stats
          step={ 2 }
          { ...this.props }
          disabled={ this.state.selectedBlock === null }
        />
      </div>
    );
  }
}

export default SelectDate;
