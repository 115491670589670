import React from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  MarkerClusterer,
} from '@react-google-maps/api';
import icon_map from '../../../../commons/assets/helix_map.svg';

function StationMap(props) {
  const mapContainerStyle = {
    height: '350px',
    width: '100%',
  };

  const center = {
    lat: parseFloat(props.center.lat),
    lng: parseFloat(props.center.lng),
  };

  const locations = props.stations.map((station) => ({
    stationId: station.id,
    lat: parseFloat(station.lat),
    lng: parseFloat(station.lng),
    title: `${station.address}`,
  }));

  const options = {
    imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
  };

  return (
    <LoadScript
      className="ml-auto mr-auto"
      id="script-loader"
      googleMapsApiKey="AIzaSyAK7dzE6LwrsMYO6Je_L_n6vQFjqsp25x8"
    >
      <GoogleMap
        id="station-map"
        mapContainerStyle={ mapContainerStyle }
        zoom={ props.zoom }
        center={ center }
      >
        <MarkerClusterer options={ options }>
          {
            (clusterer) => locations.map((location, i) => (
              <Marker
                key={ i }
                position={ location }
                clusterer={ clusterer }
                title={ location.title }
                icon={ icon_map }
                onClick={ () => props.selectStation(
                  { value: location.stationId },
                ) }
              />
            ))
          }
        </MarkerClusterer>
      </GoogleMap>
    </LoadScript>
  );
}

export default StationMap;
