import * as React from 'react';
import './InfoTicket.scss';
import { colors, Text } from 'react-elemental';
import success from '../../commons/assets/reserva_exitosa_yellow.svg';

function InfoTicket(props) {
  return (
    <div className="mb-5">
      <div className="text-center mt-5">
        <img
          alt="Success"
          src={ success }
          className="mb-2"
          width="100px"
          height="auto"
        />
        <div
          className="col-md-5 col-sm-12 col-xs-12 text-danger ml-auto mr-auto mb-4"
        >
          <h4 className="custom-title black mt-3">
            ¡Tu boleta se ha registrado con éxito!
          </h4>
        </div>
        <Text
          size="iota"
          className="text-center w-100 mb-5 mt-3"
          color={ colors.gray50 }
          bold
        >
          <strong>¡Gracias por registrarte!</strong>
        </Text>

        <span
          className="mt-4 btn-back"
          onClick={ () => {
            props.history.push('/app/registro-boleta');
          } }
        >
          Registrar otra boleta
        </span>
      </div>

    </div>
  );
}

export default InfoTicket;
