/* tslint:disable */
/* eslint-disable */
/**
 * Enex API
 * API to manage bookings for oil change with Enex
 *
 * The version of the OpenAPI document: v1
 * Contact: jliznaga@linets.cl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Service } from '../models';
/**
 * ServicesApi - axios parameter creator
 * @export
 */
export const ServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Viewset model crud of customer.
         * @param {Service} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesCreate: async (data: Service, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('servicesCreate', 'data', data)
            const localVarPath = `/services/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Servicio.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('servicesDelete', 'id', id)
            const localVarPath = `/services/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset model crud of customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/services/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Servicio.
         * @param {Service} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesPartialUpdate: async (id: number, data: Service, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('servicesPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('servicesPartialUpdate', 'data', data)
            const localVarPath = `/services/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Servicio.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('servicesRead', 'id', id)
            const localVarPath = `/services/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Servicio.
         * @param {Service} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesUpdate: async (id: number, data: Service, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('servicesUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('servicesUpdate', 'data', data)
            const localVarPath = `/services/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServicesApi - functional programming interface
 * @export
 */
export const ServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * Viewset model crud of customer.
         * @param {Service} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async servicesCreate(data: Service, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Service>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.servicesCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Servicio.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async servicesDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.servicesDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Viewset model crud of customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async servicesList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Service>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.servicesList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Servicio.
         * @param {Service} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async servicesPartialUpdate(id: number, data: Service, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Service>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.servicesPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Servicio.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async servicesRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Service>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.servicesRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Servicio.
         * @param {Service} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async servicesUpdate(id: number, data: Service, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Service>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.servicesUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ServicesApi - factory interface
 * @export
 */
export const ServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServicesApiFp(configuration)
    return {
        /**
         * Viewset model crud of customer.
         * @param {Service} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesCreate(data: Service, options?: any): AxiosPromise<Service> {
            return localVarFp.servicesCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Servicio.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.servicesDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset model crud of customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesList(options?: any): AxiosPromise<Array<Service>> {
            return localVarFp.servicesList(options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Servicio.
         * @param {Service} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesPartialUpdate(id: number, data: Service, options?: any): AxiosPromise<Service> {
            return localVarFp.servicesPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Servicio.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesRead(id: number, options?: any): AxiosPromise<Service> {
            return localVarFp.servicesRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset model crud of customer.
         * @param {number} id A unique integer value identifying this Servicio.
         * @param {Service} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesUpdate(id: number, data: Service, options?: any): AxiosPromise<Service> {
            return localVarFp.servicesUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for servicesCreate operation in ServicesApi.
 * @export
 * @interface ServicesApiServicesCreateRequest
 */
export interface ServicesApiServicesCreateRequest {
    /**
     * 
     * @type {Service}
     * @memberof ServicesApiServicesCreate
     */
    readonly data: Service
}

/**
 * Request parameters for servicesDelete operation in ServicesApi.
 * @export
 * @interface ServicesApiServicesDeleteRequest
 */
export interface ServicesApiServicesDeleteRequest {
    /**
     * A unique integer value identifying this Servicio.
     * @type {number}
     * @memberof ServicesApiServicesDelete
     */
    readonly id: number
}

/**
 * Request parameters for servicesPartialUpdate operation in ServicesApi.
 * @export
 * @interface ServicesApiServicesPartialUpdateRequest
 */
export interface ServicesApiServicesPartialUpdateRequest {
    /**
     * A unique integer value identifying this Servicio.
     * @type {number}
     * @memberof ServicesApiServicesPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Service}
     * @memberof ServicesApiServicesPartialUpdate
     */
    readonly data: Service
}

/**
 * Request parameters for servicesRead operation in ServicesApi.
 * @export
 * @interface ServicesApiServicesReadRequest
 */
export interface ServicesApiServicesReadRequest {
    /**
     * A unique integer value identifying this Servicio.
     * @type {number}
     * @memberof ServicesApiServicesRead
     */
    readonly id: number
}

/**
 * Request parameters for servicesUpdate operation in ServicesApi.
 * @export
 * @interface ServicesApiServicesUpdateRequest
 */
export interface ServicesApiServicesUpdateRequest {
    /**
     * A unique integer value identifying this Servicio.
     * @type {number}
     * @memberof ServicesApiServicesUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Service}
     * @memberof ServicesApiServicesUpdate
     */
    readonly data: Service
}

/**
 * ServicesApi - object-oriented interface
 * @export
 * @class ServicesApi
 * @extends {BaseAPI}
 */
export class ServicesApi extends BaseAPI {
    /**
     * Viewset model crud of customer.
     * @param {ServicesApiServicesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public servicesCreate(requestParameters: ServicesApiServicesCreateRequest, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).servicesCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset model crud of customer.
     * @param {ServicesApiServicesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public servicesDelete(requestParameters: ServicesApiServicesDeleteRequest, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).servicesDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset model crud of customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public servicesList(options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).servicesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset model crud of customer.
     * @param {ServicesApiServicesPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public servicesPartialUpdate(requestParameters: ServicesApiServicesPartialUpdateRequest, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).servicesPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset model crud of customer.
     * @param {ServicesApiServicesReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public servicesRead(requestParameters: ServicesApiServicesReadRequest, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).servicesRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset model crud of customer.
     * @param {ServicesApiServicesUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public servicesUpdate(requestParameters: ServicesApiServicesUpdateRequest, options?: AxiosRequestConfig) {
        return ServicesApiFp(this.configuration).servicesUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}
