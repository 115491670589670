import * as React from 'react';
import './Configuration.scss';
import {
  Button, colors, Spinner, Text, TextField,
} from 'react-elemental';
import { TiWarningOutline as Warning } from 'react-icons/ti';
import Image from 'react-bootstrap/Image';
import EditConfigurationModal from './EditConfigurationModal';
import { showNotification } from '../../actions';
import { errorType } from '../../constants';
import setting from '../../commons/assets/configuracion_sucursal.svg';
import manager from '../../commons/assets/distribuidor.svg';
import {validateRut2} from '../Commons/Validators';

class Configuration extends React.Component {
  constructor(props) {
    super(props);
    this.alert_ref = React.createRef();
    this.state = {
      isVisible: false,
      stock: 0,
      name: '',
      last_name: '',
      phone: props.auth.currentUser.phone,
      email: props.auth.currentUser.email,
      alternative_email: props.auth.currentUser.alternative_email,
      stationId: null,
      business_name: '',
      rut: ''
    };

    this.validate = this.validate.bind(this);
    this.setVisibility = this.setVisibility.bind(this);
    this.validatePhone = this.validatePhone.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.updateFullConfig = this.updateFullConfig.bind(this);
    this.onHandleChangeStock = this.onHandleChangeStock.bind(this);
  }

  setVisibility(isVisible) {
    this.setState({ isVisible });
  }

  onHandleChangeStock(value) {
    if (!isNaN(value)) { this.setState({ stock: value }); }
  }

  validatePhone(phone) {
    if (phone === '') { return ''; }
    const re = new RegExp('^(\\+?56)?(\\s?)(0?[29])(\\s?)[0-9]\\d{7}$');
    return re.test(phone) ? '' : 'No es un teléfono válido';
  }

  validateEmail(email) {
    if (email === '' || email === null) return '';
    const re = new RegExp(
      '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    );
    return re.test(email) ? '' : 'No es un correo válido';
  }

  validate() {
    const {
      stock, name, last_name, email, phone, business_name, rut
    } = this.state;
    if (stock === '' || name === '' || last_name === '' || email === ''
      || phone === '' || business_name === '' || rut === '') return false;

    return this.validateEmail(email) === '' && this.validatePhone(phone) === '';
  }

  updateFullConfig() {
    const data = {
      stock: this.state.stock,
      first_name: this.state.name,
      last_name: this.state.last_name,
      phone: this.state.phone,
      email: this.state.email,
      alternative_email: this.state.alternative_email,
      station: this.state.stationId,
      business_name: this.state.business_name,
      rut: this.state.rut
    };
    this.props.updateFullConfig(data);
  }

  componentDidMount() {
    if (!this.props.auth.isLogged) this.props.history.push('/app');

    if (this.props.auth.currentUser && this.props.auth.currentUser.station) {
      this.props.getConfig(this.props.auth.currentUser.station.id);
      this.setState({ name: this.props.auth.currentUser.first_name });
      this.setState({ last_name: this.props.auth.currentUser.last_name });
      this.setState({ phone: this.props.auth.currentUser.phone });
      this.setState({ email: this.props.auth.currentUser.email });
      this.setState(
        { alternative_email: this.props.auth.currentUser.alternative_email },
      );
      this.setState({business_name: this.props.auth.currentUser.business_name})
      this.setState({rut: this.props.auth.currentUser.rut})
    }

    if (this.props.station && this.props.station.config) {
      this.setState({ stationId: this.props.station.config.station.id });
      this.setState({ stock: this.props.station.config.stock });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.station.hasError) {
      this.alert_ref.current.innerHTML = `<div class="alert alert-danger" role="alert">
  <strong>No se pudieron actualizar sus datos!</strong></div>`;
      setTimeout(()=> {this.alert_ref.current.innerHTML =''}, 3000);
      showNotification(errorType.ERROR, nextProps.station.error);
      this.props.resetNotification();
    }

    if (nextProps.station.successChange === true) {
      this.alert_ref.current.innerHTML = `<div class="alert alert-success" role="alert">
  <strong>Se ha cambiado su configuración exitosamente!</strong></div>`;
      setTimeout(()=> {this.alert_ref.current.innerHTML =''}, 1500);
      showNotification(errorType.SUCCESS,
        'Se ha cambiado su configuración exitosamente');
      this.props.resetNotification();
    }

    if (nextProps.station && nextProps.station.config) {
      this.setState({ stationId: nextProps.station.config.station.id });
      this.setState({ stock: nextProps.station.config.stock });
    }

    if (nextProps.auth.currentUser) {
      this.setState({ name: nextProps.auth.currentUser.first_name });
      this.setState({ last_name: nextProps.auth.currentUser.last_name });
      this.setState({ phone: nextProps.auth.currentUser.phone });
      this.setState({ email: nextProps.auth.currentUser.email });
      this.setState(
        { alternative_email: nextProps.auth.currentUser.alternative_email },
      );
      this.setState({business_name: this.props.auth.currentUser.business_name})
      this.setState({rut: this.props.auth.currentUser.rut})
    }
  }

  render() {
    const { config } = this.props.station;
    const { isVisible } = this.state;
    return (
      <div className="mt-3">
        {
          config && this.props.auth.currentUser
          && this.props.auth.currentUser.station
            ? (
              <div className="">
                <h3 className="custom-title black">Configuración general</h3>
                <div className="mb-5 text-muted margin-top--5">
                  Planifica y
                  gestiona todas las reservas online de cambios de aceite
                  completando lo siguientes datos
                </div>
                <div className="row">
                  <div className="col-md-6 col-xs-12 col-sm-12 mt-4 mb-5">
                    <div className="d-inline-flex mb-4">
                      <Image
                        alt="Sucursal"
                        src={ setting }
                        color={ colors.primary }
                        width="auto"
                        height="25px"
                      />
                      <h5 className="custom-title black ml-3">
                        Estación De
                        Servicio
                      </h5>
                    </div>
                    <div
                      className="text-muted"
                    >
                      Sucursal:
                      { config.station.address }
                      ,
                    </div>
                    <div
                      className="text-muted"
                    >
                      Comuna:
                      { config.station.commune.name }
                    </div>
                    <div className="mt-2 text-muted">
                      Tiempo de atención
                      (minutos):
                      { config.attention_time }
                      Minutos
                    </div>
                    <div className="mt-2 text-muted d-block">
                      <div>Posiciones de lubricación:</div>
                      <TextField
                        className=" ml-0 col-md-3 col-xs-12 col-sm-12 rounded-pill"
                        type="number"
                        value={ this.state.stock }
                        onChange={ (e) => this.onHandleChangeStock(e.target.value) }
                        min={ 1 }
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-xs-12 col-sm-12 mt-4 mb-5">
                    <div className="d-inline-flex mb-4">
                      <Image
                        alt="Sucursal"
                        src={ manager }
                        color={ colors.primary }
                        width="auto"
                        height="25px"
                      />
                      <h5 className="custom-title black ml-3">Distribuidor</h5>
                    </div>
                    <br />
                    <div className="text-muted">
                      <div> Razón social:</div>
                      <TextField
                        className="ml-0 col-md-5 col-xs-12 col-sm-12 rounded-pill"
                        value={ this.state.business_name }
                        onChange={ (e) => this.setState({ business_name: e.target.value }) }
                        required
                      />
                    </div>
                    <div className="text-muted">
                      <div> Nombre del distribuidor:</div>
                      <TextField
                        className="ml-0 col-md-5 col-xs-12 col-sm-12 rounded-pill"
                        value={ this.state.name }
                        onChange={ (e) => this.setState({ name: e.target.value }) }
                        required
                      />
                    </div>

                    <div className="mt-3 text-muted">
                      <div>Apellidos del distribuidor:</div>
                      <TextField
                        className=" ml-0 col-md-5 col-xs-12 col-sm-12 rounded-pill"
                        value={ this.state.last_name }
                        onChange={ (e) => this.setState(
                          { last_name: e.target.value },
                        ) }
                        required
                      />
                    </div>

                    <div className="text-muted">
                      <div> Rut:</div>
                      <TextField
                        className="ml-0 col-md-5 col-xs-12 col-sm-12 rounded-pill"
                        value={ this.state.rut }
                        onChange={ (e) => this.setState({ rut: e.target.value.toUpperCase() }) }
                        error={ this.state.rut && validateRut2(this.state.rut) }
                        required
                      />
                    </div>

                    <div className="mt-3 text-muted">
                      <div>Teléfono de contacto:</div>
                      <TextField
                        className="ml-0 col-md-5 col-xs-12 col-sm-12 rounded-pill"
                        value={ this.state.phone }
                        onChange={ (e) => this.setState({ phone: e.target.value }) }
                        error={ this.validatePhone(this.state.phone) }
                        required
                      />
                    </div>
                    <div className="mt-3 text-muted">
                      <div>Mail estación de servicio:</div>
                      <TextField
                        type="email"
                        className="ml-0 col-md-5 col-xs-12 col-sm-12 rounded-pill"
                        value={ this.state.email }
                        error={ this.validateEmail(this.state.email) }
                        onChange={ (e) => this.setState({ email: e.target.value }) }
                        required
                      />
                    </div>
                    <div className="mt-3 text-muted">
                      <div>Mail alternativo:</div>
                      <TextField
                        type="email"
                        className="ml-0 col-md-5 col-xs-12 col-sm-12 rounded-pill"
                        value={ this.state.alternative_email }
                        error={ this.validateEmail(this.state.alternative_email) }
                        onChange={ (e) => this.setState(
                          { alternative_email: e.target.value },
                        ) }
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12" ref={this.alert_ref}></div>
                </div>

                <div className="ml-auto mt-5">
                  <Button
                    text="Guardar cambios"
                    className="rounded-pill"
                    style={ { float: 'right' } }
                    disabled={ this.props.isLoading || !this.validate() }
                    onClick={ () => this.updateFullConfig() }
                  >
                    { this.props.isLoading
                  && (
                  <Spinner
                    size="gamma"
                    className="ml-2"
                  />
                  ) }
                  </Button>
                </div>
              </div>
            )
            : (
              <div className="d-inline-flex w-100">
                <Text
                  size="epsilon"
                  className="text-center mt-3 w-100"
                  color={ colors.gray45 }
                  bold
                >
                  <Warning />
                  No tienes ninguna sucursal asignada
                </Text>
              </div>
            )
        }

        { isVisible && (
          <EditConfigurationModal
            booking={ this.state.selectedBooking }
            configuration={ this.props.station.config }
            setVisibility={ this.setVisibility }
            editConfig={ this.props.editConfig }
          />
        )}

      </div>

    );
  }
}

export default Configuration;
