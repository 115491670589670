import * as React from 'react';
import './Planning.scss';
import { colors, Text } from 'react-elemental';
import { TiWarningOutline as Warning } from 'react-icons/ti';
import Schedule from '../Schedule/Schedule';

class Planning extends React.Component {
  componentDidMount() {
    if (!this.props.auth.isLogged) { this.props.history.push('/app'); }

    if (this.props.auth.currentUser && this.props.auth.currentUser.station) {
      this.props.getConfig(this.props.auth.currentUser.station.id);
      this.props.getBlocks(this.props.auth.currentUser.station.id);
    }
  }

  render() {
    const { config } = this.props?.station;
    const { list } = this.props.block;
    return (
      <div className="mt-3">
        {
          this.props.auth.currentUser && this.props.auth.currentUser.station
            ? (
              <div>
                <div className="row text-center ml-0">
                  <div className="col-md-3 col-xs-12 col-sm-12">
                    <span className="little-box disabled" />
                    <span>Deshabilitado por horario</span>
                  </div>

                  <div className="col-md-3 col-xs-12 col-sm-12">
                    <span className="little-box inactivated" />
                    <span>Deshabilitado por configuración</span>
                  </div>

                  <div className="col-md-3 col-xs-12 col-sm-12">
                    <span className="little-box reserved" />
                    <span>Reservado</span>
                  </div>

                  <div className="col-md-3 col-xs-12 col-sm-12">
                    <span className="little-box" />
                    <span>Disponible</span>
                  </div>
                </div>

                { config && list && (
                <Schedule
                  configuration={ config }
                  blocks={ list }
                  createBlock={ this.props.createBlock }
                  removeBlock={ this.props.removeBlock }
                />
                ) }
              </div>
            )

            : (
              <div className="d-inline-flex w-100">
                <Text
                  size="epsilon"
                  className="text-center mt-3 w-100"
                  color={ colors.gray45 }
                  bold
                >
                  <Warning />
                  No tienes ninguna sucursal asignada
                </Text>
              </div>
            )
        }

      </div>
    );
  }
}

export default Planning;
