import React, { useState, useEffect } from 'react';
import ActionButton from '../ActionButton';
import step1 from './assets/step1.jpg'
import step3 from './assets/step3.jpg'
import './styles.scss';

const FindLub = () => {
    const [openTutorial, setOpenTutorial] = useState(true);
    const [toggleFade, setToggleFade] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setOpenTutorial(prevCheck => !prevCheck)
        }, 200)
    }, [toggleFade])

    const ListCol = ({ number, text, content }) => {
        return(
            <>
                <div className="col-12 mt-3 d-flex flex-row align-items-center">
                        <div className="number-circle d-flex text-whie text-white align-items-center justify-content-center mr-3 shell-bold">{number}</div>
                        <p className="mb-0 gray-text findlub-text">{text}</p>
                </div>
                <div className="col-12 mt-3 gray-text mb-3">
                    {content && content}
                </div>
            </>
        );
      }
    

    const StepContent = ({ pathImg, altImg }) => <img src={pathImg} className="tutorial-content-img" alt={altImg} />
    const Step2Content = () => {
        return(
            <div>
                <ul>
                    <li>Grado API SL, SM o SN</li>
                    <li>Grado ILSAC GF-3, GF-4 o GF-5</li>
                    <li>Para obtener información adicional, consulte <span className="shell-bold">"Número de viscosidad SAE recomendado"</span> en esta sección</li>
                </ul>
            </div>
        );
    }
  return (
    <div className="container pt-2" id="findlub">
        <h2 className="toggle-button-title text-center">
            <ActionButton text='¿Qué lubricante usa mi auto?' actionFunction={() => setToggleFade(prevCheck => !prevCheck)} animatedArrow={true}/>
        </h2>
        <div className={toggleFade ? "fadeIn": "fadeOut"}>
            {openTutorial && 
                <div className={"row"}>
                    <h5 className="mx-auto text-center gray-text mt-5">Encuentra cuál es el aceite recomendado por el fabricante (Ejemplo manual Nissan NP300)</h5>
                    <div className="col-12 col-md-6">
                        <ListCol number='1' text='Busca la página de líquidos y lubricantes en el manual' content={<StepContent pathImg={step1} altImg='step1' />} />
                        <ListCol number='2' text='Revisa en la tabla el lubricante recomendado para tu motor' content={<Step2Content />} />
                    </div>
                    <div className="col-12 col-md-6">
                    <ListCol number='3' text='Anota cuál es el número de viscosidad SAE recomendado' content={<StepContent pathImg={step3} altImg='step3' />} />
                    <ListCol number='4' text={<span>El lubricante de motor recomendado para tu auto es uno con
                                                <span className="shell-bold red-text"> Viscosidad 5W-30 y grado API SL, SM, SN o SP</span>
                                            </span>} />
                    </div>
                </div>}
        </div>
    </div>

  );
};

export default FindLub;
