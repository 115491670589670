import React, { useState, useEffect } from "react";
// import PopupBanner from "../../commons/assets/vintage2023.png";
import { Modal, Close } from "./StyledWelcomeModal";
import './styles.scss';
import {settingService} from '../../services/setting'

export default function WelcomeModal(props) {
  const [loadModal, setLoadModal] = useState(false);
  const [popup, setPopup] = useState(null);

  useEffect(() => {
      settingService.get().then(data => {
          if (data.data.pop_up){
              setPopup(data.data.pop_up);
              setLoadModal(true);
              // console.log('Actualizando popup, ', loadModal, popup);
          }
      });
  }, []);

  return (
    loadModal && popup ? (
      <Modal style={{zIndex: 2000}} size="alpha" onHide={() => setLoadModal(false)}>
        <Close size={20} onClick={() => setLoadModal(false)} />
        <img className="modal-img" src={popup} alt="Popup banner" />
      </Modal>) : (<div></div>)

  );
}
