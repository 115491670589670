import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import {change_password, resetNotification} from '../../actions';
import ResetPassword from './ResetPassword';

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    change_password: (token, pass, pass2) => {
      dispatch(change_password(token, pass, pass2));
    },
    resetNotification: () => {
      dispatch(resetNotification());
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
