import React from "react";

const ChildrenButton = ({ children, child, setChildActive, childActive }) => {
  return (
    <>
      <div
        onClick={() => setChildActive(child.key)}
        key={child.key}
        className={"sidebar-active children-menu" + (childActive === child.key ? ' children-active': '')}
      >
        {children}
      </div>
    </>
  );
};

export default ChildrenButton;
