import React from "react";
import Planning from "../../../Planning/PlanningContainer";
import Booking from "../../../Booking/BookingContainer";
import "./Management.scss";

const Management = () => {
  return (
    <>
      <div>
        <h5 className="red-text">Planificación</h5>
        <Planning />
      </div>
      <div>
        <h5 className="red-text">Gestión de reservas</h5>
        <Booking />
      </div>
    </>
  );
};

export default Management;
