import * as React from 'react';
import './Station.scss';
import { Spacing, LoadingBar, colors } from 'react-elemental';
import Select from 'react-select';
import Stats from '../../Stats';
import StationMap from './StationMap';
import StationCards from './StationCards';
import ProductCarousel from '../../../Commons/ProductCarousel';
import { customSelect } from '../../../../commons/style/style';

const ZOOM_MOBILE = 12;

class Station extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Sucursales",
      selectedRegionId: null,
      selectedCommune: null,
      selectedStation: null,
      searchAdvance: false,
      geolocation: false,
      nearbyStations: [],
      center: {
        lat: -33.4534775,
        lng: -70.7144413,
      },
      guest: null,
      zoom: 13,
    };
    this.getCommunes = this.getCommunes.bind(this);
    this.getStations = this.getStations.bind(this);
    this.selectStation = this.selectStation.bind(this);
    this.selectCommune = this.selectCommune.bind(this);
    this.selectRegion = this.selectRegion.bind(this);
    this.showAdvance = this.showAdvance.bind(this);
    this.initMap = this.initMap.bind(this);
    this.calculateLinealDistance = this.calculateLinealDistance.bind(this);
    this.getStations(null);
  }

  getCommunes(regionId) {
    this.props.getCommunes(regionId);
  }

  showAdvance() {
    this.setState({ searchAdvance: !this.state.searchAdvance });
  }

  getStations(communeId) {
    this.props.getStations(communeId);
    // console.log('getStations');
  }

  selectStation(item) {
    const stationId = parseInt(item.value);
    const selectedStation = this.props.stations.find(
      (station) => station.id === stationId,
    );
    if (selectedStation) {
      const temp = { lat: selectedStation.lat, lng: selectedStation.lng };
      this.setState({ center: temp });
      this.setState({ zoom: 17 });
      this.setState({ selectedStation });
      this.props.onChangeStation(selectedStation);
      // Scroll to bottom
      setTimeout(() => {
        let rootElement = document.getElementById('label-products');
        if (rootElement == null) {
          rootElement = document.documentElement;
          rootElement.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
          });
        } else {
          rootElement.scrollIntoView();
        }
      }, 700);
    }
  }

  selectCommune(item) {
    const communeId = parseInt(item.value);
    const selectedCommune = this.props.communes.find(
      (commune) => commune.id === communeId,
    );
    if (selectedCommune) {
      const temp = { lat: selectedCommune.lat, lng: selectedCommune.lng };
      this.setState({ center: temp });
      this.setState({ zoom: 13 });
      if (window.innerWidth < 648) {
        this.setState({ zoom: ZOOM_MOBILE });
      }
      this.setState({ selectedCommune });
      this.setState({ selectedStation: null });
      this.setState({ searchAdvance: false });
      setTimeout(() => this.calculateLinealDistance(), 500);
    }
  }

  selectRegion(item) {
    this.setState({ showDistance: false });
    const regionId = parseInt(item.value);
    this.getCommunes(regionId);
    this.setState({ selectedRegionId: regionId });
    this.setState({ selectedCommune: null });
    this.setState({ selectedStation: null });
  }

  initMap() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({ geolocation: true });
        this.setState({ title: "Sucursales cercanas" });
        // console.log('Nueva ubicacion: ', position);
        const map_center = { lat: position.coords.latitude, lng: position.coords.longitude };
        // const map_center = { lat: -33.4534775, lng: -70.7144413 };
        this.setState({ center: map_center });
        this.setState({ guest: map_center });
        this.setState({ showDistance: true });
        // console.log('Update center to:', map_center);
        if (this.props.stations.length > 0) {
          this.calculateLinealDistance();
        } else {
          setTimeout(() => this.calculateLinealDistance(), 1000);
        }
      }, (err) => {
        setTimeout(() => this.calculateLinealDistance(), 1000);
      });
    } else {
      if (this.props.stations.length > 0) {
        this.calculateLinealDistance();
      } else {
        setTimeout(() => this.calculateLinealDistance(), 1000);
      }
    }
  }

  calculateLinealDistance() {
    // if (this.state.center && this.props.stations.length > 0) {
    // console.log('calculateLinealDistance center: ', this.state.center);
    // console.log('calculateLinealDistance this.props.stations.length: ', this.props.stations.length);
    // console.log('calculateLinealDistance this.props.stations: ', this.props.stations);
    // }
    const getKm = (lat1, lon1, lat2, lon2) => {
      const rad = (x) => (x * Math.PI) / 180;
      const R = 6371; // Ratio of Earth in km
      const dLat = rad(lat2 - lat1);
      const dLong = rad(lon2 - lon1);
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(lat1)) * Math.cos(rad(lat2)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c;
      // return d.toFixed(3);
      return d;
    };

    const max_stations = 4;
    let nearby;
    if (this.state.selectedCommune) {
      nearby = this.props.stations.filter((item) => {
        return item.commune.id === this.state.selectedCommune.id;
      });
      this.setState({nearbyStations: nearby});
    } else {
      const stationsList = this.props.stations.map((item) => {
        return {
          ...item,
          distance: getKm(this.state.center.lat, this.state.center.lng, parseFloat(item.lat), parseFloat(item.lng)),
        };
      });

      // console.log('stationsList: ', stationsList);

      nearby = stationsList.sort((a, b) => a.distance - b.distance);
      this.setState({nearbyStations: nearby.slice(0, max_stations)});
    }
    if (this.state.nearbyStations.length > 0 ){
      // console.log('nearbyStation', this.state.nearbyStations[0]);
      this.setState({center: {lat: nearby[0].lat, lng: nearby[0].lng}});
    }
  }

  componentDidMount() {
    this.initMap();
    // console.log('Resolution: ', window.innerWidth);
    if (window.innerWidth < 648) {
      this.setState({ zoom: ZOOM_MOBILE });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {

  }

  render() {
    const { regions, communes, stations } = this.props;
    const { nearbyStations } = this.state;
    const sucursals = nearbyStations;
    return (
      <div className="mt-3">
        <h3 className="custom-title black mt-5 d-none d-md-block">
          Selecciona una Estación de
          Servicio
        </h3>
        <div className="mb-5 text-muted margin-top--5 d-none d-md-block">
          Elige la Estación de
          Servicio más cercana para agendar tu cambio de aceite
        </div>

        {
          this.state.center.lat !== null && this.state.center.lng !== null
          && (
            <div className="row">
              <div className="col-lg-3 nearby-stations order-1 order-lg-0 mt-3 mt-lg-0">
                <h5 className="mb-3">{ this.state.title }</h5>
                { !this.state.searchAdvance && (
                <StationCards
                  stations={ sucursals }
                  selectStation={this.selectStation}
                  selectedStation={this.state.selectedStation}
                  showDistance={this.state.showDistance}
                ></StationCards>)}
                {!this.state.searchAdvance && this.state.geolocation === false && (
                  <p className="small mb-0 mt-2">Si permites usar la geolocalización, se mostraran las sucursales mas cercanas a tu ubicación.</p>
                )}
                <button type="button" className="btn btn-sm btn-search w-100 mt-3" onClick={this.showAdvance}>Buscar otras sucursales</button>
                {this.state.searchAdvance && (
                  <div className="row mt-2">
                    <div className="col-12">
                      <Spacing bottom>
                        {/*<label className="text-muted custom-label margin-bottom-10">Región</label>*/}
                        <Select
                          className="rounded-pill"
                          styles={customSelect}
                          placeholder="Selecciona una región"
                          onChange={this.selectRegion}
                          noOptionsMessage={() => 'No hay regiones cargadas'}
                          options={regions.map((region) => ({label: region.name, value: region.id.toString()}))}
                        />
                        {
                          regions.length === 0
                          && (
                            <div className="p-2">
                              <LoadingBar
                                color={colors.red}
                                thickness={4}
                                duration={500}
                                delay={50}
                              />
                            </div>
                          )
                        }
                      </Spacing>
                    </div>

                    <div className="col-12">
                      <Spacing bottom>
                        {/*<label className="text-muted custom-label margin-bottom-10">Comuna</label>*/}
                        <Select
                          styles={customSelect}
                          placeholder="Selecciona una comuna"
                          value={this.state.selectedCommune
                            ? {
                              value: this.state.selectedCommune.id.toString(),
                              label: this.state.selectedCommune.name,
                            }
                            : null}
                          onChange={this.selectCommune}
                          noOptionsMessage={() => 'Debe seleccionar una región para mostrar sus comunas'}
                          options={communes.map((commune) => ({label: commune.name, value: commune.id.toString()}))}
                        />
                        {
                          ((this.state.selectedRegionId !== null && communes.length
                            === 0)
                            || (this.state.selectedRegionId !== null && communes.length
                              > 0 && communes[0].region !== this.state.selectedRegionId))
                          && (
                            <div className="p-2">
                              <LoadingBar
                                color={colors.red}
                                thickness={4}
                                duration={500}
                                delay={50}
                              />
                            </div>
                          )
                        }
                      </Spacing>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-lg-9 col-xs-12 col-sm-12 ml-auto mr-auto order-0 order-lg-1">
                <StationMap
                  center={this.state.center}
                  zoom={this.state.zoom}
                  stations={stations}
                  selectStation={this.selectStation}
                />
              </div>
            </div>
          )
        }
        <ProductCarousel products={ this.props.products } />
        <Stats
          step={ 1 }
          { ...this.props }
          disabled={ this.state.selectedStation === null }
        />
      </div>
    );
  }
}

export default Station;
