import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal, Spacing, Text } from "react-elemental";
import DatePicking from "./DatePicking";
import { getConfig } from "../../../actions/stations";
import { list } from "../../../actions/block";
import { create, edit } from "../../../actions/booking";

const ReagendBooking = ({ booking, setVisibility }) => {
  const [isLoading, setIsloading] = useState(true);
  const [newDate, setNewDate] = useState(null);
  const dispatch = useDispatch();
  const state = useSelector((state) => ({
    config: state.station.config,
    exceptions: state.block.list,
  }));

  useEffect(() => {
    if (booking.station.id) {
      dispatch(getConfig(booking.station.id));
      dispatch(list(booking.station.id));
    }
  }, [dispatch, booking.station.id]);

  useEffect(() => {
    if (state.config) {
      setIsloading(false);
    }
  }, [state.config]);

  const reAgendBooking = () => {
    const data = {
      ...booking,
      secret_key: null,
    };
    if (
      data.station !== null &&
      newDate !== null &&
      data.vehicle.patent !== "" &&
      data.vehicle.brand !== "" &&
      data.vehicle.model !== "" &&
      data.vehicle.year !== "" &&
      data.vehicle.fuel_type !== "" &&
      data.customer.name !== "" &&
      data.customer.email
    ) {
      data.date = new Date(
        Date.UTC(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate(),
          newDate.getHours(),
          newDate.getMinutes(),
          0
        )
      );
      const oldBooking = {
        ...booking,
        status: "cancelled",
        station: booking.station,
        comment: "Cancelado por reagendamiento",
      };
      delete oldBooking.prom_code;
      dispatch(edit(oldBooking.id, JSON.stringify(oldBooking)));
      setTimeout(()=>{dispatch(create(data));}, 500); // TODO: Revisar y cambiar esto
      setVisibility(false);
    }
  };

  return (
    <Modal
      size="alpha"
      onHide={() => {
        setVisibility(false);
      }}
    >
      <Spacing size="large" top right bottom left padding>
        {isLoading ? (
          <div>Cargando ...</div>
        ) : (
          <Spacing bottom>
            <DatePicking
              config={state.config}
              exceptions={state.exceptions}
              onChangeDate={setNewDate}
            />
          </Spacing>
        )}

        <Text right>
          <Button
            text="Cancelar"
            secondary
            className="mr-3 rounded-pill"
            onClick={() => setVisibility(false)}
          />
          <Button
            text="Re-Agendar"
            className="rounded-pill"
            onClick={() => reAgendBooking()}
            disabled={newDate === null}
          />
        </Text>
      </Spacing>
    </Modal>
  );
};

export default ReagendBooking;
