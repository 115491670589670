import React, { useState, useEffect } from 'react';

import './styles.scss';
import FindLub from '../FindLub';
import KnowOilPrice from '../KnowOilPrice';

const OilFAQs = () => {


    return (
        <div className="container">
            <h2 className="gray-text text-center">Preguntas frecuentes sobre el cambio de aceite</h2>
            <FindLub />
            <KnowOilPrice />
        </div>

    );
};

export default OilFAQs;
